import React, { useState } from "react";
import { useCookies } from "react-cookie";
import moment from "moment";
import Repo from "../Utility/Repo";
import { Link, useLocation } from "react-router-dom";
import Breadcrumb from "../Layout/Breadcrumb";

function ListaPagamentiAttesi(props) {
  const [cookies] = useCookies();
  const [apiToken] = useState(cookies.api_token);

  const [localEnte] = useState(cookies.ente);
  const [defaultEntity, setDefaultEntity] = useState(
    cookies.user_profile ? cookies.user_profile.AreaName : ""
  );

  const getPdf = async (idCartella) => {
    var data = JSON.stringify({ pkCartella: idCartella, pkTipoCartella: "" });
    Repo.getFile64(apiToken, data)
      .then(function (response) {
        var b64 = response.data.binaryFile;
        var a = window.document.createElement("a");
        a.href = "data:application/octet-stream;base64," + b64;
        a.download = response.data.nomeFile;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <>
      {props.children.length !== 0 ? (
        <>
          <div
            id="collapseDiv1-lft"
            className="collapse-div collapse-left-icon"
            role="tablist"
          >
            {Array.isArray(props.children) &&
              props.children.map((obbj, index) => (
                <div key={index}>
                  <div className="row mt-4">
                    <div className="col-12 col-lg-12">
                      <div className="card-wrapper">
                        <div className="card card-bg">
                          <div className="card-body">
                            <div className="row">
                              <div className="col-xs-12">
                                <h5 className="card-title">
                                  {" "}
                                  {obbj.nomeUnivocoCartella}
                                </h5>
                                <hr />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-xs-6 col-md-4">
                                <p>
                                  <b>Importo Dovuto: </b> {obbj.importoDovuto} €
                                </p>
                              </div>
                              <div className="col-xs-6 col-md-4">
                                <p>
                                  <b>Importo Residuo: </b> 0 €
                                </p>
                              </div>
                              <div className="col-md-4">
                                <p>
                                  <b>Importo Versato: </b> 0 €
                                </p>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-xs-6 col-md-4">
                                {" "}
                                <p>
                                  <b>Causale: </b> {obbj.causale}
                                </p>
                              </div>
                              <div className="col-xs-6 col-md-4">
                                <p>
                                  <b>Scadenza: </b>{" "}
                                  {obbj.dataScadenza
                                    ? moment(
                                      new Date(obbj.dataScadenza)
                                    ).format("DD-MM-YYYY")
                                    : "/"}
                                </p>
                              </div>
                            </div>

                            {!props.disableButton && (
                              <>
                                <div className="row">
                                  {obbj.rate.length < 1 && (
                                    <div className="col-6 pt-2">
                                      <button
                                        onClick={() => props.setDettaglio(obbj)}
                                        type="button"
                                        className="btn btn-primary"
                                      >
                                        Paga Ora{" "}
                                      </button>
                                    </div>
                                  )}
                                  {obbj.rate.length > 0 && (
                                    <div className="col-4 pt-2 ">
                                      <button
                                        onClick={() => {
                                          window.scroll(0, 0),
                                            props.setDettaglio(obbj);
                                        }}
                                        type="button"
                                        className="btn btn-primary btn-sm"
                                      >
                                        {" "}
                                        {props.dettaglioCartella
                                          ? "Paga in unica soluzione"
                                          : "Visualizza Rate"}
                                      </button>
                                    </div>
                                  )}
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </>
      ) : (
        <b>Nessun Risultato da Mostrare</b>
      )}
    </>
  );
}

export default ListaPagamentiAttesi;

import { Input } from "design-react-kit";
import { useState, useEffect } from "react";
import moment from "moment";
import { useCookies } from "react-cookie";
import Repo from "../Utility/Repo";
import AlertMessage from "../Objects/AlertMessage.js";
import Select from "../Objects/Select.js";
import { useLocation } from "react-router";
import useLocalStorage from "../Utility/useLocalStorage.js";

function DettaglioPosizione(props) {
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const paramsId = query.get("id");

  const [errorMessage, setErrorMessage] = useState();
  const [personType, setPersonType] = useState();
  const [cookies] = useCookies();

  const [apiToken] = useState(cookies.api_token);

  const [responsePosizione, setResponsePosizione, removeResponse] =
    useLocalStorage("responsePosizioni");
  const [errorCfPiva, setErrorCfPiva] = useState(false);

  const [editMode, setEditMode] = useState(true);
  const [entrateSelect, setEntrateSelect] = useState({});
  const [entrataLabel, setEntrataLabel] = useState({});

  const [items, setItems] = useState([]);

  const [item, setItem] = useState();
  const [data, setData] = useState({
    nomePagatore: "",
    cognomePagatore: "",
    cfPiva: "",
    indirizzoPagatoreVia: "",
    indirizzoPagatoreCitta: "",
    rata: "",
    dataScadenza: "2021-12-07T10:05:50.986Z",
    importoVersamento: 0,
    causaleVersamento: "",
    idTipoEntrata: 0,
  });

  const handleChange = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    switch (name) {
      case "nome":
        setData((prevState) => ({
          ...prevState,
          nomePagatore: value,
        }));

        break;

      case "cognome":
        setData((prevState) => ({
          ...prevState,
          cognomePagatore: value,
        }));

        break;

      case "codiceFiscale":
        setData((prevState) => ({
          ...prevState,
          cfPiva: value,
        }));

        break;

      case "partitaIva":
        setData((prevState) => ({
          ...prevState,
          cfPiva: value,
        }));

        break;

      case "ragioneSociale":
        setData((prevState) => ({
          ...prevState,
          ragioneSociale: value,
        }));

        break;

      // case "entrata":
      //   setData((prevState) => ({
      //     ...prevState,
      //     entrata: value,
      //   }));
      //   break;

      case "dataScadenza":
        setData((prevState) => ({
          ...prevState,
          dataScadenza: moment(value).format("YYYY-MM-DDTHH:mm:ss"),
        }));
        break;

      case "rata":
        setData((prevState) => ({
          ...prevState,
          rata: value,
        }));
        break;

      case "importo":
        setData((prevState) => ({
          ...prevState,
          importoVersamento: parseInt(value),
        }));
        break;

      case "indirizzoPagatoreVia":
        setData((prevState) => ({
          ...prevState,
          indirizzoPagatoreVia: value,
        }));
        break;

      case "indirizzoPagatoreCitta":
        setData((prevState) => ({
          ...prevState,
          indirizzoPagatoreCitta: value,
        }));
        break;

      case "idTipoEntrata":
        setData((prevState) => ({
          ...prevState,
          idTipoEntrata: value,
        }));
        break;

      case "causaleVersamento":
        setData((prevState) => ({
          ...prevState,
          causaleVersamento: value,
        }));
        break;

      default:
        break;
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const regexCF =
      /^[A-Za-z]{6}[0-9LMNPQRSTUV]{2}[A-Za-z]{1}[0-9LMNPQRSTUV]{2}[A-Za-z]{1}[0-9LMNPQRSTUV]{3}[A-Za-z]{1}$/gm;

    const regexPV = /^[0-9]{11}$/gm;

    let passedValidation = true;

    if (personType) {
      if (!data.cfPiva.match(regexCF)) {
        setErrorCfPiva(true);
        passedValidation = false;
      } else {
        setErrorCfPiva(false);
      }
    } else {
      if (!data.cfPiva.match(regexPV)) {
        passedValidation = false;

        setErrorCfPiva(true);
      } else {
        setErrorCfPiva(false);
      }
    }

    if (passedValidation) {
      var entity = data;
      var dataJson = {
        entity,
      };
      dataJson = JSON.stringify(dataJson);

      Repo.putPosizioniDebitorie(apiToken, dataJson)
        .then((response) => {
          localStorage.clear();
          //window.location.href = window.location.href.split( "/dettaglio_posizione")[0];
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    var getOne = responsePosizione.find((obj) => {
      return obj.id === parseInt(paramsId);
    });
    if (getOne.cfPiva.length > 11) {
      setPersonType(true);
    } else {
      setPersonType(false);
    }

    setData({
      id: getOne.id,
      nomePagatore: getOne.nomePagatore,
      ragioneSociale: getOne.ragioneSociale,
      cognomePagatore: getOne.cognomePagatore,
      cfPiva: getOne.cfPiva,
      indirizzoPagatoreVia: getOne.indirizzoPagatoreVia,
      indirizzoPagatoreCitta: getOne.indirizzoPagatoreCitta,
      rata: getOne.rata,
      dataScadenza: getOne.dataScadenza,
      importoVersamento: getOne.importoVersamento,
      causaleVersamento: getOne.causaleVersamento,
      idTipoEntrata: getOne.idTipoEntrata,
    });
    setItem({
      value: getOne.idTipoEntrata,
      label: getOne.descrizioneTipoEntrata,
    });
  }, [paramsId]);

  useEffect(() => {
    Repo.getTipiEntrata(apiToken)
      .then((response) => {
        setEntrateSelect(response.data.data);
        setItems(response.data.data);
        response.data.data.map((obj, index) => {
          const newList = items.concat({
            value: obj.idTipoEntrata,
            label: obj.descrizione,
          });
          setItems(newList);
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // useEffect(() => {
  //   if (items && data.idTipoEntrata){
  //     var entrata = items.find((elem) => elem.value === data.idTipoEntrata);
  //     setEntrataLabel(entrata);
  //   }

  // }, [editMode]);

  const changeEntrata = (event) => {
    setItem({
      value: event.value,
      label: event.label,
    });
    var entrataValue = event.value;
    setData((prevState) => ({
      ...prevState,
      idTipoEntrata: parseInt(entrataValue),
    }));
  };

  return (
    <>
      <div className="col-12 ">
        <br></br>
        {errorMessage !== undefined && (
          <AlertMessage error={errorMessage}></AlertMessage>
        )}

        <form onSubmit={handleSubmit} className="">
          <div className="card card-body">
            <div className="card-wrapper card-space pb-5">
              <div className="card card-bg">
                <div className="card-body">
                  <h4 className="card-title">Posizione Debitoria:</h4>

                  {personType && (
                    <>
                      <div className="row form-group">
                        <div className="col-xs-6 col-md-3">
                          <Input
                            id="nome"
                            required
                            label="Nome"
                            type="text"
                            value={data.nomePagatore}
                            name="nome"
                            onChange={handleChange}
                            disabled={editMode}
                          />
                        </div>
                        <div className="col-xs-6 col-md-4">
                          <Input
                            id="cognome"
                            required
                            label="Cognome"
                            type="text"
                            value={data.cognomePagatore}
                            name="cognome"
                            onChange={handleChange}
                            disabled={editMode}
                          />
                        </div>
                        <div className="col-xs-6 col-md-5">
                          <Input
                            id="codicefiscale"
                            required
                            label="Codice Fiscale"
                            type="text"
                            value={data.cfPiva}
                            name="codiceFiscale"
                            onChange={handleChange}
                            disabled={editMode}
                            {...(errorCfPiva && {
                              infoText: "Il campo presenta errori",
                            })}
                            {...(errorCfPiva && { className: "text-danger" })}
                          />
                        </div>
                      </div>

                      <div className="row form-group">
                        <div className="col-xs-12 col-md-6">
                          <Input
                            id="indirizzoPagatoreVia"
                            required
                            label="Indirizzo Pagamento"
                            type="text"
                            value={data.indirizzoPagatoreVia}
                            name="indirizzoPagatoreVia"
                            onChange={handleChange}
                            disabled={editMode}
                          />
                        </div>

                        <div className="col-xs-12 col-md-6">
                          <Input
                            id="indirizzoPagatoreCitta"
                            required
                            label="Città"
                            type="text"
                            value={data.indirizzoPagatoreCitta}
                            name="indirizzoPagatoreCitta"
                            onChange={handleChange}
                            disabled={editMode}
                          />
                        </div>
                      </div>

                      <div className="row form-group">
                        <div className="bootstrap-select-wrapper col-5">
                          {!editMode ? (
                            <>
                              {" "}
                              <Select
                                aria-label="Tipo entrata"
                                clearText="Annulla"
                                value={item}
                                defaultValue={item}
                                defaultMenuIsOpen={false}
                                icon={false}
                                id="idTipoEntrata"
                                onChange={changeEntrata}
                                options={items}
                                isSearchable
                                placeholder="Scegli una opzione"
                                disabled={editMode}
                              />
                            </>
                          ) : (
                            <>
                              <Input
                                label="Tipo Entrata"
                                value={item.label}
                                disabled={editMode}
                              />
                            </>
                          )}
                        </div>
                        <div className="col-xs-6 col-md-4">
                          <Input
                            id="dataScadenza"
                            required
                            label="Data di Scadenza"
                            type="date"
                            value={moment(data.dataScadenza).format(
                              "YYYY-MM-DD"
                            )}
                            name="dataScadenza"
                            placeholder="12/02/2021"
                            onChange={handleChange}
                            disabled={editMode}
                          />
                        </div>

                        <div className="col-xs-6 col-md-3">
                          <Input
                            id="rata"
                            required
                            label="Rata"
                            value={data.rata ? data.rata : ""}
                            name="rata"
                            onChange={handleChange}
                            disabled={editMode}
                          />
                        </div>
                      </div>

                      <div className="row form-group">
                        <div className="col-md-3">
                          {!editMode ? (
                            <>
                              <Input
                                id="importo"
                                required
                                label="Importo"
                                type="number"
                                name="importo"
                                value={data.importoVersamento}
                                onChange={handleChange}
                                disabled={editMode}
                              />
                            </>
                          ) : (
                            <>
                              <Input
                                id="importo"
                                required
                                label="Importo"
                                type="text"
                                name="importo"
                                value={
                                  data.importoVersamento &&
                                  data.importoVersamento.toString() + " €"
                                }
                                onChange={handleChange}
                                disabled={editMode}
                              />
                            </>
                          )}
                        </div>
                        <div className="col-md-5">
                          <Input
                            id="causaleVersamento"
                            required
                            label="Causale Versamento"
                            type="text"
                            name="causaleVersamento"
                            value={data.causaleVersamento}
                            onChange={handleChange}
                            disabled={editMode}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  {!personType && (
                    <>
                      <div className="row form-group">
                        <div className="col-xs-6 col-md-6">
                          <Input
                            id="ragioneSociale"
                            required
                            label="Ragione Sociale"
                            type="text"
                            value={
                              data.ragioneSociale ? data.ragioneSociale : ""
                            }
                            name="ragioneSociale"
                            onChange={handleChange}
                            disabled={editMode}
                          />
                        </div>
                        <div className="col-xs-6 col-md-6">
                          <Input
                            id="PartitaIva"
                            required
                            label="Partita Iva"
                            type="text"
                            value={data.cfPiva}
                            name="codiceFiscale"
                            onChange={handleChange}
                            disabled={editMode}
                            {...(errorCfPiva && {
                              infoText: "Il campo presenta errori",
                            })}
                            {...(errorCfPiva && { className: "text-danger" })}
                          />
                        </div>
                      </div>

                      <div className="row form-group">
                        <div className="col-xs-12 col-md-6">
                          <Input
                            id="indirizzoPagatoreVia"
                            required
                            label="Indirizzo Pagamento"
                            type="text"
                            value={data.indirizzoPagatoreVia}
                            name="indirizzoPagatoreVia"
                            onChange={handleChange}
                            disabled={editMode}
                          />
                        </div>

                        <div className="col-xs-12 col-md-6">
                          <Input
                            id="indirizzoPagatoreCitta"
                            required
                            label="Città"
                            type="text"
                            value={data.indirizzoPagatoreCitta}
                            name="indirizzoPagatoreCitta"
                            onChange={handleChange}
                            disabled={editMode}
                          />
                        </div>
                      </div>

                      <div className="row form-group">
                        <div className="bootstrap-select-wrapper col-5">
                          {!editMode ? (
                            <>
                              {" "}
                              <Select
                                aria-label="Tipo entrata"
                                clearText="Annulla"
                                value={item}
                                defaultValue={item}
                                defaultMenuIsOpen={false}
                                icon={false}
                                id="idTipoEntrata"
                                onChange={changeEntrata}
                                options={items}
                                isSearchable
                                placeholder="Scegli una opzione"
                                disabled={editMode}
                              />
                            </>
                          ) : (
                            <>
                              <Input
                                label="Tipo Entrata"
                                value={item && item.label}
                                disabled={editMode}
                              />
                            </>
                          )}
                        </div>

                        <div className="col-xs-6 col-md-4">
                          <Input
                            id="dataScadenza"
                            required
                            label="Data di Scadenza"
                            type="date"
                            value={moment(data.dataScadenza).format(
                              "YYYY-MM-DD"
                            )}
                            name="dataScadenza"
                            placeholder="12/02/2021"
                            onChange={handleChange}
                            disabled={editMode}
                          />
                        </div>

                        <div className="col-xs-6 col-md-3">
                          <Input
                            id="rata"
                            required
                            label="Rata"
                            value={data.rata}
                            name="rata"
                            onChange={handleChange}
                            disabled={editMode}
                          />
                        </div>
                      </div>

                      <div className="row form-group">
                        {!editMode ? (
                          <>
                            <Input
                              id="importo"
                              required
                              label="Importo"
                              type="number"
                              name="importo"
                              value={data.importoVersamento}
                              onChange={handleChange}
                              disabled={editMode}
                            />
                          </>
                        ) : (
                          <>
                            <Input
                              id="importo"
                              required
                              label="Importo"
                              type="text"
                              name="importo"
                              value={
                                data.importoVersamento &&
                                data.importoVersamento.toString() + " €"
                              }
                              onChange={handleChange}
                              disabled={editMode}
                            />
                          </>
                        )}
                        <div className="col-md-5">
                          <Input
                            id="causaleVersamento"
                            required
                            label="Causale Versamento"
                            type="text"
                            name="causaleVersamento"
                            value={data.causaleVersamento}
                            onChange={handleChange}
                            disabled={editMode}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  <div className="row ">
                    {editMode ? (
                      <>
                        <div className="col-12 text-right">
                          <a
                            className="btn btn-primary text-white"
                            onClick={() => setEditMode(!editMode)}
                          >
                            Modifica
                          </a>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="col-10 text-right">
                          <button
                            type="submit"
                            className="btn btn-primary text-white"
                          >
                            Salva
                          </button>
                        </div>

                        <div className="col-2 text-right">
                          <a
                            className="btn btn-info text-white"
                            onClick={() => setEditMode(!editMode)}
                          >
                            Indietro
                          </a>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default DettaglioPosizione;

import "bootstrap-italia/dist/css/bootstrap-italia.min.css";
import "bootstrap-italia/dist/js/bootstrap-italia.bundle.min.js";

import "./App.css";

import Repo from "./Components/Utility/Repo";
import { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { useCookies } from "react-cookie";
import Header from "./Components/Layout/Header";
import { MemoizedFooter } from "./Components/Layout/Footer";
import Body from "./Components/Layout/Body";
import ChatBot from "./Components/Objects/ChatBot";

function App() {
  const [cookies, setCookie] = useCookies();
  const [user, setUser] = useState({});
  const [apiToken] = useState(cookies.api_token);
  const [localEnte] = useState(cookies.ente);
  const [countCarello, setCountCarello] = useState({});
  const [carello, setCarello] = useState({});
  const [defaultEntity, setDefaultEntity] = useState(
    cookies.user_profile ? cookies.user_profile.AreaCode : ""
  );

  const [subfolder, setSubFolder] = useState(
    window.__RUNTIME_CONFIG__.PUBLIC_URL
  );

  useEffect(() => {
    if (apiToken) {
      Repo.getUserProfile(apiToken)
        .then((response) => {
          setUser(response.data);
          if (!localEnte) {
            Repo.getEntities()
              .then(function (response) {
                var item = response.data.find(
                  (x) => x.TownCode === defaultEntity
                );

                var userSelect = {
                  idComune: item.TownCode,
                  nomeComune: item.TownName,
                  idEnte: item.Id,
                  nomeEnte:
                    item.EntityTypeName == "Municipal"
                      ? "Ufficio Comunale"
                      : item.EntityTypeName,
                };
                setCookie("ente", userSelect, { path: "/" });
                window.location.href = subfolder + "/home";
              })
              .catch(function (error) {
                console.log(error);
              });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  let interval;
  useEffect(() => {
    interval = setInterval(() => {
      localStorage.clear();
      window.location.href = subfolder + "/home";
    }, 32400000);
  }, [interval]);

  const listaLinkUtente = [
    { testo: "Home", url: "/home" },
    { testo: "Domande Frequenti", url: "/faq" },
    { testo: "Serve Aiuto?", url: "/help" },
  ];

  const listaLink = [
    { testo: "Home", url: "/home" },
    { testo: "Domande Frequenti", url: "/faq" },
    { testo: "Serve Aiuto?", url: "/help" },
  ];

  return (
    <div className="App">
      <Router {...(subfolder && { basename: subfolder })}>
        <div className="headerCustom">
          <Header
            LastName={user.LastName}
            FirstName={user.FirstName}
            url="http://www.servizilocalispa.com/"
            Title="Servizi Locali Spa"
            linksUtente={listaLinkUtente}
            links={listaLink}
            linksEsterni={user.Apps}
            AreaName={!localEnte ? "" : localEnte.nomeComune}
            EnteName={!localEnte ? "" : localEnte.nomeEnte}
            conteggio={countCarello}
            subfolder={subfolder ? subfolder : ""}
          />
        </div>
        <Body
          user={user}
          addItem={() => setCountCarello(countCarello + 1)}
          removeItem={() => setCountCarello(countCarello - 1)}
          subfolder={subfolder ? subfolder : ""}
        ></Body>
        <MemoizedFooter> </MemoizedFooter>
      </Router>

      {/* <ChatBot></ChatBot> */}
    </div>
    // <FlexWebchat></FlexWebchat>
  );
}

export default App;

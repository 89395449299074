import React, { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import moment from "moment";
import "bootstrap-italia/dist/css/bootstrap-italia.min.css";
import iconSprite from "bootstrap-italia/dist/svg/sprite.svg";
import { Link } from "react-router-dom";
import Repo from "../Utility/Repo";
import { useCookies } from "react-cookie";
import { useHistory } from "react-router";

function TabellaFascicolo(props) {
  const [cookies] = useCookies();
  const [apiToken] = useState(cookies.api_token);

  const getPdf = async (obbj) => {
    Repo.getCartellaDownload(
      obbj.tokenDownload,
      obbj.idCartella,
      obbj.idTipoEntrata
    )
      .then(function (response) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "file.pdf");
        document.body.appendChild(link);
        link.click();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <>
      {props.children && props.children.length !== 0 ? (
        <>
          <div className="card-wrapper">
            <div className="card-body">
              {/* -------------------------------------------- */}
              {!props.table ? (
                <>
                  {Array.isArray(props.children) &&
                    props.children.map((obbj, index) => (
                      <div
                        id="collapseDiv1-sc1"
                        className="collapse-div collapse-background"
                        role="tablist"
                        key={index}
                      >
                        <div
                          className="collapse-header"
                          id={"heading1-sc" + index}
                        >
                          <button
                            data-toggle="collapse"
                            data-target={"#collapse1-sc" + index}
                            aria-expanded="true"
                            aria-controls={"collapse1-sc" + index}
                          >
                            {obbj.nomeUnivocoCartella}
                            <div className="row">
                              <div className="col-6">
                                <b>Data Emissione: </b>{" "}
                                {moment(
                                  new Date(obbj.dataEmissioneCartella)
                                ).format("DD-MM-YYYY")}
                              </div>
                              <div className="col-6">
                                <b>Data Notifica: </b>{" "}
                                {moment(
                                  new Date(obbj.dataEmissioneCartella)
                                ).format("DD-MM-YYYY")}
                              </div>
                            </div>
                          </button>
                        </div>
                        <div
                          id={"collapse1-sc" + index}
                          className="collapse show"
                          role="tabpanel"
                          aria-labelledby={"heading1-sc" + index}
                        >
                          <div className="collapse-body">
                            <div className="row">
                              <div className="col-8">
                                <div className="row">
                                  {" "}
                                  <b>Descrizione: </b>Lorem Ipsum is simply
                                  dummy text of the printing and typesetting
                                  industry.
                                </div>
                                <div className="row">
                                  <button
                                    type="button"
                                    className="btn btn-primary btn-sm mt-2"
                                    style={{ marginRight: "5px" }}
                                    onClick={() => {
                                      props.setDettaglio(obbj);
                                      window.scroll(0, 0);
                                    }}
                                  >
                                    Dettaglio
                                  </button>

                                  <button
                                    type="button"
                                    className="btn btn-secondary btn-sm mt-2"
                                    onClick={() => getPdf(obbj)}
                                  >
                                    <svg className="icon icon-sm icon-white">
                                      <use href={`${iconSprite}#it-download`} />
                                    </svg>{" "}
                                    <span>Scarica</span>
                                  </button>
                                </div>
                              </div>
                              <div className="col-4">
                                <div className="row">
                                  <b>Anno Imposta: </b> {obbj.annoImposta}
                                </div>
                                <div className="row">
                                  <b>Importo Dovuto: </b> {obbj.importoDovuto} €
                                </div>
                                <div className="row">
                                  <b>Importo Versato: </b> {obbj.importoVersato}{" "}
                                  €
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}{" "}
                </>
              ) : (
                <>
                  <div className="row">
                    <div className="col-12">
                      <div
                        className="card card-teaser rounded text-primary font-weight-bold"
                        style={{ fontSize: "32px" }}
                      >
                        Lista Cartelle
                      </div>

                      {Array.isArray(props.children) &&
                        props.children.map((obbj, index) => (
                          <div
                            className="card card-teaser rounded shadow mb-2"
                            key={index}
                          >
                            <div className="card-body">
                              <div className="text-left">
                                <div className="row">
                                  <div className="col-lg-8 col-sm-12">
                                    {" "}
                                    <h5 className="text-primary">
                                      {!obbj.connessioneFallita && obbj.nomeUnivocoCartella
                                        ? obbj.nomeUnivocoCartella
                                        : "Cartella Non Trovata"}
                                    </h5>
                                  </div>

                                  {!obbj.connessioneFallita && (
                                    <div className="col-lg-4 col-sm-12 d-flex flex-row-reverse">
                                      {" "}
                                      <button
                                        type="button"
                                        className="btn btn-secondary btn-sm mb-2 btn-icon"
                                        onClick={() => getPdf(obbj)}
                                      >
                                        <svg className="icon icon-sm icon-white">
                                          <use
                                            href={`${iconSprite}#it-download`}
                                          />
                                        </svg>{" "}
                                        <span>Scarica</span>
                                      </button>
                                      <button
                                        type="button"
                                        className="btn btn-primary btn-sm mb-2 btn-icon"
                                        style={{ marginRight: "5px" }}
                                        onClick={() => {
                                          props.setDettaglio(obbj);
                                        }}
                                      >
                                        <svg className="icon icon-sm icon-white">
                                          <use href={`${iconSprite}#it-file`} />
                                        </svg>{" "}
                                        <span>Dettaglio</span>
                                      </button>
                                    </div>
                                  )}
                                </div>

                                {!obbj.connessioneFallita && (
                                  <>
                                    {" "}
                                    <div className="row mt-3">
                                      <div className="col-sm-6 col-lg-3">
                                        Data Notifica:{" "}
                                        <b>
                                          {" "}
                                          {moment(
                                            new Date(obbj.dataEmissioneCartella)
                                          ).format("DD-MM-YYYY")}{" "}
                                        </b>
                                      </div>
                                      <div className="col-sm-6 col-lg-3 ">
                                        Dovuto: <b>{obbj.importoDovuto} € </b>
                                      </div>
                                      <div className="col-sm-6 col-lg-3">
                                        Versato: <b>{obbj.importoVersato} € </b>
                                      </div>
                                      {obbj.flagPagabile == 1 && (
                                        <div className="col-sm-6 col-lg-3">
                                          <svg className="icon icon-sm mb-1">
                                            <use
                                              href={`${iconSprite}#it-warning-circle`}
                                            />
                                          </svg>{" "}
                                          <b>
                                            <span>Da Pagare</span>
                                          </b>{" "}
                                        </div>
                                      )}
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      ) : (
        <b>Nessun Risultato da Mostrare</b>
      )}
    </>
  );
}

export default TabellaFascicolo;

import React, { useEffect, useState, useContext } from "react";
import Cookies from "universal-cookie";
import moment from "moment";
import Select from "../Objects/Select.js";
import useLocalStorage from "../Utility/useLocalStorage.js";
import { ApiContext } from "../Contexts/FascicoloContext";
import Breadcrumb from "../Layout/Breadcrumb";

// import TabellaFascicolo from '../Objects/TabellaFascicolo'
import Repo from "../Utility/Repo";

// import Pagination from "../Objects/Pagination";
import AlertMessage from "../Objects/AlertMessage";
import { useCookies } from "react-cookie";
import TabellaFascicolo from "../Objects/TabellaFascicolo";
import Pagination from "../Objects/Pagination";

import DettaglioPagamentiAttesi from "./DettaglioPagamentiAttesi";
import DettaglioCartella from "./DettaglioCartella";
import Cartella from "../Objects/Cartella.js";
import { Link } from "react-router-dom";

function Fascicolo(props) {
  const [cookies] = useCookies();
  const [apiToken] = useState(cookies.api_token);
  const [fiscalCode] = useState(props.codiceFiscale);
  const [loading, setLoading] = useState();
  const [fascicolo, setFascicolo] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorFlag, setErrorFlag] = useState(false);

  const [pageCount, setPageCount] = useState(0);
  const [queryCount, setQueryCount] = useState(0);
  const [response, setResponse, removeResponse] =
    useLocalStorage("responseFascicolo");
  let pageIndex = 0;
  const [pindex, setPIndex] = useState(0);
  const [cartella, setCartella] = useState();

  const [pagamentoDovuto, setpagamentoDovuto] = useState();
  const [rata, setRata] = useState();

  const { responseFascicolo, setResponseFascicolo } = useContext(ApiContext);

  const [data, setData] = useState({
    CFPIva: "",
    pageIndex: 0,
    pageSize: 9,
    orderDescending: true,
    orderField: "CFPIva",
    tipoCartella: "",
    tipoEntrata: "",
    annoImposta: 0,
    annoEmissione: 0,
  });

  const ChangePage = (numberPage) => {
    var page = numberPage - 1;
    var dataTemp = data;
    dataTemp.pageIndex = page;
    setData(dataTemp);
    pageIndex = numberPage - 1;
    setPIndex(pageIndex);

    reload(JSON.stringify(dataTemp));
  };

  const reload = (dataJson) => {
    setLoading(false);
    if (pageIndex in responseFascicolo) {
      setFascicolo(
        responseFascicolo[pageIndex].items
          ? responseFascicolo[pageIndex].items
          : []
      );

      setPageCount(responseFascicolo[pageIndex].pageCount);
      setQueryCount(responseFascicolo[pageIndex].queryCount);
      pageIndex = responseFascicolo[pageIndex].pageIndex;
      setPIndex(pageIndex);
      setLoading(true);
    } else {
      Repo.getFascicolo(apiToken, dataJson)
        .then((response) => {
          var tempDict = responseFascicolo;
          tempDict[response.data.pageIndex] = response.data;
          setFascicolo(response.data.items ? response.data.items : []);
          setResponseFascicolo(tempDict);
          setPageCount(response.data.pageCount);
          setQueryCount(response.data.queryCount);
          pageIndex = response.data.pageIndex;
          setPIndex(pageIndex);
          setLoading(true);
          setErrorFlag(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(true);
          setErrorFlag(true);
        });
    }
  };

  function isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

  useEffect(() => {
    if (props.codiceFiscale) {
      var dataObb = data;
      dataObb.CFPIva = props.codiceFiscale;
      var dataJson = JSON.stringify(dataObb);
      setData(dataObb);

      if (responseFascicolo.length > 0) {
        setFascicolo(responseFascicolo.items);
        setPageCount(responseFascicolo.pageCount);
        setQueryCount(responseFascicolo.queryCount);
        pageIndex = responseFascicolo.pageIndex;
        setPIndex(pageIndex);
        setLoading(true);
      } else reload(dataJson);
    }
  }, [props.codiceFiscale]);

  const handleChange = (event) => {
    var value = event.target.value;
    switch (event.target.name) {
      case "valueDateEmmision":
        setData((prevState) => ({
          ...prevState,
          AnnoImposta: value,
        }));
        break;

      case "valueTaxYear":
        setData((prevState) => ({
          ...prevState,
          AnnoEmissione: value,
        }));
        break;

      default:
        break;
    }
  };

  const handleChangeSelect = (event, nameOfComponent) => {
    let name = nameOfComponent.name;
    let value = event.value;

    switch (name) {
      case "valueFoldertype":
        setData((prevState) => ({
          ...prevState,
          TipoCartella: value,
        }));
        break;

      case "valueDataEntry":
        setData((prevState) => ({
          ...prevState,
          TipoEntrata: value,
        }));
        break;

      default:
        break;
    }
  };

  const handleSubmit = (event) => {
    setLoading(false);
    event.preventDefault();
    setResponseFascicolo([]);
    reload(JSON.stringify(data));
  };

  const selectItem = () => {
    window.scroll(0, 0);
    if (rata) {
      return (
        <>
          <div className="card-wrapper card-space">
            <div className="card card-bg">
              <div className="card-body">
                {" "}
                <Cartella>{cartella}</Cartella>
              </div>

              <DettaglioPagamentiAttesi
                dettaglioCartella={true}
                setRataDettaglio={setRata}
                user={props.user}
              >
                {rata}
              </DettaglioPagamentiAttesi>
            </div>{" "}
          </div>
        </>
      );
    } else if (pagamentoDovuto) {
      return (
        <div className="card-wrapper card-space">
          <div className="card card-bg">
            <div className="card-body">
              {" "}
              <Cartella>{cartella}</Cartella>
            </div>

            <DettaglioPagamentiAttesi
              dettaglioCartella={true}
              setRataDettaglio={setRata}
              user={props.user}
            >
              {pagamentoDovuto}
            </DettaglioPagamentiAttesi>
          </div>{" "}
        </div>
      );
    } else if (cartella)
      return (
        <DettaglioCartella
          setDettaglio={setCartella}
          setRataDettaglio={setRata}
          setpagamentoDovuto={setpagamentoDovuto}
          user={props.user}
        >
          {cartella}
        </DettaglioCartella>
      );
    else return false;
  };

  window.onpopstate = function () {
    if (rata) {
      setRata();
    } else if (pagamentoDovuto) {
      setpagamentoDovuto();
    } else if (cartella) {
      setCartella();
    } else {
      removeResponse();
      return window.location.replace(props.subfolder + "/");
    }
  };

  const onBackButtonEvent = (e) => {
    e.preventDefault();

    if (rata) {
      setRata();
    } else if (pagamentoDovuto) {
      setpagamentoDovuto();
    } else if (cartella) {
      setCartella();
    } else {
      removeResponse();
      return window.location.replace(props.subfolder + "/");
    }
    window.history.pushState({}, "");
  };

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", onBackButtonEvent);
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  }, [cartella, pagamentoDovuto, rata]);

  return (
    <>
      <div className="clearfix">
        <div className="float-left">
          <nav className="breadcrumb-container pl-2" aria-label="breadcrumb">
            <ol className="breadcrumb">
              {rata ? (
                <>
                  <Link
                    style={{ color: "inherit", textDecoration: "inherit" }}
                    to={"/"}
                  >
                    Home
                  </Link>
                  &nbsp; <b>/</b>
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setCartella();
                      setpagamentoDovuto();
                      setRata();
                    }}
                  >
                    &nbsp; Fascicolo &nbsp;
                  </a>{" "}
                  <b>/</b>
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setRata();
                      setpagamentoDovuto();
                    }}
                  >
                    &nbsp; Dettaglio &nbsp;
                  </a>{" "}
                  <b>/</b>
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setRata();
                    }}
                  >
                    &nbsp; Pagamento &nbsp;
                  </a>{" "}
                  <b>/</b>
                  <a className={"breadcrumb-item active"}>
                    &nbsp; <b>Rate</b> &nbsp;
                  </a>
                </>
              ) : pagamentoDovuto ? (
                <>
                  <Link
                    style={{ color: "inherit", textDecoration: "inherit" }}
                    to={"/"}
                  >
                    Home
                  </Link>
                  &nbsp; <b>/</b>
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setCartella();
                      setpagamentoDovuto();
                      setRata();
                    }}
                  >
                    &nbsp; Fascicolo &nbsp;
                  </a>{" "}
                  <b>/</b>
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setRata();
                      setpagamentoDovuto();
                    }}
                  >
                    &nbsp; Dettaglio &nbsp;
                  </a>{" "}
                  <b>/</b>
                  <a className={"breadcrumb-item active"}>
                    &nbsp; <b>Pagamento</b> &nbsp;
                  </a>
                </>
              ) : cartella ? (
                <>
                  <Link
                    style={{ color: "inherit", textDecoration: "inherit" }}
                    to={"/"}
                  >
                    Home
                  </Link>
                  &nbsp; <b>/</b>
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setCartella();
                      setpagamentoDovuto();
                      setRata();
                    }}
                  >
                    &nbsp; Fascicolo &nbsp;
                  </a>{" "}
                  <b>/</b>
                  <a className={"breadcrumb-item active"}>
                    &nbsp; <b>Dettaglio</b> &nbsp;
                  </a>
                </>
              ) : (
                <>
                  <Link
                    style={{ color: "inherit", textDecoration: "inherit" }}
                    to={"/"}
                  >
                    Home
                  </Link>
                  &nbsp; <b>/</b>
                  <a className={"breadcrumb-item active"}>
                    &nbsp; <b>Fascicolo</b> &nbsp;
                  </a>
                </>
              )}
            </ol>
          </nav>
        </div>
      </div>

      {!(cartella || pagamentoDovuto || rata) ? (
        <>
          {" "}
          {errorFlag && (
            <AlertMessage
              error={true}
              messaggio={
                "Alcuni dati potrebbero essere visualizzati non correttamente"
              }
            ></AlertMessage>
          )}
          <div className="col-12">
            <div className="card card-teaser rounded shadow mb-2">
              <div className="card-body">
                <h3>Fascicolo del Contribuente</h3>

                <br />

                <div>
                  <form onSubmit={handleSubmit}>
                    <div>
                      <div className="form-row mb-4">
                        <div className="form-group col-12">
                          <div className="bootstrap-select-wrapper">
                            <label>Tipo Entrata</label>

                            <Select
                              aria-label="Scegli una opzione"
                              clearText="Annulla"
                              defaultInputValue=""
                              defaultMenuIsOpen={false}
                              defaultValue={null}
                              icon={false}
                              name="valueDataEntry"
                              onChange={handleChangeSelect}
                              options={[
                                {
                                  value: "tipo1",
                                  label: "Tipo 1",
                                },
                                {
                                  value: "tipo2",
                                  label: "Tipo 2",
                                },
                                {
                                  value: "tipo3",
                                  label: "Tipo 3",
                                },
                              ]}
                              placeholder="Scegli una opzione"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="form-row">
                        <div className="form-group col-6">
                          <div className="form-group col-12">
                            {" "}
                            <input
                              type="number"
                              className="form-control"
                              id="valueDateEmmision"
                              name="valueDateEmmision"
                              defaultValue={moment().format("YYYY")}
                              max={moment().format("YYYY")}
                              onChange={handleChange}
                              min="1900"
                            />
                            <label htmlFor="annoImposta" className="active">
                              Anno Imposta
                            </label>
                          </div>
                        </div>

                        <div className="form-group col-6">
                          <div className="form-group col-12">
                            {" "}
                            <input
                              type="number"
                              className="form-control"
                              id="valueTaxYear"
                              name="valueTaxYear"
                              defaultValue={moment().format("YYYY")}
                              max={moment().format("YYYY")}
                              onChange={handleChange}
                              min="1900"
                            />
                            <label htmlFor="annoImposta" className="active">
                              Anno Emmisione
                            </label>
                          </div>
                        </div>

                        <div className="form-group col text-center">
                          <button type="submit" className="btn btn-primary">
                            Cerca{" "}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            {loading ? (
              <TabellaFascicolo
                table={true}
                errorControll={setErrorFlag}
                setDettaglio={setCartella}
              >
                {fascicolo}
              </TabellaFascicolo>
            ) : (
              <div className="col-12">
                <div className="trasparente">
                  <div className="progress-spinner progress-spinner-active spinner">
                    <span className="sr-only">Caricamento...</span>
                  </div>
                </div>
              </div>
            )}

            {fascicolo && fascicolo.length !== 0 && (
              <Pagination
                methodChange={ChangePage}
                PageIndex={pindex + 1}
                PageCount={pageCount}
                TotalCount={queryCount}
              ></Pagination>
            )}
          </div>
        </>
      ) : (
        <>{selectItem()}</>
      )}
    </>
  );
}

export default Fascicolo;

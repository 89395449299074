import { useState, useEffect } from "react";
import { Input } from "design-react-kit";
import moment from "moment";
import Repo from "../Utility/Repo";
import { Link } from "react-router-dom";
import ListaPosizioniDebitorie from "../Objects/ListaPosizioniDebitorie";
import { useCookies } from "react-cookie";
import useLocalStorage from "../Utility/useLocalStorage.js";

function PosizioniDebitorie(props) {
  const [posizioniDebitorie, setPosizioniDebitorie] = useState([]);
  const [loading, setLoading] = useState(true);
  const [cookies] = useCookies();
  const [responsePosizione, setResponsePosizione, removeResponse] =
    useLocalStorage("responsePosizioni");
  const [value, setValue, removeValue] = useLocalStorage("contribuente");

  const [apiToken] = useState(cookies.api_token);
  const [dataRequest, setDataRequest] = useState({
    cognomePagatore: null, //si
    cfPiva: "", //SI
    //pageIndex: 0,
    //pageSize: 0,
    //orderDescending: false,
    //orderField: "Cognome",
  });

  const handleChange = (event) => {
    var value = event.target.value;
    switch (event.target.name) {
      case "cfPiva":
        setDataRequest((prevState) => ({
          ...prevState,
          cfPiva: value,
        }));
        break;

      case "cognomePagatore":
        setDataRequest((prevState) => ({
          ...prevState,
          cognomePagatore: value,
        }));
        break;

      case "ragioneSociale":
        setDataRequest((prevState) => ({
          ...prevState,
          ragioneSociale: value,
        }));
        break;

      case "nomePagatore":
        setDataRequest((prevState) => ({
          ...prevState,
          nomePagatore: value,
        }));
        break;

      case "idTipoEntrata":
        setDataRequest((prevState) => ({
          ...prevState,
          idTipoEntrata: value ? value : null,
        }));
        break;

      case "casualeVersamento":
        setDataRequest((prevState) => ({
          ...prevState,
          casualeVersamento: value ? value : null,
        }));
        break;

      default:
        break;
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    var dataTemp = dataRequest;
    setDataRequest(dataTemp);
    if (apiToken) {
      reload(JSON.stringify(dataTemp));
    }
  };

  const reload = () => {
    var dataJson = dataRequest;
    dataJson = JSON.stringify(dataJson);
    setLoading(false);
    Repo.getPosizioniDebitorie(apiToken, dataJson)
      .then((response) => {
        setPosizioniDebitorie(response.data.items ? response.data.items : []);

        setResponsePosizione(response.data.items ? response.data.items : []);

        setLoading(true);
      })
      .catch((error) => {
        console.log(error);
        setLoading(true);
      });
  };

  useEffect(() => {
    if (responsePosizione && responsePosizione.length > 0) {
      setPosizioniDebitorie(responsePosizione);
    } else {
      reload();
    }
  }, []);

  const deleteMethod = (temp) => {
    Repo.deletePosizioneDebitoria(apiToken, temp.id)
      .then((response) => {
        reload();
      })
      .catch((error) => {});

    reload();
  };

  return (
    <>
      {" "}
      <div className="row">
        <div className="col-12 ">
          <div className="card-wrapper card-space mb-0">
            <div className="card card-bg">
              <div className="card-body">
                <div className="row">
                  <div className="col-8">
                    <h5 className="card-title">Ricerca Posizioni Debitorie</h5>
                  </div>
                  <div className="col-4 text-right">
                    <Link
                      to={{
                        pathname: "/posizioni_debitorie/nuovo",
                      }}
                    >
                      <button className="btn btn-primary mr-1">
                        Aggiungi nuova posizione
                      </button>
                    </Link>
                  </div>
                </div>

                <form onSubmit={handleSubmit} className="mt-5">
                  <div>
                    <div className="form-row mb-4">
                      <div className="form-group col-6">
                        <div className="form-group col-12">
                          <input
                            type="text"
                            className="form-control"
                            id="cfPiva"
                            name="cfPiva"
                            defaultValue={value ? value.cfPiva : ""}
                            onChange={handleChange}
                          />
                          {value ? (
                            <>
                              <label className="active" htmlFor="cfPiva">
                                Codice Fiscale / P.Iva
                              </label>
                            </>
                          ) : (
                            <>
                              <label htmlFor="cfPiva">
                                Codice Fiscale / P.Iva
                              </label>
                            </>
                          )}
                        </div>
                      </div>
                      {/* <div className="form-group col-4">
                        <div className="form-group col-12">
                          <input
                            type="text"
                            className="form-control"
                            id="nomePagatore"
                            name="nomePagatore"
                            onChange={handleChange}
                          />
                          <label htmlFor="nomePagatore">Nome Pagatore</label>
                        </div>
                      </div> */}
                      <div className="form-group col-6">
                        <div className="form-group col-12">
                          <input
                            type="text"
                            className="form-control"
                            id="cognomePagatore"
                            name="cognomePagatore"
                            onChange={handleChange}
                          />
                          <label htmlFor="cognomePagatore">
                            Cognome Pagatore
                          </label>
                        </div>
                      </div>
                    </div>
                    {/* <div className="form-row mb-4">
                      <div className="form-group col-6">
                        <div className="form-group col-12">
                          {" "}
                          <input
                            type="text"
                            className="form-control"
                            id="idTipoEntrata"
                            name="idTipoEntrata"
                            onChange={handleChange}
                          />
                          <label htmlFor="idTipoEntrata">
                            Identificativo pagamento
                          </label>
                        </div>
                      </div> */}
                    {/* <div className="form-group col-6">
                        <div className="form-group col-12">
                          {" "}
                          <input
                            type="text"
                            className="form-control"
                            id="casualeVersamento"
                            name="casualeVersamento"
                            onChange={handleChange}
                          />
                          <label htmlFor="casualeVersamento">
                            Causale Versamento
                          </label>
                        </div>
                      </div> 
                    </div>*/}

                    <div className="form-group col-lg-12 text-center">
                      <button type="submit" className="btn btn-primary">
                        Cerca{" "}
                      </button>
                    </div>
                  </div>
                </form>
                <ListaPosizioniDebitorie
                  posizioniDebitorie={posizioniDebitorie}
                  setDeleteMethod={deleteMethod}
                ></ListaPosizioniDebitorie>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!loading && (
        <div className="col-12">
          <div className="trasparente">
            <div className="progress-spinner progress-spinner-active spinner">
              <span className="sr-only">Caricamento...</span>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PosizioniDebitorie;

import { useEffect } from "react";

function NotifyPanel() {
  return (
    <>
      <div id="accordionDiv2" className="collapse-div border-0" role="tablist">
        <div
          className="collapse-header pl-5 pr-5"
          id="headingA2"
          style={{ fontSize: "20px", height: "55px" }}
        >
          <button
            data-toggle="collapse"
            data-target="#accordion2"
            aria-expanded="true"
            aria-controls="accordion2"
            className="collapseCustom"
          >
            Notifiche
          </button>
        </div>
        <div
          id="accordion2"
          className="collapse show"
          role="tabpanel"
          aria-labelledby="headingA2"
          data-parent="#accordionDiv2"
        >
          <div className="collapse-body pt-0 mb-0 pb-0 text-left">
            <div className="border-bottom rounded">
              <div className="d-inline-block">
                <i
                  className="fas fa-exclamation-circle text-danger pl-3 pr-2 d-inline"
                  style={{ fontSize: "26px" }}
                ></i>
              </div>
              <div className="d-inline-block pt-0 pb-2">
                <h6 className="mb-0">TARI</h6>
                <p className="mb-0 mp-0" style={{ fontSize: "16px" }}>
                  E' presente un pagamento TARI scaduto per il comune di{" "}
                  <b>Bitonto</b>.
                </p>
              </div>
            </div>
          </div>
          <div className="collapse-body pt-1 mb-0 pb-0 text-left">
            <div>
              <div className="d-inline-block">
                <i
                  className="fas fa-exclamation-circle text-warning pl-3 pr-2 d-inline"
                  style={{ fontSize: "26px" }}
                ></i>
              </div>
              <div className="d-inline-block pt-1 pb-3">
                <h6 className="mb-0">IMU</h6>
                <p className="mb-0 mp-0" style={{ fontSize: "16px" }}>
                  E' presente un pagamento IMU in scadenza per il comune di{" "}
                  <b>Altamura</b>.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default NotifyPanel;

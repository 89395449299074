import { useEffect } from "react";
import mainLogo from "../../Img/mainlogoBlue.png";
import logo from "../../Img/logoblu.png";
import sfondo from "../../Img/sfondodivestiti.png";
import Device from "../../Img/Full Device.png";
import CieSpid from "../../Img/CieSpid.png";
import configuration from "../Utility/config.json";
import Chatbot from "../../Img/chatbot.png";
import { Link } from "react-router-dom";

function Home(props) {
  const baseUrl = location.protocol + "//" + location.host;

  const hub_url = window.__RUNTIME_CONFIG__.REACT_APP_HUB_URL;




  return (
    <>
      <div className="it-hero-wrapper it-dark it-overlay pt-4 pb-4">
        <div className="img-responsive-wrapper">
          <div className="img-responsive">
            <div className="img-wrapper">
              <img src={sfondo} title="img title" alt="imagealt" />
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="it-hero-text-wrapper text-primary">
                <h3 className="it-category text-white text-sans-serif">
                  SmartCityPA
                </h3>
                <br />
                <h5 className="text-white text-sans-serif">
                  {" "}
                  SmartCityPA è una piattaforma che permette ai cittadini di
                  accedere, in modo rapido e sicuro ai servizi di pagamento nei
                  confronti dell’ente, eliminando le attese ed avendo un reale
                  risparmio di tempo.
                </h5>
                <br />
                <div className="btnHome">
                  <a
                    href={
                      hub_url +
                      configuration.AUTH_PATH_LOGIN +
                      baseUrl
                    }
                    className=""
                  >
                    <button
                      type="button"
                      className="btn btn-white btn-md text-primary"
                    >
                      ACCEDI AI SERVIZI{" "}
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section section-muted pt-5">
        <div className="section-content">
          <span className="text-dark">
            <img
              height="80"
              src={logo}
              alt="logo"
              className="mx-auto d-block"
            />
            <div className="it-brand-text">
              <h2 className="no_toc pb-1 ">
                <img
                  src={mainLogo}
                  alt="mainlogo"
                  className="img-fluid mx-auto d-block"
                />
              </h2>
            </div>
          </span>
          <hr className="hrCustom" />
          <br />
          <div className="card-deck">
            <div className="card shadow border-0 mg-md-4">
              <div className="card-body">
                <h3 className="mb-4">ACCESSO E SICUREZZA</h3>
                <h6 className="text-sans-serif">
                  AUTENTICANDOSI, TRAMITE SPID (SISTEMA PUBBLICO DI IDENTITÀ
                  DIGITALE O ATTRAVERSO CARTA D’IDENTITÀ DIGITALE), È POSSIBILE
                  ACCEDERE AD ULTERIORI FUNZIONALITA' COME LA CONSULTAZIONE DEL
                  PROPRIO “FASCICOLO DEL CONTRIBUENTE”, DEI “PAGAMENTI ATTESI”
                  DALL’ENTE E DEI “PAGAMENTI EFFETTUATI”.{" "}
                </h6>
                <div className="img-wrapper text-center">
                  <img className="imageCustom" src={CieSpid} />
                </div>
              </div>
            </div>
            <div className="card shadow border-0 mg-md-4">
              <div className="card-body">
                <h3 className="mb-4">OPERAZIONI DA NON REGISTRATO</h3>
                <h6 className="text-sans-serif">
                  {" "}
                  NON AUTENTICANDOSI È COMUNQUE POSSIBILE EFFETTUARE IL
                  PAGAMENTO DI UN AVVISO TRAMITE PAGO PA O UN PAGAMENTO
                  SPONTANEO (IMU, TARI, TASI)
                </h6>
                <div className="btnHome">
                  <button
                    type="button"
                    className="btn btn-secondary btn-sm btn-icon btn-block rounded text-left text-white"
                    style={{ cursor: "default" }}
                  >
                    EFFETTUA IL PAGAMENTO DI UN AVVISO TRAMITE PAGOPA{" "}
                  </button>
                </div>
                <div className="btnHome">
                  <Link to="/validazione_otp">
                    <button
                      type="button"
                      className="btn btn-primary btn-sm btn-icon btn-block rounded text-left text-white"
                    >
                      EFFETTUA UN PAGAMENTO SPONTANEO{" "}
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section pt-5">
        <div className="section-content text-center ">
          <h2 className="mb-4 text-primary">
            APP, PORTALE WEB O SPORTELLO DIGITALE
          </h2>
          <div className="img-wrapper">
            <img width="80%" src={Device} title="totem" alt="" />
          </div>
          <h5 className="card-text text-center">
            <br />
            OGNI CITTADINO PUÒ ACCEDERE AI SERVIZI, IN PIENA AUTONOMIA,
            SCEGLIENDO IL DISPOSITIVO PIÙ COMODO IN BASE ALLE PROPRIE
            ESIGENZE...{" "}
          </h5>
        </div>
      </div>

      <div className="section section-muted pt-5">
        <div className="container pt-md-0 pb-5 pb-md-0">
          <div className="row">
            <div className="col-md-5  pl-3 pt-3 pb-4 py-md-5  px-md-5 text-center align-items-center d-md-flex">
              <img
                className="img-fluid rounded-circle shadow"
                src={Chatbot}
                alt="Image"
              />
            </div>
            <div className="col-md-6 pl-3 pt-3 py-md-5 px-md-5 ">
              <h2 className="text-primary pb-4 pt-lg-4">AI CHATBOT</h2>
              <p>
                È stato integrato un sistema di <b>Intelligenza Artificiale</b>{" "}
                che consente al cittadino di porre delle domande, al fine di
                ottenere informazioni sul dominio di interesse. Si parla, in
                particolare, di un agente conversazionale in grado di instaurare
                un dialogo, comprendere ciò che viene richiesto dal
                contribuente, rispondere a tali domande sulla base della
                conoscenza in possesso e, contemporaneamente, imparare a
                riconoscere diverse richieste.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="it-hero-wrapper it-dark it-overlay it-hero-small-size it-header-center-wrapper pt-4 pb-4 ">
        <div className="col-12">
          <div className="text-primary text-center">
            <h2 className="it-category text-white">
              RICHIEDI UNA DEMO GRATUITA
            </h2>
            <br />
            <div className="btnHome">
              <Link
                to="https://www.servizilocalispa.it/contatti/"
                className="btn btn-white btn-lg text-primary"
              >
                CONTATTACI
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;

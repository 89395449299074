import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const Breadcrumb = (props) => {
  const [linkHistory, setLink] = useState([]);
  let location = useLocation();

  useEffect(() => {
    setLink(location.pathname.split("/"));
  }, [location.pathname]);

  function isLast(index) {
    return index === linkHistory.length - 1;
  }

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <>
      <nav className="breadcrumb-container pl-2" aria-label="breadcrumb">
        <ol className="breadcrumb">
          {location.pathname !== "/home" && location.pathname !== "/" && (
            <>
              {" "}
              <p>
                {
                  <>
                    <li className={"breadcrumb-item "}>
                      <a href={"/" + "home"}>Home</a>
                    </li>
                  </>
                }
              </p>
              {linkHistory.map((x, index) => (
                <p key={index}>
                  {
                    <>
                      {isLast(index) ? (
                        <li className={"breadcrumb-item active"}>
                          <a>{capitalizeFirstLetter(x.replace("_", " "))}</a>
                        </li>
                      ) : (
                        <li className={"breadcrumb-item "}>
                          <a href={"/" + x}>
                            {capitalizeFirstLetter(x.replace("_", " "))}
                          </a>
                          <span className="separator">/</span>
                        </li>
                      )}
                    </>
                  }
                </p>
              ))}
            </>
          )}
        </ol>
      </nav>
    </>
  );
};

export default Breadcrumb;

import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import Repo from "../Utility/Repo";
import { Input } from "design-react-kit";
import Notification from "../Objects/Notification";
import { Link } from "react-router-dom";

function ValidazioneOTP(props) {
  const [cookies] = useCookies();

  const [apiToken] = useState(cookies.api_token);

  const [user, setUser] = useState({});

  const [otpToken] = useState();
  const [lengthOtp, setLengthOtp] = useState(0);
  const [visibileOTP, setVisibileOTP] = useState(false);

  const [isErrorCf, setFlagCf] = useState(false);

  const [data, setData] = useState({
    nome: "",
    CFPIVA: "",
    email: "",
  });

  const [otp, setOTP] = useState({
    OTP: "",
    token: "",
  });

  useEffect(() => {
    if (apiToken) {
      Repo.getUserProfile(apiToken)
        .then((response) => {
          setData({
            nome: response.data.FirstName + " " + response.data.LastName,
            CFPIVA: response.data.FiscalCode,
            email: response.data.Email,
          });
          setVisibileOTP(true);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  const handleChange = (event) => {
    let value = event.target.value;
    let name = event.target.name;

    switch (name) {
      case "nome":
        setData((prevState) => ({
          ...prevState,
          nome: value,
        }));

        break;

      case "CFPIVA":
        setData((prevState) => ({
          ...prevState,
          CFPIVA: value,
        }));

        break;

      case "email":
        setData((prevState) => ({
          ...prevState,
          email: value,
        }));

        break;

      default:
        break;
    }
  };

  const handleChangeOTP = (event) => {
    let tempOtp = otp;
    let value = event.target.value;
    let name = event.target.name;

    tempOtp.OTP = value;
    setOTP(tempOtp);
    setLengthOtp(otp.OTP.length);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    debugger;
    if (visibileOTP == false) {
      if (data.CFPIVA && data.nome && data.email) {
        setOTP((prevState) => ({
          ...prevState,
          token: "666",
        }));
        setVisibileOTP(true);
      }
    }
  };

  const handleSubmit2 = (event) => {
    event.preventDefault();
    if (lengthOtp == 6) {
      alert("");
      //invio API
    }
  };

  /*function check_tax_code(cf) {
    setFlagCf(false);

    console.log("cf: ", cf);
    var validi, i, s, set1, set2, setpari, setdisp;
    if (cf == "") return "";
    cf = cf.toUpperCase();
    if (cf.length != 16) setFlagCf(true);
    validi = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    for (i = 0; i < 16; i++) {
      if (validi.indexOf(cf.charAt(i)) == -1) setFlagCf(true);
    }
    set1 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    set2 = "ABCDEFGHIJABCDEFGHIJKLMNOPQRSTUVWXYZ";
    setpari = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    setdisp = "BAKPLCQDREVOSFTGUHMINJWZYX";
    s = 0;
    for (i = 1; i <= 13; i += 2)
      s += setpari.indexOf(set2.charAt(set1.indexOf(cf.charAt(i))));
    for (i = 0; i <= 14; i += 2)
      s += setdisp.indexOf(set2.charAt(set1.indexOf(cf.charAt(i))));
    if (s % 26 != cf.charCodeAt(15) - "A".charCodeAt(0)) {
      setFlagCf(true);
    }
  }*/

  return (
    <>
      {/*       <Notification
        fix="top-fix"
        header="Salvataggio avvenuto con successo"
        state="success"
        withIcon
        dismissable="true"
      /> */}
      <div className="clearfix">
        <div className="float-left">
          <nav className="breadcrumb-container pl-2" aria-label="breadcrumb">
            <ol className="breadcrumb">
              <Link
                style={{ color: "inherit", textDecoration: "inherit" }}
                to={"/"}
              >
                Home
              </Link>
              &nbsp; <b>/</b>
              <a className={"breadcrumb-item active"}>
                &nbsp; <b>Validazione OTP</b> &nbsp;
              </a>{" "}
            </ol>
          </nav>
        </div>
      </div>
      <div className="card card-teaser rounded shadow mb-5">
        <div className="card-body">
          <div
            className="category-top mb-5 "
            {...(!apiToken
              ? { className: "category-top" }
              : { className: "category-top mb-5" })}
          >
            <b className="category">Intestatario Pagamento</b>
          </div>
          {!apiToken && (
            <div className="alert alert-info mb-5 pb-0 info_alert" role="alert">
              <p>
                In questa sezione riportare nome, cognome e codice fiscale
                oppure la Ragione Sociale e partita IVA{" "}
                <b>
                  riportate nella partica per cui si sta effettuando il
                  pagamento
                </b>
              </p>
            </div>
          )}
          {isErrorCf && (
            <div
              className="alert alert-danger mb-5 pb-0 info_alert"
              role="alert"
            >
              <p>Il codice fiscale non è corretto.</p>
            </div>
          )}
          <form onSubmit={handleSubmit} className="">
            <div id="dataform" className="form-row">
              <div className="form-group col-md-6">
                <Input
                  id="exampleInputText1"
                  label="Nome Cognome/Ragione Sociale/Denominazione Sociale"
                  type="text"
                  name="nome"
                  required
                  disabled={visibileOTP}
                  value={data.nome}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col-md-6">
                <Input
                  label="Codice Fiscale/ Partita Iva"
                  id="exampleInputText2"
                  type="text"
                  name="CFPIVA"
                  maxLength="16"
                  required
                  disabled={visibileOTP}
                  value={data.CFPIVA}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col-md-12">
                <Input
                  label="Email"
                  id="email"
                  type="email"
                  name="email"
                  maxLength=""
                  minLength=""
                  infoText="Inserire la propria email (non PEC) necessaria per accedere alla procedura di pagamento"
                  required
                  disabled={visibileOTP}
                  value={data.email}
                  onChange={handleChange}
                />
              </div>
            </div>
            {!apiToken && (
              <div className="float-right">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={visibileOTP}
                >
                  Verifica Dati
                </button>
              </div>
            )}
            {apiToken && (
              <div className="btnHome">
                <Link
                  to="/pagamento_spontaneo"
                  type="submit"
                  className="btn btn-primary float-right"
                >
                  Continua{" "}
                </Link>
              </div>
            )}
          </form>
          <br />
          <br />{" "}
        </div>
      </div>
      {visibileOTP && !apiToken && (
        <div className="card card-teaser rounded shadow mb-5 ">
          <div className="card-body">
            <div className="category-top">
              <b className="category">Intestatario Pagamento</b>
            </div>

            <div>
              <div className="alert alert-success mb-5 pb-0" role="alert">
                <h4 className="alert-heading">Grazie!</h4>
                <p>
                  A breve riceverai un codice OTP di conferma all'indirizzo:{" "}
                  <b>{data.email}</b>
                </p>
              </div>

              <form>
                <div id="otpform" className="form-row pt-5">
                  <div className="form-group col-md-10">
                    <Input
                      label="Codice OTP"
                      id="exampleInputText5"
                      type="text"
                      name="otp"
                      maxLength="6"
                      minLength="6"
                      value={data.otp}
                      onChange={handleChangeOTP}
                    />
                  </div>
                  <div className="form-group col-md-2">
                    <div className="float-right">
                      <Link
                        to={{
                          pathname: "/pagamento_spontaneo",
                        }}
                      >
                        {" "}
                        <button
                          className="btn btn-primary"
                          disabled={lengthOtp == 6 ? false : true}
                        >
                          Verifica OTP
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ValidazioneOTP;

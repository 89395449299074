import { Input, Label, FormGroup } from "design-react-kit";
import { Link } from "react-router-dom";

function RichiestaRettifica(props) {
  return (
    <>
      {!props.isOperator && (
        <>
          <div className="px-lg-5 px-2">
            <div className="row pt-md-4">
              <div className="col-lg-10  col-12">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col">
                      <h1>Richiesta di rettifica dati per errori materiali</h1>
                      <br />
                      <p>
                        Il servizio di richiesta di rettifica dati consente di
                        richiedere al proprio comune di residenza la rettifica
                        di presunti{" "}
                        <strong>errori materiali o di trascrizione </strong>
                        riscontrati sui propri dati anagrafici.{" "}
                      </p>
                      <p>
                        Il servizio <strong>NON PUO’</strong> essere utilizzato
                        per richiedere il cambio di residenza o per richiedere
                        la modifica dei propri dati anagrafici che
                        comporterebbero tra le altre cose una nuova attribuzione
                        del codice fiscale (es. richiesta di aggiornamento del
                        nome, del cognome, ecc.).
                        <br />
                        Per richiedere l’aggiornamento dei propri dati
                        anagrafici (nome, cognome, luogo e data di nascita) che
                        compongono il codice fiscale è necessario recarsi presso
                        il proprio comune di residenza.
                      </p>
                      <p>
                        È possibile richiedere la correzione{" "}
                        <strong>SOLO ED ESCLUSIVAMENTE</strong> di presunti
                        errori materiali o di trascrizione che possono essere
                        comprovati da specifica documentazione (es. documento
                        d’identità, passaporto, atto di nascita, ecc.).
                      </p>
                    </div>
                  </div>
                  <br />
                  <div>
                    <p>
                      Consapevole che chiunque rilasci dichiarazioni mendaci è
                      punito dalla legge ai sensi del codice penale e delle
                      leggi in materia, ai sensi e per gli effetti degli
                      articoli 75 e 76 del D.P.R n. 445/2000.
                    </p>

                    <FormGroup check tag="div">
                      <Input id="checkbox1" type="checkbox" />
                      <Label
                        check
                        for="checkbox1"
                        tag="label"
                        widths={["xs", "sm", "md", "lg", "xl"]}
                      >
                        <strong>
                          DICHIARO SOTTO LA MIA PERSONALE RESPONSABILITA’
                        </strong>
                      </Label>
                    </FormGroup>

                    <ul>
                      <li>
                        di aver preso visione dell'informativa riportata nella{" "}
                        <Link
                          to="https://sc.anpr.interno.it/serviziCittadino/dist/docs/guida_compilazione_rettifica.pdf"
                          target="guida"
                        >
                          guida alla compilazione di una richiesta di rettifica.{" "}
                          <span className="sr-only">
                            {" "}
                            apre una nuova finestra
                          </span>
                        </Link>
                      </li>
                      <li>
                        di aver compreso che lo scopo di tale servizio è
                        esclusivamente quello di richiedere la correzione di
                        presunti errori materiali o di trascrizione;
                      </li>
                      <li>di non inviare dichiarazioni mendaci;</li>
                      <li>
                        di non allegare documentazione non pertinente alla
                        richiesta di rettifica.
                      </li>
                    </ul>
                  </div>

                  <div className="row">
                    <div className="col py-4">
                      <div className="mb-5">
                        <Link to="/rettifica_dati" className="btn btn-primary">
                          Procedi
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default RichiestaRettifica;

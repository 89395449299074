import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import Repo from "../Utility/Repo";
import { Input } from "design-react-kit";
import FormGenerator from "../Objects/FormGenerator";
import { Link } from "react-router-dom";
function PagamentoSpontaneo(props) {
  const [cookies] = useCookies();

  const [user, setUser] = useState({});
  const [apiToken] = useState(cookies.api_token);

  const [numberGender, setNumberGender] = useState();

  const [editMode, setEditMode] = useState(false);

  return (
    <>
      <div className="clearfix">
        <div className="float-left">
          <nav className="breadcrumb-container pl-2" aria-label="breadcrumb">
            <ol className="breadcrumb">
              <Link
                style={{ color: "inherit", textDecoration: "inherit" }}
                to={"/"}
              >
                Home
              </Link>
              &nbsp; <b>/</b>
              <Link
                to="/validazione_otp"
                className={"breadcrumb-item"}
                style={{ color: "inherit", textDecoration: "inherit" }}
              >
                &nbsp; Validazione OTP &nbsp;
              </Link>{" "}
              &nbsp; <b>/</b>
              <a className={"breadcrumb-item active"}>
                &nbsp; <b>Pagamento Spontaneo</b> &nbsp;
              </a>{" "}
            </ol>
          </nav>
        </div>
      </div>
      <FormGenerator></FormGenerator>
    </>
  );
}

export default PagamentoSpontaneo;

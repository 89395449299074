import { useState, useEffect } from "react";
import { Input } from "design-react-kit";
import Select from "../Objects/Select.js";
import PagoPAButton from "../Objects/PagoPAButton";
import { Link } from "react-router-dom";

function FormGenerator() {
  const [number, setNumberList] = useState([]);
  const [total, setTotal] = useState(0);

  const [field, setField] = useState({
    formName: "TestForm",
    choice: {
      type: "choice",
      label: "Tipo Pagamento",
      options: [
        {
          label: "Nido",
        },
        {
          label: "Tari",
        },
        {
          label: "Mensa scolastica",
        },
      ],
    },
    globalParameters: [
      {
        name: "Importo pagamento",
        label: "Importo pagamento",
        type: "decimal",
        defaultValue: null,
        regex: "",
        required: true,
        minValue: null,
        maxValue: null,
        value: null,
        readOnly: false,
        visible: true,
      },
    ],
    partialAmountCalculators: [],
  });

  const handleChange = (e) => {
    setTotal(e.value);
  };
  const inputCreate = (x) => {
    switch (x.type) {
      case "string":
        return (
          <Input
            label={x.label}
            type={x.type}
            maxLength={x.max_length}
            minLength={x.min_length}
            required={x.required}
          />
        );
        break;
      case "decimal":
        if (x.label == "totale") {
          return (
            <Input
              label={x.label}
              type="number"
              className="input-number input-number-currency disabled"
              min={x.min_value}
              max={x.max_value}
              step={x.step}
              required={x.required}
              readOnly
              value={total}
            ></Input>
          );
        }
        {
          return (
            <Input
              label={x.label}
              type="number"
              className="input-number input-number-currency"
              min={x.min_value}
              max={x.max_value}
              step={x.step}
              required={x.required}
              onChange={(e) => handleChange(e.target)}
              name={x.name}
            ></Input>
          );
        }

        break;

      case "choice":
        return (
          <Select
            aria-label="Scegli una opzione"
            clearText="Annulla"
            defaultInputValue=""
            defaultMenuIsOpen={false}
            defaultValue={x.options[0]}
            value={x.value}
            icon={false}
            id="selectExampleClassic"
            options={x.options}
            isSearchable
            placeholder="Scegli una opzione"
          />
        );
        break;
    }
  };

  return (
    <form>
      <div className="col-12 col-lg-12">
        <div className="card-wrapper card-space">
          <div className="card card-bg">
            <div className="card-body">
              <div className="category-top">
                <b className="category">Dati Pagamento</b>
              </div>

              {inputCreate(field.choice)}

              {field.globalParameters.map((x, index) => (
                <div key={index} className="form-group my-5">
                  {inputCreate(x)}
                </div>
              ))}
              <div className="row">
                <div className="alert alert-info col-6">
                  <div>
                    <span className="glyphicon glyphicon-info-sign">
                      &nbsp;{" "}
                    </span>
                    <span id="lblExtraInfo">
                      <table>
                        <tbody>
                          <tr>
                            <td>
                              <b>ISEE</b>
                            </td>
                            <td>
                              <b>Prezzo</b>
                            </td>
                          </tr>
                          <tr>
                            <td>da 0,00 a 5000,00</td>
                            <td>€ 1,70</td>
                          </tr>
                          <tr>
                            <td>da 5.001,00 a 8.000,00</td>
                            <td>€ 1,80</td>
                          </tr>
                          <tr>
                            <td>
                              da a 8.001,00 a € 12.000,00&nbsp;&nbsp;&nbsp;
                            </td>
                            <td>€ 1,90</td>
                          </tr>
                          <tr>
                            <td>da a 12.001,00 a 15.000,00</td>
                            <td>€ 2,10</td>
                          </tr>
                          <tr>
                            <td>oltre € 15.001</td>
                            <td>€ 2,20</td>
                          </tr>
                        </tbody>
                      </table>
                    </span>
                    <br />
                  </div>
                </div>

                <div className="col-5 text-right">
                  <br />
                  <label>Totale</label>
                  <hr
                    className="mt-0"
                    style={{ width: "243px", marginRight: "0px" }}
                  />
                  <p>
                    <span id="spnTotaleImportoDovuto">{total}</span> €
                  </p>
                </div>
              </div>
              <div className="float-right">
                <PagoPAButton></PagoPAButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}

export default FormGenerator;

import React, { useState, useEffect } from "react";

import ListaPagamentiAttesi from "../Objects/ListaPagamentiAttesi";
import ListaPagamentiEffettuati from "../Objects/ListaPagamentiEffettuati";
import TabellaFascicolo from "../Objects/TabellaFascicolo";
import AlertMessage from "../Objects/AlertMessage";
import iconSprite from "bootstrap-italia/dist/svg/sprite.svg";
import Repo from "../Utility/Repo";
import { useCookies } from "react-cookie";
import Cartella from "../Objects/Cartella";
import { Redirect } from "react-router-dom";
import Allegati from "../Objects/Allegati";
import ListaRate from "../Objects/ListaRate";

function DettaglioCartella(props) {
  const [cookies] = useCookies();
  const [apiToken] = useState(cookies.api_token);
  const [fiscalCode] = useState(cookies.user_profile.FiscalCode);
  const [pagamentiDovuti, setpagamentiDovuti] = useState([]);
  const [pagamentiEffettuati, setPagamentiEffettuati] = useState([]);
  const [cartelleCorrelate, setCartelleCorrelate] = useState([]);
  const [allegati, setAllegati] = useState([]);
  const [errorMessage, setErrorMessage] = useState(false);
  const [cartella, setCartella] = useState(
    props.children ? props.children : []
  );
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState({
    CFPIva: "",
    idCartella: cartella ? cartella.idCartella : "",
    idTipoEntrata: cartella ? cartella.idTipoEntrata : "",
    pageIndex: 0,
    pageSize: 4,
    orderDescending: true,
    orderField: "CFPIva",
  });

  const [dataCorrelate, setDataCorrelate] = useState({
    idCartella: "",
    idTipoEntrata: 0,
  });

  const reload = () => {
    var dataCorrelateTemp = dataCorrelate;
    dataCorrelateTemp.idCartella = cartella.idCartella;
    dataCorrelateTemp.idTipoEntrata = cartella.idTipoEntrata;

    setLoading(false);
    if (apiToken) {
      setErrorMessage(false);
      var dataJson = JSON.stringify(data);

      Repo.getPagamentiAttesiCartelle(apiToken, dataJson)
        .then((response) => {
          setpagamentiDovuti(response.data.items);
          setLoading(true);
        })
        .catch((error) => {
          setErrorMessage(true);
        });

      Repo.getPagamentiEffettuatiCartella(apiToken, dataJson)
        .then((response) => {
          setPagamentiEffettuati(response.data.items);
        })
        .catch((error) => {
          console.log(error);

          setErrorMessage(true);
        });

      Repo.getCartelleCorrelate(apiToken, dataCorrelateTemp)
        .then((response) => {
          setCartelleCorrelate(response.data.items);
        })
        .catch((error) => {
          console.log(error);

          setCartelleCorrelate([]);
          setErrorMessage(true);
        });

      Repo.getCartellaAllegati(apiToken, {
        idCartella: data.idCartella,
        idTipoEntrata: data.idTipoEntrata,
      })
        .then((response) => {
          setAllegati(response.data.items);
        })
        .catch((error) => {
          console.log(error);
          setLoading(true);
          setCartelleCorrelate([]);
          setErrorMessage(true);
        });
    }
  };

  useEffect(() => {
    window.scroll(0, 0);
    setCartella(props.children);

    reload();
  }, [props.children]);

  useEffect(() => {
    reload(), document.getElementById("tab1-tab").click();
  }, [cartella]);

  const getPdf = async () => {
    Repo.getCartellaDownload(
      cartella.tokenDownload,
      cartella.idCartella,
      cartella.idTipoEntrata
    )
      .then(function (response) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "file.pdf");
        document.body.appendChild(link);
        link.click();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <>
      {/* {!cartella.idCartella  && <Redirect to="/home"></Redirect>}  */}

      {errorMessage && <AlertMessage error="true"></AlertMessage>}

      <div className="row">
        <div className="col-12 col-lg-12">
          <div className="card-wrapper card-space">
            <div className="card card-bg">
              <div className="card-body">
                <div className="row">
                  <div className="col-9">
                    {" "}
                    <div className="no_toc d-none d-lg-block d-md-block">
                      <h3 className="card-title">
                        {" "}
                        <u>Cartella:</u> {cartella.nomeUnivocoCartella}
                      </h3>
                    </div>
                    <div className="col-12 d-none d-sm-block d-md-none d-block d-sm-none">
                      <h5 className="card-title">
                        {" "}
                        <u>Cartella:</u> {cartella.idCartella}
                      </h5>
                    </div>
                  </div>
                  <div className="col-3 text-right">
                    {" "}
                    <button
                      type="button"
                      className="btn btn-secondary btn-sm"
                      onClick={() => getPdf()}
                    >
                      <svg className="icon icon-sm icon-white">
                        <use href={`${iconSprite}#it-download`} />
                      </svg>{" "}
                      <span>Scarica Cartella</span>
                    </button>
                  </div>
                </div>

                <div>
                  {/* {pagamentiDovuti.length > 0 && <> */}
                  <Cartella
                    setpagamentoDovuto={props.setpagamentoDovuto}
                    pagamento={
                      pagamentiDovuti.length > 0 ? pagamentiDovuti[0] : {}
                    }
                    isRata={
                      pagamentiDovuti.length > 0 &&
                        pagamentiDovuti[0].rate.length == 0
                        ? false
                        : true
                    }
                  >
                    {cartella}
                  </Cartella>
                  {/* </>} */}
                </div>

                <div className="px-2 mt-5">
                  <hr />

                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        id="tab1-tab"
                        data-toggle="tab"
                        href="#tab1"
                        role="tab"
                        aria-controls="tab1"
                        aria-selected="true"
                      >
                        {pagamentiDovuti.length > 0 &&
                          pagamentiDovuti[0].rate.length == 0
                          ? "Pagamenti"
                          : "Pagamento Unica soluzione"}
                      </a>
                    </li>
                    <li className="nav-item">
                      {pagamentiDovuti.length > 0 &&
                        pagamentiDovuti[0].rate.length > 0 && (
                          <a
                            className="nav-link "
                            id="tab2-tab"
                            data-toggle="tab"
                            href="#tab2"
                            role="tab"
                            aria-controls="tab2"
                            aria-selected="false"
                            aria-disabled="true"
                            tabIndex="-1"
                          >
                            Rate
                          </a>
                        )}
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="tab3-tab"
                        data-toggle="tab"
                        href="#tab3"
                        role="tab"
                        aria-controls="tab3"
                        aria-selected="false"
                      >
                        Pagamenti Effettuati
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link "
                        id="tab4-tab"
                        data-toggle="tab"
                        href="#tab4"
                        role="tab"
                        aria-controls="tab4"
                        aria-selected="false"
                        tabIndex="-1"
                      >
                        {" "}
                        Cartelle Correlate
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link "
                        id="tab5-tab"
                        data-toggle="tab"
                        href="#tab5"
                        role="tab"
                        aria-controls="tab5"
                        aria-selected="false"
                        tabIndex="-1"
                      >
                        {" "}
                        Allegati
                      </a>
                    </li>
                  </ul>

                  <div className="tab-content" id="myTabContent">
                    <div
                      className="tab-pane p-4 fade show active"
                      id="tab1"
                      role="tabpanel"
                      aria-labelledby="tab1-tab"
                    >


                      <>
                        <ListaPagamentiAttesi
                          dettaglioCartella={true}
                          setDettaglio={props.setpagamentoDovuto}
                        >
                          {pagamentiDovuti}
                        </ListaPagamentiAttesi>
                      </>

                    </div>

                    {pagamentiDovuti.length > 0 &&
                      pagamentiDovuti[0].rate.length > 0 && (
                        <>
                          <div
                            className="tab-pane p-4 fade disabled"
                            id="tab2"
                            role="tabpanel"
                            aria-labelledby="tab2-tab"
                          >
                            <ListaRate
                              setRataDettaglio={props.setRataDettaglio}
                            >
                              {pagamentiDovuti[0].rate}
                            </ListaRate>
                          </div>
                        </>
                      )}

                    <div
                      className="tab-pane p-4 fade"
                      id="tab3"
                      role="tabpanel"
                      aria-labelledby="tab3-tab"
                    >
                      {" "}
                      <ListaPagamentiEffettuati>
                        {pagamentiEffettuati}
                      </ListaPagamentiEffettuati>{" "}
                    </div>
                    <div
                      className="tab-pane p-4 fade"
                      id="tab4"
                      role="tabpanel"
                      aria-labelledby="tab3-tab"
                    >
                      {" "}
                      <TabellaFascicolo

                        setDettaglio={setCartella}
                      >
                        {cartelleCorrelate}
                      </TabellaFascicolo>
                    </div>

                    <div
                      className="tab-pane p-4 fade disabled"
                      id="tab5"
                      role="tabpanel"
                      aria-labelledby="tab5-tab"
                    >
                      <Allegati idTipoEntrata={cartella.idTipoEntrata}>
                        {allegati}
                      </Allegati>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {!loading && (
        <div className="col-12">
          <div className="trasparente">
            <div className="progress-spinner progress-spinner-active spinner">
              <span className="sr-only">Caricamento...</span>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default DettaglioCartella;

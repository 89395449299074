import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { Headers, SlimHeaderFullResponsive } from "design-react-kit";

// import smartcitylogo from "../../Img/smartcitylogo.PNG";
import mainLogo from "../../Img/mainlogo.png";
import configuration from "../Utility/config.json";
import iconSprite from "bootstrap-italia/dist/svg/sprite.svg";
import logo from "../../Img/logo.png";
import SwitchComuneModale from "../Objects/SwitchComuneModale";
import Repo from "../Utility/Repo";
import { Link } from "react-router-dom";

function Header(props) {
  const [cookies, setCookie, removeCookie] = useCookies();
  const [apiToken] = useState(cookies.api_token);
  const [isOperator, setIsOperator] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  const auth_url = window.__RUNTIME_CONFIG__.REACT_APP_AUTH_URL;

  const queryParameters =
    "?returnUrl=" + location.protocol + "//" + location.host + props.subfolder;

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  useEffect(() => {
    if (cookies.user_profile) {
      if (cookies.user_profile.WorkingEntityId) {
        setIsOperator(true);
      }
    }
  }, [cookies]);

  const login = () => {
    return (
      <a
        href={auth_url + configuration.AUTH_PATH_LOGIN + queryParameters}
        className="btn btn-primary btn-icon btn-full mx-0"
      >
        <span className="rounded-icon">
          <svg className="icon icon-primary">
            <use href={`${iconSprite}#it-user`} />
          </svg>
        </span>
        <span className="d-lg-block">Accedi ai servizi</span>
      </a>
    );
  };

  const logout = () => {
    return (
      <>
        <div className="it-user-wrapper nav-item dropdown">
          <a
            href="/#"
            className="btn btn-primary btn-icon btn-full"
            data-toggle="dropdown"
            // aria-haspopup="true"
            aria-expanded="false"
          >
            <span className="rounded-icon">
              <svg className="icon icon-primary ml-0">
                <use href={`${iconSprite}#it-user`} />
              </svg>
            </span>
            <span className="d-none d-lg-block">
              {props.LastName} {props.FirstName}
            </span>
          </a>
          <div
            className="dropdown-menu"
            x-placement="bottom-start"
            style={{
              position: "absolute",
              willChange: "transform",
              top: "0px",
              left: "0px",
              transform: "translate3d(0px, 48px, 0px)",
            }}
          >
            <div className="link-list-wrapper">
              <ul className="link-list ">
                <li>
                  <a
                    className="list-item"
                    href={
                      auth_url +
                      configuration.AUTH_PATH_LOGOUT +
                      queryParameters
                    }
                    onClick={() => deleteStorage()}
                  >
                    <svg className="icon icon-primary">
                      <use xlinkHref={`${iconSprite}#it-external-link`}></use>
                    </svg>

                    <span className="ml-1">
                      <b>Esci</b>
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </>
    );
  };

  const deleteStorage = () => {
    removeCookie("ente");
    localStorage.clear();
  };

  const ChangeEnte = (name) => {
    setCookie("ente", name, { path: "/" });
    window.location.href = props.subfolder + "/home";
  };

  // const listaComuni = [
  //   { value: "Oria (BA)", title: "Oria" },
  //   { value: "Bari (BA)", title: "Bari" },
  //   { value: "Altamura (BA)", title: "Altamura" },
  // ];

  return (
    <>
      <header className="it-header-wrapper it-header-sticky">
        <div className="it-header-slim-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="it-header-slim-wrapper-content">
                  <a
                    className=" d-lg-block navbar-brand"
                    href="https://www.servizilocalispa.it/"
                  >
                    Servizi Locali Spa
                  </a>

                  <div className="it-header-slim-right-zone ">
                    {apiToken && (
                      <>
                        {props.linksEsterni && props.linksEsterni.length < 2 ? (
                          <>
                            <div className="nav-item d-none d-lg-block">
                              {props.linksEsterni &&
                                props.linksEsterni.map((linkObb, index) => (
                                  <span className="linkMenu" key={index}>
                                    <a href={linkObb.Url}>{linkObb.Title}</a>
                                  </span>
                                ))}
                            </div>
                          </>
                        ) : (
                          <div className="nav-item dropdown">
                            <a
                              className="nav-link dropdown-toggle"
                              href="/#"
                              data-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <span>Smart City Pa</span>
                              <svg className="icon d-none d-lg-block align-middle">
                                <use href={`${iconSprite}#it-expand`} />
                              </svg>
                            </a>
                            <div className="dropdown-menu">
                              <div className="row">
                                <div className="col-12">
                                  <div className="link-list-wrapper">
                                    <ul className="link-list">
                                      <li>
                                        <a
                                          className="list-item"
                                          href="https://smartcondominio.servizilocalispa.it:3002/"
                                        >
                                          <span>Smart Condominio</span>
                                        </a>{" "}
                                      </li>
                                      {props.linksEsterni &&
                                        props.linksEsterni.map(
                                          (linkObb, index) => (
                                            <li key={index}>
                                              <a
                                                className="list-item"
                                                href={linkObb.Url}
                                              >
                                                <span>{linkObb.Title}</span>
                                              </a>{" "}
                                            </li>
                                          )
                                        )}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    )}

                    <div className="it-access-top-wrapper mr-2">
                      {apiToken ? logout() : login()}
                    </div>

                    {/*props.LastName && (
                      <div style={{ marginLeft: "5px", padding: "2px" }}>
                        <svg className="icon icon-light  ">
                          {" "}
                          <use href={`${iconSprite}#it-horn`} />
                        </svg>
                        <span className="badge badge-warning">2</span>{" "}
                        {/* <span className="badge badge-danger">9</span> */}
                    {/*                                        
                                        <svg className="icon icon-light  ">  <use href={`${iconSprite}#it-folder`} /></svg>
                                        <span className="badge badge-light">9</span>
                                        <span className="sr-only">Messaggi non letti<span className="badge badge-light">0</span></span> 
                      </div>
                    )}*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="it-nav-wrapper">
          <div className="it-header-center-wrapper">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="it-header-center-content-wrapper ">
                    <div className="it-brand-wrapper px-0 mx-0">
                      <div className="row">
                        {width < 992 && (
                          <div className="col-2 pt-3">
                            <a className="">
                              <button
                                className="custom-navbar-toggler"
                                type="button"
                                aria-controls="nav02"
                                aria-expanded="false"
                                aria-label="Toggle navigation"
                                data-target="#nav02"
                              >
                                <svg className="icon">
                                  <use
                                    href={`${iconSprite}#it-burger`}
                                    width="70%"
                                  />
                                </svg>
                              </button>
                            </a>
                          </div>
                        )}
                        <div className="col-10 mt-1">
                          <a className="">
                            <img height="58" src={logo} alt="logo" />
                            <div className="it-brand-text">
                              <h2 className="no_toc ">
                                {" "}
                                <img
                                  className="img-fluid"
                                  src={mainLogo}
                                  alt="mainlogo"
                                  style={{
                                    marginLeft: "5px",
                                    marginTop: "4px",
                                    maxHeight: "65px",
                                  }}
                                />
                              </h2>
                              <h3 className="no_toc d-none d-lg-block ml-3 mt-0">
                                Servizi per il cittadino
                              </h3>
                            </div>
                          </a>
                        </div>{" "}
                      </div>
                    </div>
                    {apiToken && (
                      <>
                        {" "}
                        {width > 991 && (
                          <div className="it-right-zone bg-white px-4 py-2 mr-2 text-black text-decoration-none collapseCustom shadow rounded">
                            <div className="text-left">
                              <div className="row ">
                                <div className="col-sm mt-1">
                                  <div className="row">
                                    Comune:
                                    <h6 className="ml-1">
                                      <u>
                                        {props.AreaName && (
                                          <b> {props.AreaName}</b>
                                        )}{" "}
                                      </u>
                                    </h6>
                                  </div>
                                  <div className="row">
                                    Ente:
                                    <h6 className="ml-1">
                                      <u>
                                        {props.EnteName && (
                                          <b>{props.EnteName}</b>
                                        )}
                                      </u>
                                    </h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="no_toc  text-left ml-4">
                              <SwitchComuneModale
                                subfolder={props.subfolder}
                                classCustom={
                                  "btn btn-primary btn-xs text-white"
                                }
                              >
                                <div className="">Cambia</div>
                              </SwitchComuneModale>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Headers shadow={false} sticky>
            <div className="it-header-navbar-wrapper ">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <nav className="navbar navbar-expand-lg has-megamenu">
                      <div
                        className="navbar-collapsable"
                        id="nav02"
                        style={{ display: "none", width: "80%" }}
                      >
                        <div
                          className="overlay"
                          style={{ display: "none" }}
                        ></div>
                        <div className="close-div sr-only">
                          <button className="btn close-menu" type="button">
                            <span className="it-close"></span>close
                          </button>
                        </div>
                        <div className="menu-wrapper">
                          <ul className="navbar-nav">
                            {isOperator || !apiToken
                              ? props.links &&
                                props.links.map((linkObb, index) => (
                                  <li className="nav-item active" key={index}>
                                    <Link className="nav-link" to={linkObb.url}>
                                      <span>{linkObb.testo}</span>
                                      <span className="sr-only">current</span>
                                    </Link>
                                  </li>
                                ))
                              : props.linksUtente &&
                                props.linksUtente.map((linkObb, index) => (
                                  <li className="nav-item active" key={index}>
                                    <Link className="nav-link" to={linkObb.url}>
                                      <span>{linkObb.testo} </span>
                                      <span className="sr-only">current</span>
                                    </Link>
                                  </li>
                                ))}
                          </ul>
                        </div>
                      </div>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </Headers>
        </div>

        {apiToken && width <= 991 && (
          <div className="it-header-navbar-wrapper pt-1 pb-2 px-3">
            <div
              id="accordionDiv1"
              className="collapse-div bg-white rounded"
              role="tablist"
            >
              <div className="collapse-header" id="headingA1">
                <button
                  className="px-3 py-2 text-black text-decoration-none collapseCustom"
                  data-toggle="collapse"
                  data-target="#accordion1"
                  aria-controls="accordion1"
                >
                  Comune:{" "}
                  <u className="text-primary">
                    {props.AreaName && <b>{props.AreaName}</b>}{" "}
                  </u>
                </button>
              </div>
              <div
                id="accordion1"
                className="collapse"
                role="tabpanel"
                aria-labelledby="headingA1"
                data-parent="#accordionDiv1"
              >
                <div className="collapse-body py-0 px-3">
                  <b>Ente:</b>{" "}
                  <b className="text-primary">
                    <u>{props.EnteName && props.EnteName}</u>{" "}
                  </b>
                </div>
                <div className="row">
                  <div className="col-12 text-right pb-1 pt-0">
                    <SwitchComuneModale
                      classCustom={"btn btn-primary btn-xs mx-4 text-left"}
                    >
                      Cambia
                    </SwitchComuneModale>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </header>
    </>
  );
}

export default Header;

import React, { useEffect, useState, useContext } from "react";
import { useCookies } from "react-cookie";
import AlertMessage from "../Objects/AlertMessage.js";
import Repo from "../Utility/Repo";
//import ListaPagamentiEffettuati from "../Objects/ListaPagamentiEffettuati";
import moment from "moment";
import ListaPagamentiAttesi from "../Objects/ListaPagamentiAttesi.js";
import Select from "../Objects/Select.js";
import { DovutiApiContext } from "../Contexts/DovutiContext";
import { Link } from "react-router-dom";
import useLocalStorage from "../Utility/useLocalStorage.js";
import DettaglioPagamentiAttesi from "./DettaglioPagamentiAttesi.js";
function PagamentiAttesi(props) {
  const [cookies] = useCookies();
  const [apiToken] = useState(cookies.api_token);
  const [loading, setLoading] = useState();
  const [pagamentiDovuti, setpagamentiDovuti] = useState();
  const [pagamentoDovuto, setpagamentoDovuto] = useState();
  const [rata, setRata] = useState();

  const [errorMessage, setErrorMessage] = useState(false);
  const { responseDovuti, setResponse } = useContext(DovutiApiContext);

  const [data, setData] = useState({
    cfpIva: cookies.user_profile.FiscalCode,
    tipoCartella: "string",
    idCartella: "string",
    idTipoEntrata: 0,
    nomeUnivocoCartella: "string",
    dal: "2021-05-20T11:50:04.262Z",
    al: "2021-05-20T11:50:04.262Z",
  });

  useEffect(() => {
    var dataJson = "";

    if (props.codiceFiscale) {
      var dataObb = data;
      dataObb.cfpIva = props.codiceFiscale;
      var dataJson = JSON.stringify(dataObb);
      setData(dataObb);

      if (responseDovuti) {
        setpagamentiDovuti(responseDovuti);
        setLoading(true);
      } else reload(dataJson);
    }
  }, [props.codiceFiscale]);

  const handleChange = (event, nameOfComponent) => {
    let name = nameOfComponent.name;
    let value = event.value;

    switch (name) {
      case "valueData":
        var startdate = moment();

        switch (value) {
          case "1m":
            startdate = moment()
              .subtract(1, "months")
              .format("YYYY-MM-DDTHH:mm:ss");
            break;

          case "3m":
            startdate = moment()
              .subtract(3, "months")
              .format("YYYY-MM-DDTHH:mm:ss");
            break;

          case "1y":
            startdate = moment()
              .subtract(1, "years")
              .format("YYYY-MM-DDTHH:mm:ss");
            break;

          default:
            startdate = null;
            break;
        }

        setData((prevState) => ({
          ...prevState,
          dal: startdate,
        }));

        break;

      default:
        break;
    }
  };

  const handleSubmit = (event) => {
    localStorage.setItem("dataForm", JSON.stringify(data));

    event.preventDefault();
    setLoading(false);
    if (apiToken) {
      var dataJson = data;
      dataJson = JSON.stringify(dataJson);
      reload(dataJson);
    }
  };

  const reload = (dataJson) => {
    setLoading(false);
    localStorage.setItem("dataForm", dataJson);
    Repo.getPagamentiAttesiContribuente(apiToken, dataJson)
      .then((response) => {
        setResponse(response.data.items);
        setpagamentiDovuti(response.data.items ? response.data.items : []);
        setLoading(true);
      })
      .catch((error) => {
        console.log(error);
        setLoading(true);
        setErrorMessage(true);
      });
  };

  const selectItem = () => {
    if (rata) {
      return rata;
    } else if (pagamentoDovuto) {
      return pagamentoDovuto;
    } else return false;
  };

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    if (rata) {
      setRata();
    } else if (pagamentoDovuto) {
      setpagamentoDovuto();
    } else {
      return window.location.replace(props.subfolder + "/");
    }
    window.history.pushState({}, "");
  };

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", onBackButtonEvent);
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  }, [pagamentoDovuto, rata]);

  return (
    <>
      <div className="clearfix">
        <div className="float-left">
          <nav className="breadcrumb-container pl-2" aria-label="breadcrumb">
            <ol className="breadcrumb">
              {rata ? (
                <>
                  <Link
                    style={{ color: "inherit", textDecoration: "inherit" }}
                    to={"/"}
                  >
                    Home
                  </Link>{" "}
                  &nbsp; <b>/</b>
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setpagamentoDovuto();
                      setRata();
                    }}
                  >
                    &nbsp; Pagamenti Dovuti &nbsp;
                  </a>{" "}
                  <b>/</b>
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setRata();
                    }}
                  >
                    &nbsp; Pagamento &nbsp;
                  </a>{" "}
                  <b>/</b>
                  <a className={"breadcrumb-item active"}>
                    &nbsp; <b>Rate</b> &nbsp;
                  </a>
                </>
              ) : pagamentoDovuto ? (
                <>
                  <Link
                    style={{ color: "inherit", textDecoration: "inherit" }}
                    to={"/"}
                  >
                    Home
                  </Link>{" "}
                  &nbsp; <b>/</b>
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setpagamentoDovuto();
                      setRata();
                    }}
                  >
                    &nbsp; Pagamenti Dovuti &nbsp;
                  </a>{" "}
                  <b>/</b>
                  <a className={"breadcrumb-item active"}>
                    &nbsp;<b> Pagamento</b> &nbsp;
                  </a>
                </>
              ) : (
                <>
                  <Link
                    style={{ color: "inherit", textDecoration: "inherit" }}
                    to={"/"}
                  >
                    Home
                  </Link>{" "}
                  &nbsp; <b>/</b>
                  <a className={"breadcrumb-item active"}>
                    &nbsp; <b>Pagamenti Dovuti</b> &nbsp;
                  </a>
                </>
              )}
            </ol>
          </nav>
        </div>
      </div>

      {errorMessage && <AlertMessage error={true}></AlertMessage>}

      {!(pagamentoDovuto || rata) ? (
        <div className="col-12">
          <div className="card card-teaser rounded shadow mb-2">
            <div className="card-body">
              <h3>Pagamenti Dovuti</h3>
              <div className="mt-4">
                <form onSubmit={handleSubmit}>
                  <div>
                    <div className="form-row">
                      <div className="form-group col-lg-10 col-sm-8">
                        <div className="bootstrap-select-wrapper">
                          <label>Periodo</label>

                          <Select
                            aria-label="Scegli una opzione"
                            clearText="Annulla"
                            defaultInputValue=""
                            defaultMenuIsOpen={false}
                            defaultValue={null}
                            icon={false}
                            name="valueData"
                            onChange={handleChange}
                            options={[
                              {
                                value: "1m",
                                label: "Ultimo mese",
                              },
                              {
                                value: "3m",
                                label: "Ultimi tre mesi",
                              },
                              {
                                value: "1y",
                                label: "Ultimo anno",
                              },
                            ]}
                            placeholder="Scegli una opzione"
                          />
                        </div>
                      </div>
                      <div className="form-group col-lg-2 col-sm-2 text-right">
                        <button type="submit" className="btn btn-primary">
                          Cerca{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <br />
          <br />

          <div className="col-12">
            {loading ? (
              <>
                <div className="col-12">
                  <div className="col-12">
                    {loading ? (
                      <ListaPagamentiAttesi setDettaglio={setpagamentoDovuto}>
                        {pagamentiDovuti}
                      </ListaPagamentiAttesi>
                    ) : (
                      <div className="col-12">
                        <div className="trasparente">
                          <div className="progress-spinner progress-spinner-active spinner">
                            <span className="sr-only">Caricamento...</span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <div className="col-12">
                <div className="trasparente">
                  <div className="progress-spinner progress-spinner-active spinner">
                    <span className="sr-only">Caricamento...</span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <>
          <DettaglioPagamentiAttesi
            setRataDettaglio={setRata}
            user={props.user}
          >
            {selectItem()}
          </DettaglioPagamentiAttesi>
        </>
      )}
    </>
  );
}

export default PagamentiAttesi;

import {
  Tooltip,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "design-react-kit";
import { useEffect, useState, useContext, useLayoutEffect } from "react";

import { Link } from "react-router-dom";
import Notification from "./Notification";
import iconSprite from "bootstrap-italia/dist/svg/sprite.svg";

import PagoPAButton from "../Objects/PagoPAButton";

function ListaPosizioniDebitorie(props) {
  const [listaPosizioni, setListaPosizioni] = useState();
  const [contribuenteSelezionato, setContribuenteSelezionato] = useState([]);
  const [dataRequest, setDataRequest] = useState([]);
  const [queryCount, setQueryCount] = useState(0);

  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState();

  const ids = ["Example1", "Example2", "Example3"];
  const [openOne, toggleOne] = useState(false);
  const [openTwo, toggleTwo] = useState(false);
  const [openThree, toggleThree] = useState(false);
  const [isOpenModalUsers, setIsOpenModalUsers] = useState(false);

  const [valueSelect, setValueSelect] = useState({});

  useEffect(() => {
    setListaPosizioni(props.posizioniDebitorie);
    setQueryCount(props.posizioniDebitorie.length);
    props.posizioniDebitorie.map((x) => {
      const div = document.createElement("a");
      const div2 = document.createElement("a");
      const div3 = document.createElement("a");
      div.setAttribute("id", "edit" + x.idPagamento);
      div2.setAttribute("id", "delete" + x.idPagamento);
      div3.setAttribute("id", "pay" + x.idPagamento);
      document.body.appendChild(div);
      document.body.appendChild(div2);
      document.body.appendChild(div3);
    });
  }, [props.posizioniDebitorie]);

  return (
    <>
      {errorMessage !== undefined && (
        <Notification
          fix="top-fix"
          header="Salvataggio avvenuto con successo"
          state="success"
          withIcon
          dismissable="true"
        />
      )}
      {listaPosizioni && (
        <div className="row">
          <div className="col-12">
            {listaPosizioni && listaPosizioni.length > 0 ? (
              listaPosizioni.map((x, index) => (
                <div key={index}>
                  <div className="card card-teaser rounded shadow mb-2 col-12">
                    <div className="card-body">
                      <div className="row align-items-center ">
                        <div className="col-sm-6 col-lg-2 d-flex align-items-center">
                          <h5 className="card-title">
                            {x.cfPiva ? x.cfPiva : "Non Presente"}
                          </h5>
                        </div>
                        <div className="col-sm-6 col-lg-2 d-flex align-items-center text-center">
                          {x.nomePagatore ? (
                            <>
                              <b>
                                {x.nomePagatore} {x.cognomePagatore}
                              </b>
                            </>
                          ) : (
                            <>
                            <b>
                              {x.ragioneSociale}
                            </b>
                            </>
                          )}
                        </div>
                        <div className="col-sm-6 col-lg-5 d-flex align-items-center">
                          {x.causaleVersamento && <>{x.causaleVersamento}</>}
                        </div>
                        <div className="bd-example tooltip-demo col-sm-12 col-lg-3 text-center align-items-center">
                          <div className="row text-center px-3">
                            <div className=" mb-2" style={{ width: "220px" }}>
                              <Link
                                id={"edit" + x.id}
                                to={
                                  "/posizioni_debitorie/dettaglio_posizione?id=" +
                                  x.id
                                }
                                className=""
                              >
                                <svg className="icon icon-primary">
                                  <use href={`${iconSprite}#it-pencil`} />
                                </svg>
                              </Link>

                              <a
                                id={"delete" + x.id}
                                className="mx-4"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setValueSelect(x),
                                    setIsOpenModalUsers(!isOpenModalUsers);
                                }}
                              >
                                <svg className="icon icon-primary">
                                  <use href={`${iconSprite}#it-delete`} />
                                </svg>
                              </a>
                              <a
                                id={"pay" + x.id}
                                className=""
                                style={{ cursor: "pointer" }}
                              >
                                <svg className="icon icon-primary">
                                  <use href={`${iconSprite}#it-check-circle`} />
                                </svg>
                              </a>
                              <UncontrolledTooltip
                                placement="top"
                                target={"edit" + x.id}
                              >
                                Modifica Posizione
                              </UncontrolledTooltip>
                              <UncontrolledTooltip
                                placement="top"
                                target={"delete" + x.id}
                              >
                                Elimina Posizione
                              </UncontrolledTooltip>
                              <UncontrolledTooltip
                                placement="top"
                                target={"pay" + x.id}
                              >
                                Registra pagamento effettuato con canala diverso
                                da PagoPA
                              </UncontrolledTooltip>
                            </div>
                            <div>
                              <PagoPAButton></PagoPAButton>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div>
                <b>Nessuna Posizione Debitoria</b>
              </div>
            )}

            <div className="text-right">
              <b>Totale: </b>
              {queryCount}
            </div>
            <br />

            {/* <div className="row col-12">
              {resetPag && (
                <ReactPaginate
                  previousLabel={
                    <svg className="icon icon-primary">
                      <use xlinkHref={`${iconSprite}#it-chevron-left`}></use>
                    </svg>
                  }
                  nextLabel={
                    <svg className="icon icon-primary">
                      <use xlinkHref={`${iconSprite}#it-chevron-right`}></use>
                    </svg>
                  }
                  breakLabel={"..."}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={4}
                  onPageChange={handlePageClick}
                  containerClassName={
                    "pagination justify-content-center col-12"
                  }
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                  initialPage={0}
                />
              )}
            </div> */}
          </div>
        </div>
      )}

      {!loading && (
        <div className="col-12">
          <div className="trasparente">
            <div className="progress-spinner progress-spinner-active spinner">
              <span className="sr-only">Caricamento...</span>
            </div>
          </div>
        </div>
      )}

      <div>
        <Modal
          isOpen={isOpenModalUsers}
          toggle={() => setIsOpenModalUsers(!isOpenModalUsers)}
          size={"lg"}
        >
          <ModalHeader
            toggle={() => setIsOpenModalUsers(!isOpenModalUsers)}
            id="modal"
          >
            Eliminazione posizione debitoria: {valueSelect.id}
          </ModalHeader>

          <ModalBody>
            <p>
              {props.customMessage
                ? props.customMessage
                : "Stai eliminando la posizione debitoria, sei sicuro di voler procedere ?"}{" "}
            </p>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={() => {
                props.setDeleteMethod(valueSelect),
                  setIsOpenModalUsers(!isOpenModalUsers);
              }}
            >
              Si
            </Button>
            <Button
              color="secondary"
              onClick={() => setIsOpenModalUsers(!isOpenModalUsers)}
            >
              No
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
}

export default ListaPosizioniDebitorie;

import iconSprite from "bootstrap-italia/dist/svg/sprite.svg";
import useLocalStorage from "../Utility/useLocalStorage";
// import NotifyPanel from "../Objects/NotifyPanel.js";
import Select from "../Objects/Select.js";
import { useState } from "react";

function CaricamentoMassivo({}) {
  const [value, setValue, removeValue] = useLocalStorage("contribuente");

  const [fileName, setFileName] = useState([]);

  const [isUploaded, setisUploaded] = useState(false);

  function handleChange(event) {
    console.log(`Selected file - ${event.target.files[0].name}`);
    setFileName(event.target.files[0].name);
    setisUploaded(true);
  }

  return (
    <>
      <div className="col-12">
        <div className="card card-teaser rounded shadow mb-2">
          <div className="card-body">
            <h3>Importazione Posizioni Debitorie</h3>

            <h6>
              Seleziona il file Excel da importare (estensione
              .xls)
            </h6>
            <br />
            <br />
            <div>
              <form onSubmit="">
                <div>
                  <div className="form-row mb-4">
                    <div className="form-group col-12">
                      <div className="bootstrap-select-wrapper">
                        <label>Tipologia</label>

                        <Select
                          aria-label="Scegli una opzione"
                          clearText="Annulla"
                          defaultInputValue=""
                          defaultMenuIsOpen={false}
                          defaultValue={null}
                          icon={false}
                          name="valueDataEntry"
                          onChange=""
                          options={[
                            {
                              value: "tipo1",
                              label: "EMISSIONE CARTA IDENTITÁ ELETTRONICA",
                            },
                            {
                              value: "tipo2",
                              label: "DIRITTI VARI",
                            },
                            {
                              value: "tipo3",
                              label: "DIRITTI DI CONCORSO",
                            },
                          ]}
                          placeholder="Scegli una opzione"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-row">
                    <input
                      type="file"
                      name="upload1"
                      id="upload1"
                      className="upload"
                      multiple="multiple"
                      onChange={handleChange}
                    />
                    <label
                      htmlFor="upload1"
                      style={{ height: "48px", paddingTop: "10px" }}
                    >
                      <svg className="icon icon-sm" aria-hidden="true">
                        <use href={`${iconSprite}#it-upload`} />
                      </svg>
                      <span>Upload</span>
                    </label>

                    <ul className="upload-file-list">
                      {fileName && isUploaded ? (
                        <li className="upload-file success">
                          <svg className="icon icon-sm" aria-hidden="true">
                            <use href={`${iconSprite}#it-file`}></use>
                          </svg>
                          <p>
                            <span className="sr-only">File caricato:</span>
                            <strong> {fileName}</strong>
                          </p>
                          <button disabled>
                            <span className="sr-only">
                              Caricamento ultimato
                            </span>
                            <svg className="icon" aria-hidden="true">
                              <use href={`${iconSprite}#it-check`}></use>
                            </svg>
                          </button>
                        </li>
                      ) : (
                        <li className="upload-file error mt-1">
                          <svg className="icon icon-sm" aria-hidden="true">
                            <use href={`${iconSprite}#it-file`}></use>
                          </svg>
                          <p>
                            <span className="sr-only">
                              Errore caricamento file:
                            </span>
                            <strong> Nessun file selezionato</strong>{" "}
                            <span className="upload-file-weight"></span>
                          </p>
                        </li>
                      )}
                    </ul>

                    <div className="form-group col text-center">
                      <button
                        type="submit"
                        className="btn btn-primary float-right"
                      >
                        Importa File{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CaricamentoMassivo;

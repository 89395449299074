function AlertMessage(props) {
  return (
    <>
      {props.messaggio ? (
        <>
          <div className="alert alert-danger alert-dismissible fade show">
            <strong>Attenzione</strong> {props.messaggio}
          </div>
        </>
      ) : (
        <div
          {...(props.error
            ? { className: "alert alert-danger alert-dismissible fade show" }
            : { className: "alert alert-success alert-dismissible fade show" })}
        >
          {props.error ? (
            <>
              <strong>Attenzione</strong> Alcuni campi inseriti sono da
              controllare.
            </>
          ) : (
            <strong>Operazione andata a buon fine</strong>
          )}
        </div>
      )}
    </>
  );
}

export default AlertMessage;

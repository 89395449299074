import iconCheckOk from "bootstrap-italia/dist/svg/sprite.svg";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Verified(props) {
  let query = useQuery();
  const [isVerified, setIsVerified] = useState(
    query.get("esito") && query.get("esito") == "ERROR" ? false : true
  );

  return (
    <>
      <div className="main-body mt-2 mb-0 text-center">
        {isVerified ? (
          <>
            <svg className="icon-success icon-xl" style={{ paddingTop: "10%" }}>
              <use href={`${iconCheckOk}#it-check-circle`} />
            </svg>
            <br />
            <br />
            <h3>Il pagamento è stato effettuato con successo</h3>
          </>
        ) : (
          <>
            <svg className="icon-danger icon-xl" style={{ paddingTop: "10%" }}>
              <use href={`${iconCheckOk}#it-close-circle`} />
            </svg>
            <br />
            <br />
            <h3>Il pagamento non è andato a buon fine</h3>
          </>
        )}
        <br />
        <br />
        <Link
          to={{
            pathname: "/home",
          }}
        >
          <button className="btn btn-primary mr-1 ">Torna alla home</button>
        </Link>
      </div>
    </>
  );
}

export default Verified;

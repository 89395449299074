import React from "react";
import logopagopa from "../../Img/pagopa-logo.png";

function PagoPAButton(props) {
  return (
    <>
      <button
        type="button"
        className="btn"
        onClick={props.handleSubmit}
        style={{
          width: "220px",
          height: "48px",
          backgroundColor: "#0073E6",
          color: "white",
        }}
      >
        <img
          className="img-fluid mx-auto"
          src={logopagopa}
          alt="logopagopa"
          style={{
            maxWidth: "26%",
          }}
        />
        Paga online
      </button>
    </>
  );
}

export default PagoPAButton;

import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import moment from "moment";
import Repo from "../Utility/Repo";
import { Link } from "react-router-dom";

function ListaRate(props) {
  const [cookies] = useCookies();
  const [apiToken] = useState(cookies.api_token);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="table-responsive">
        <table className="table table-hover">
          <thead>
            <tr>
              <th scope="col">N.Rata</th>
              <th scope="col">Dovuto</th>
              <th scope="col">Interessi</th>
              <th scope="col">Scadenza</th>
              <th scope="col">Paga</th>
            </tr>
          </thead>
          <tbody>
            {props.children && props.children.length !== 0 ? (
              <>
                {Array.isArray(props.children) &&
                  props.children.map((obbj, index) => (
                    <tr key={index}>
                      <td scope="row">
                        <b></b>
                        {obbj.numeroRata}
                      </td>
                      <td>{obbj.importoDovuto} €</td>
                      <td>{obbj.importoInteressi} €</td>
                      <td>
                        {obbj.dataScadenza
                          ? moment(new Date(obbj.dataScadenza)).format(
                              "DD-MM-YYYY"
                            )
                          : "/"}
                      </td>
                      <td>
                        <div className="row">
                          <div className="">
                            <button
                              onClick={() => props.setRataDettaglio(obbj)}
                              type="button"
                              className="btn btn-primary "
                            >
                              {" "}
                              Paga Ora{" "}
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
              </>
            ) : (
              <tr>
                <td colSpan="5" className="text-center">
                  <b>Nessun Dato da Mostrare</b>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default ListaRate;

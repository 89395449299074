import React, { Component, useEffect, useState } from "react";
// import Select from 'react-select'
import Select from "../Objects/Select.js";
import { useCookies } from "react-cookie";
import iconSprite from "bootstrap-italia/dist/svg/sprite.svg";
import Repo from "../Utility/Repo";
import { id } from "inversify";

function SwitchComuneModale(props) {
  const [cookies, setCookie, removeCookie] = useCookies();
  const [userSelect, setUserSelect] = useState({
    idComune: "",
    nomeComune: "",
    idEnte: "",
    nomeEnte: "",
  });

  const [listaComuni, setListaComuni] = useState({
    value: cookies.ente ? cookies.ente.idComune : "",
    label: cookies.ente ? cookies.ente.nomeComune : "",
  });

  const [items, setItems] = useState({
    value: cookies.ente ? cookies.ente.idEnte : "",
    label: cookies.ente ? cookies.ente.nomeEnte : "",
  });

  const [getComuni, setGetListaComuni] = useState([]);

  const getUniqueBy = (arr, prop) => {
    const set = new Set();
    return arr.filter((o) => !set.has(o[prop]) && set.add(o[prop]));
  };

  const LoadEntities = (event) => {
    Repo.getEntities()
      .then(function (response) {
        const uniqueTownCode = getUniqueBy(response.data, "TownCode");
        var tempList = [];
        uniqueTownCode.map((item) => {
          var items = response.data.filter((x) => x.TownCode === item.TownCode);

          var tempItems = [];
          items.map((item2) => {
            tempItems.push({
              value: item2.Id,
              label:
                item2.EntityTypeName == "Municipal"
                  ? "Ufficio Comunale"
                  : item2.EntityTypeName,
            });
          });

          if (cookies.ente.idComune === item.TownCode) {
            setItems(tempItems);
          }

          tempList.push({
            value: item.TownCode,
            label: item.TownName,
            items: tempItems,
          });
        });
        setListaComuni(tempList);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //   const listaaaaComuni = [
  //     {
  //       value: "1",
  //       label: "Taranto",
  //       items: [
  //         { value: "1", label: "Ufficio Comunale" },
  //         { value: "2", label: "Capitaneria Di Porto" },
  //         { value: "2", label: "Servizi Sociali" },
  //       ],
  //     },
  //     {
  //       value: "2",
  //       label: "Bagnara di Romagna",
  //       items: [
  //         { value: "1", label: "Ufficio Comunale" },
  //         { value: "2", label: "Servizi Sociali" },
  //       ],
  //     },
  //     {
  //       value: "3",
  //       label: "Napoli",
  //       items: [{ value: "1", label: "Ufficio Comunale" }],
  //     },
  //   ];

  const changeComune = (event) => {
    var id = event.value;
    setUserSelect({
      idComune: event.value,
      nomeComune: event.label,
      idEnte: event.items[0].value,
      nomeEnte: event.items[0].label,
    });

    setItems(event.items);
  };

  const changeEnte = (event) => {
    var id = event.value;
    setUserSelect((prevState) => ({
      ...prevState,
      idEnte: id,
      nomeEnte: event.label,
    }));
  };

  const setCookieEnte = () => {
    setCookie("ente", userSelect, { path: "/" });
    window.location.href = props.subfolder + "/";
  };

  return (
    <>
      <button
        id="dropdownEntity"
        type="button"
        {...(props.classCustom && { className: props.classCustom })}
        data-toggle="modal"
        data-target="#exampleModal"
        onClick={() => LoadEntities()}
      >
        {props.children}
      </button>

      <div
        className="modal fade text-dark text-left"
        tabIndex="-1"
        role="dialog"
        id="exampleModal"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="row">
                <h6>Seleziona Comune: </h6>

                <div className="bootstrap-select-wrapper col-12">
                  {/* <label></label> */}

                  <Select
                    aria-label="Scegli una opzione"
                    clearText="Annulla"
                    defaultMenuIsOpen={false}
                    defaultValue={listaComuni}
                    icon={false}
                    id="selectExampleClassic"
                    onChange={changeComune}
                    options={listaComuni}
                    isSearchable
                    placeholder="Scegli una opzione"
                  />
                </div>
              </div>
              <br></br>

              {items.length > 1 && (
                <div className="row text-dark">
                  <h6>Seleziona Ente: </h6>
                  <div className="bootstrap-select-wrapper col-12">
                    <Select
                      aria-label="Scegli una opzione"
                      clearText="Annulla"
                      defaultValue={items}
                      defaultMenuIsOpen={false}
                      icon={false}
                      id="selectExampleClassic"
                      onChange={changeEnte}
                      options={items}
                      isSearchable
                      placeholder="Scegli una opzione"
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="modal-footer">
              <button
                onClick={setCookieEnte}
                className="btn btn-primary btn-sm"
                data-dismiss="modal"
                type="button"
              >
                Salva
              </button>

              <button
                className="btn btn-danger btn-sm"
                data-dismiss="modal"
                type="button"
              >
                Chiudi
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SwitchComuneModale;

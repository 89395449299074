import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import ServiziCittadino from './ServiziCittadino';
import ServiziOperatore from './ServiziOperatore';

function Servizi(props) {


    const [cookies] = useCookies();
    const [isOperator, setIsOperator] = useState(false);

    useEffect(() => {
        window.localStorage.removeItem('dataForm');

        
        if (cookies.user_profile) {
            if (cookies.user_profile.WorkingEntityId != null) {
                setIsOperator(true);
            }
        }
    }, [cookies]);


 
    return (


        <div className="row text-center">
            {isOperator ? <ServiziOperatore></ServiziOperatore> : <ServiziCittadino></ServiziCittadino>}
        </div>


    );
}

export default Servizi;

import React from "react";
import iconSprite from "bootstrap-italia/dist/svg/sprite.svg";

function Pagination(props) {



    return (
        <div>
            <div className="text-right">
                <b>Totale: </b>{props.TotalCount}
            </div>

            <nav className="pagination-wrapper justify-content-center" aria-label="Navigazione centrata">
                <ul className="pagination">

                    {props.PageIndex !== 1 &&
                        <li className="page-item disabled" onClick={() => props.methodChange(props.PageIndex - 1)} style={{cursor: "pointer"}}>
                            <a className="page-link"   href="/#" tabIndex="-1" aria-hidden="true">
                                <svg className="icon icon-primary" ><use xlinkHref={`${iconSprite}#it-chevron-left`}></use></svg>
                                <span className="sr-only">Pagina precedente</span>
                            </a>
                        </li>
                    }

                    {[...Array(props.PageCount)].map((x, i) =>


                        <li className="page-item" onClick={() => props.methodChange(i + 1)} key={i} style={{cursor: "pointer"}} >
                            <a className="page-link"  {...(i + 1 === props.PageIndex && { "aria-current": "page" })}>
                                {i + 1}
                            </a>
                        </li>


                    )}

                    {props.PageCount !== props.PageIndex &&
                        <li className="page-item disabled" onClick={() => props.methodChange(props.PageIndex + 1)} style={{cursor: "pointer"}}>
                            <a  className="page-link" href="/#" tabIndex="-1" aria-hidden="true">
                                <svg className="icon icon-primary"><use xlinkHref={`${iconSprite}#it-chevron-right`}></use></svg>
                                <span className="sr-only">Pagina precedente</span>
                            </a>
                        </li>
                    }

                </ul>


            </nav>
        </div>

    )

}

export default Pagination;

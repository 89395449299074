import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import Context from "../Contexts/FascicoloContext";
import DovutiContext from "../Contexts/DovutiContext";
import ContribuentiContext from "../Contexts/ContribuentiContext";
import { Switch, Route } from "react-router-dom";
import Home from "../Page/Home.js";
import Faq from "../Page/Faq.js";
import Help from "../Page/Help.js";
import Servizi from "../Page/Servizi.js";
import Rettifica from "../Page/Rettifica.js";
import ValidazioneOTP from "../Page/ValidazioneOTP.js";
import RichiestaRettifica from "../Page/RichiestaRettifica.js";
import PagamentiEffettuati from "../Page/PagamentiEffettuati.js";
import PagamentiAttesi from "../Page/PagamentiAttesi.js";
import PaymentCheck from "../Page/PaymentCheck.js";

import Fascicolo from "../Page/Fascicolo.js";
import DettaglioCartella from "../Page/DettaglioCartella.js";
import DettaglioPagamentiAttesi from "../Page/DettaglioPagamentiAttesi.js";
import Breadcrumb from "./Breadcrumb.js";
import PanelloOperatore from "../Objects/PanelloOperatore.js";
import NuovoContribuente from "../Page/NuovoContribuente.js";
import { useLocation } from "react-router-dom";
import PagamentoSpontaneo from "../Page/PagamentoSpontaneo.js";
import iconSprite from "bootstrap-italia/dist/svg/sprite.svg";
import FlexWebchat from "../Twilio-WebChat/FlexWebchat";

import moment from "moment";
import ChatBot from "../Objects/ChatBot.js";
import useLocalStorage from "../Utility/useLocalStorage";
import PrivacyPolicy from "../Page/PrivacyPolicy";
import HomeOperator from "../Objects/HomeOperator";
import CaricamentoMassivo from "../Page/CaricamentoMassivo";
import PosizioniDebitorie from "../Page/PosizioniDebitorie";
import DettaglioPosizione from "../Page/DettaglioPosizione";
import NuovaPosizione from "../Page/NuovaPosizione";

function Body(props) {
  const [cookies] = useCookies();
  const [apiToken] = useState(cookies.api_token);
  const [isOperator, setIsOperator] = useState(false);
  const [contribuenteSelezionato, setContribuenteSelezionato] = useState([]);
  const [codiceFiscale, setCodiceFiscale] = useState("");
  const location = useLocation();
  const [response, setResponse, removeResponde] =
    useLocalStorage("responseDovuti");

  const [value, setValue, removeValue] = useLocalStorage("contribuente");

  const [intent, setIntent] = useState();
  useEffect(() => {
    if (cookies.user_profile) {
      if (cookies.user_profile.WorkingEntityId) {
        setIsOperator(true);

        var obbjContribuente = JSON.parse(localStorage.getItem("contribuente"));

        if (obbjContribuente) {
          let dataAdd = moment(obbjContribuente.dataCreazione).add(10, "h");
          let dataTimeNow = moment().format();
          let diffTime = moment(dataAdd).diff(dataTimeNow, "hours");

          if (diffTime <= 0) {
            localStorage.clear();
            window.location.href = props.subfolder + "/home";
          } else {
            setContribuenteSelezionato(obbjContribuente);
            setCodiceFiscale(obbjContribuente.cfPiva);
          }
        }
      } else {
        setCodiceFiscale(cookies.user_profile.FiscalCode);
      }
    }
    if (codiceFiscale) {
      setCodiceFiscale(cookies.user_profile.FiscalCode);
    }
  }, [cookies]);

  return (
    <main role="main" className="main-body">
      {apiToken ? (
        <div className="container mb-4 mt-3">
          {!isOperator && <FlexWebchat configuration={window.appConfig} />}

          {/* <div className="clearfix">
            <div className="float-left">
              {" "}
              <Breadcrumb></Breadcrumb>
            </div>

                        {location.pathname.toLowerCase() !== "/" &&
              location.pathname.toLowerCase() !== "/home" && (
                <div className="float-right">
                  <button
                    type="button"
                    className="btn btn-primary go-back"
                    onClick={() => window.history.back()}
                  >
                    <svg className="icon icon-sm icon-white mr-2 ">
                      <use href={`${iconSprite}#it-arrow-left`} />
                    </svg>{" "}
                    Torna indietro
                  </button>
                </div>
              )}
          </div> */}

          {value &&
            isOperator &&
            location.pathname !== "/contribuente/nuovo" &&
            location.pathname !== "/help" &&
            location.pathname !== "/privacy_policy" &&
            location.pathname !== "/faq" && (
              <PanelloOperatore subfolder={props.subfolder}></PanelloOperatore>
            )}

          <div>
            <Switch>
              <Route exact path="/help">
                <Help token={apiToken} isOperator={isOperator}></Help>
              </Route>

              <Route exact path="/faq">
                <Faq></Faq>
              </Route>

              <Route exact path="/ricerca">
                <PanelloOperatore
                  subfolder={props.subfolder}
                ></PanelloOperatore>
              </Route>

              <Route exact path="/caricamento_massimo">
                <CaricamentoMassivo></CaricamentoMassivo>
              </Route>

              <Route exact path="/posizioni_debitorie">
                <Breadcrumb></Breadcrumb>
                <PosizioniDebitorie></PosizioniDebitorie>
              </Route>

              <Route exact path="/posizioni_debitorie/nuovo">
                <Breadcrumb></Breadcrumb>
                <NuovaPosizione></NuovaPosizione>
              </Route>

              <Route exact path="/posizioni_debitorie/dettaglio_posizione">
                <Breadcrumb></Breadcrumb>
                <DettaglioPosizione></DettaglioPosizione>
              </Route>

              <Route exact path="/pagamento_spontaneo">
                <PagamentoSpontaneo></PagamentoSpontaneo>
              </Route>

              {!isOperator && (
                <Route exact path="/validazione_otp">
                  <ValidazioneOTP></ValidazioneOTP>
                </Route>
              )}
              <Route exact path="/privacy_policy">
                <PrivacyPolicy></PrivacyPolicy>
              </Route>
              <Route path="/fascicolo/dettaglio_cartella">
                <DettaglioCartella addItem={props.addItem}></DettaglioCartella>
              </Route>

              {/* <Route path={"/fascicolo/:id"} render={(props) => <DettaglioCartella {...props} carrello2={this.props.carrello} />} /> */}

              <Route exact path="/fascicolo">
                <Context>
                  <Fascicolo
                    user={props.user}
                    isOperator={isOperator}
                    codiceFiscale={codiceFiscale}
                    subfolder={props.subfolder}
                  ></Fascicolo>
                </Context>
              </Route>

              <Route exact path="/pagamenti_effettuati">
                <PagamentiEffettuati
                  isOperator={isOperator}
                  codiceFiscale={codiceFiscale}
                ></PagamentiEffettuati>
              </Route>

              <Route exact path="/pagamenti_dovuti/dettaglio_pagamento">
                <DettaglioPagamentiAttesi
                  addItem={props.addItem}
                  subfolder={props.subfolder}
                ></DettaglioPagamentiAttesi>
              </Route>

              <Route exact path="/pagamenti_dovuti">
                {removeResponde()}
                <DovutiContext>
                  <PagamentiAttesi
                    user={props.user}
                    addItem={props.addItem}
                    codiceFiscale={codiceFiscale}
                    isOperator={isOperator}
                    subfolder={props.subfolder}
                  ></PagamentiAttesi>
                </DovutiContext>
              </Route>

              <Route exact path="/rettifica">
                <Rettifica></Rettifica>
              </Route>

              <Route exact path="/payment_check">
                <PaymentCheck></PaymentCheck>
              </Route>

              <Route exact path="/richiesta_rettifica">
                <RichiestaRettifica></RichiestaRettifica>
              </Route>

              {isOperator && (
                <Route exact path="/contribuente/nuovo">
                  <NuovoContribuente
                    subfolder={props.subfolder}
                  ></NuovoContribuente>
                </Route>
              )}
              {isOperator && isOperator ? (
                <Route path="/">
                  <HomeOperator
                    userSelect={value ? true : false}
                  ></HomeOperator>
                </Route>
              ) : (
                <Route path="/">
                  <Servizi></Servizi>
                </Route>
              )}
            </Switch>
          </div>
        </div>
      ) : (
        <div>
          <Switch>
            <Route exact path="/help">
              <div className="container my-4">
                <Breadcrumb></Breadcrumb>

                <Help token={apiToken} isOperator={isOperator}></Help>
              </div>
            </Route>
            <Route exact path="/faq">
              <div className="container my-4">
                <Breadcrumb></Breadcrumb>
                <Faq></Faq>
              </div>
            </Route>
            <Route exact path="/privacy_policy">
              <PrivacyPolicy></PrivacyPolicy>
            </Route>
            <Route exact path="/validazione_otp">
              <div className="container my-4">
                <ValidazioneOTP></ValidazioneOTP>
              </div>
            </Route>
            <Route exact path="/pagamento_spontaneo">
              <div className="container my-4">
                <PagamentoSpontaneo></PagamentoSpontaneo>
              </div>
            </Route>
            <Route path="/">
              <Home></Home>
            </Route>
          </Switch>
        </div>
      )}
      {/* 
      {(!apiToken || isOperator) && <ChatBot intent={"WELCOME"}></ChatBot>} */}
    </main>
  );
}

export default Body;

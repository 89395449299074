import React from "react";
import Cookies from "universal-cookie";
import moment from "moment";

import "bootstrap-italia/dist/css/bootstrap-italia.min.css";
import iconSprite from "bootstrap-italia/dist/svg/sprite.svg";
import PagoPAButton from "../Objects/PagoPAButton";

function Cartella(props) {
  const cookies = new Cookies();
  const api_token = cookies.get("api_token");

  const handleChange = (event) => {
    props.children.onClick(event.target.value);
  };

  let cartella = props.children;

  return (
    <>
      {cartella && (
        <>
          <div className="row">
            <div className="col-6 col-md-4">
              <div className="form-group">
                <b>Data Emissione</b>
                <input
                  type="text"
                  className="form-control"
                  readOnly="readonly"
                  onChange={handleChange}
                  value={
                    !cartella.dataEmissioneCartella
                      ? ""
                      : moment(new Date(cartella.dataEmissioneCartella)).format(
                          "DD-MM-YYYY"
                        )
                  }
                />
              </div>
            </div>
            <div className="col-6 col-md-4">
              <div className="form-group">
                <b>Data Notifica </b>
                <input
                  type="text"
                  className="form-control"
                  readOnly="readonly"
                  onChange={handleChange}
                  value={
                    !cartella.dataNotificaCartella
                      ? ""
                      : moment(new Date(cartella.dataNotificaCartella)).format(
                          "DD-MM-YYYY"
                        )
                  }
                />
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="form-group">
                <b>Modalita Notifica </b>
                <input
                  type="text"
                  className="form-control disable"
                  readOnly="readonly"
                  onChange={handleChange}
                  value={
                    !cartella.modalitaNotifica ? "-" : cartella.modalitaNotifica
                  }
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-6 col-md-4">
              <div className="form-group">
                <b>Data Annullamento Cart.</b>
                <input
                  type="text"
                  className="form-control"
                  readOnly="readonly"
                  onChange={handleChange}
                  value={
                    !cartella.dataAnnullamentoCartella
                      ? "-"
                      : moment(
                          new Date(cartella.dataAnnullamentoCartella)
                        ).format("DD-MM-YYYY")
                  }
                />
              </div>
            </div>
            <div className="col-6 col-md-4">
              <div className="form-group">
                <b>Importo Dovuto</b>
                <input
                  type="text"
                  className="form-control disable"
                  onChange={handleChange}
                  value={cartella.importoDovuto + " €"}
                />
              </div>
            </div>
            <div className="col-6 col-md-4">
              <div className="form-group">
                <b>Importo Versato</b>
                <input
                  type="text"
                  className="form-control disable"
                  readOnly="readonly"
                  onChange={handleChange}
                  value={
                    !cartella.importoVersato
                      ? 0 + " €"
                      : cartella.importoVersato + " €"
                  }
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-6 col-md-4">
              <div className="form-group">
                <b>Residuo Da Versare Att.</b>
                <input
                  type="text"
                  className="form-control disable"
                  readOnly="readonly"
                  onChange={handleChange}
                  value={
                    !cartella.residuoDaVersareAttualizzato
                      ? 0 + " €"
                      : cartella.residuoDaVersareAttualizzato + " €"
                  }
                />
              </div>
            </div>
            <div className="col-6 col-md-4">
              <div className="form-group">
                <b>Include Ravvedimento</b>
                <input
                  type="text"
                  className="form-control disable"
                  readOnly="readonly"
                  value={
                    cartella.residuoDaVersareAttualizzato === 1 ? "SI" : "NO"
                  }
                />
              </div>
            </div>
            <div className="col-6 col-md-4">
              <div className="form-group">
                <b>Anno Imposta</b>
                <input
                  type="text"
                  className="form-control disable"
                  readOnly="readonly"
                  onChange={handleChange}
                  value={!cartella.annoImposta ? "-" : cartella.annoImposta}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-4 col-md-4">
              <div className="form-group">
                <b>Tipo Servizio</b>
                <input
                  type="text"
                  className="form-control disable"
                  readOnly="readonly"
                  defaultValue={"-"}
                />
              </div>
            </div>
            {/* <div className="col-6 col-md-6 ">
                            <div className="form-group text-center">
                                <br />
                                {props.isRata ?
                                
                            <> <button type="button" className="btn btn-primary" onClick={()=>props.setpagamentoDovuto(props.pagamento)}>Paga in unica soluzione</button></>
                             : 
                             <> <button type="button" className="btn btn-primary" onClick={()=>props.setpagamentoDovuto(props.pagamento)}>Paga Ora</button></>}
                               
                            </div>
                        </div> */}
          </div>
        </>
      )}
    </>
  );
}
export default Cartella;

import React, { useState } from "react";
import { useCookies } from "react-cookie";
import moment from "moment";
import Repo from "../Utility/Repo";

function ListaPagamentiEffettuati(props) {
  const [cookies] = useCookies();
  const [apiToken] = useState(cookies.api_token);

  const getPdf = async (idCartella) => {
    var data = JSON.stringify({ pkCartella: idCartella, pkTipoCartella: "" });
    Repo.getFile64(apiToken, data)
      .then(function (response) {
        var b64 = response.data.binaryFile;
        var a = window.document.createElement("a");
        a.href = "data:application/octet-stream;base64," + b64;
        a.download = response.data.nomeFile;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <>
      {props.children && props.children.length !== 0 ? (
        <>
          <div
            id="collapseDiv1-lft"
            className="collapse-div collapse-left-icon"
            role="tablist"
          >
            {Array.isArray(props.children) &&
              props.children.map((obbj, index) => (
                <div key={index}>
                  <div className="row">
                    <div className="col-12 col-lg-12">
                      <div className="card-wrapper card-space">
                        <div className="card card-bg">
                          <div className="card-body">
                            <h5 className="card-title">
                              Numero rata: <b> {obbj.numeroRata} </b>
                            </h5>
                            <hr />
                            <div className="row">
                              <div className="col-sm-6 col-lg-4">
                                <div className="row">
                                  Data Pagamento: {" "}
                                  <b className="ml-1"> {moment(new Date(obbj.dataPagamento)).format(
                                    "DD-MM-YYYY"
                                  )} </b>
                                </div>
                              </div>
                              <div className="col-sm-6 col-lg-4">
                                <div className="row">
                                  Metodo di pagamento: {" "}
                                  <b className="ml-1"> {obbj.metodoDiPagamento
                                    ? obbj.metodoDiPagamento
                                    : "Non pervenuto"}</b>
                                </div>
                              </div>
                              <div className="col-sm-6 col-lg-2">
                                <div className="row">
                                  Importo: <b className="ml-1"> {obbj.importo} €</b>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </>
      ) : (
        <b>Nessun Risultato da Mostrare</b>
      )}
    </>
  );
}

export default ListaPagamentiEffettuati;

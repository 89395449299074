import { Input } from "design-react-kit";
import { useState } from "react";
import moment from "moment";
import { useCookies } from "react-cookie";
import Repo from "../Utility/Repo";
import AlertMessage from "../Objects/AlertMessage.js";

function NuovoContribuente(props) {
  const [cookies] = useCookies();
  const [apiToken] = useState(cookies.api_token);
  const [errorMessage, setErrorMessage] = useState();

  const [data, setData] = useState({
    cfPiva: "",
    nome: "",
    cognome: "",
    ragioneSociale: "",
    indirizzoResidenza: "",
    luogoDiResidenza: "",
    dataDiNascita: "2021-05-28T14:06:02.904Z",
    luogoDiNascita: "",
    statoCivile: "",
    sesso: "",
    professione: "",
    dataDecesso: "2021-05-28T14:06:02.904Z",
    email: "",
    pec: "",
    cellulare: "",
  });

  const handleChange = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    switch (name) {
      case "nome":
        setData((prevState) => ({
          ...prevState,
          nome: value,
        }));

        break;

      case "cognome":
        setData((prevState) => ({
          ...prevState,
          cognome: value,
        }));

        break;

      case "cfPiva":
        setData((prevState) => ({
          ...prevState,
          cfPiva: value,
        }));

        break;

      case "luogoDiNascita":
        setData((prevState) => ({
          ...prevState,
          luogoDiNascita: value,
        }));

        break;

      case "professione":
        setData((prevState) => ({
          ...prevState,
          professione: value,
        }));
        break;

      case "luogoDiResidenza":
        setData((prevState) => ({
          ...prevState,
          luogoDiResidenza: value,
        }));
        break;

      case "indirizzoResidenza":
        setData((prevState) => ({
          ...prevState,
          indirizzoResidenza: value,
        }));
        break;

      case "statoCivile":
        setData((prevState) => ({
          ...prevState,
          statoCivile: value,
        }));
        break;

      case "email":
        setData((prevState) => ({
          ...prevState,
          email: value,
        }));
        break;

      case "pec":
        setData((prevState) => ({
          ...prevState,
          pec: value,
        }));
        break;

      case "pec":
        setData((prevState) => ({
          ...prevState,
          pec: value,
        }));
        break;

      case "cellulare":
        setData((prevState) => ({
          ...prevState,
          cellulare: value,
        }));
        break;

      case "ragioneSociale":
        setData((prevState) => ({
          ...prevState,
          ragioneSociale: value,
        }));
        break;

      case "sesso":
        setData((prevState) => ({
          ...prevState,
          sesso: value,
        }));
        break;

      case "dataDiDecesso":
        setData((prevState) => ({
          ...prevState,
          dataDiDecesso: moment(value).format("YYYY-MM-DDTHH:mm:ss"),
        }));

      case "dataDiNascita":
        setData((prevState) => ({
          ...prevState,
          dataDiNascita: moment(value).format("YYYY-MM-DDTHH:mm:ss"),
        }));

        break;

      default:
        break;
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    var dataJson = data;
    dataJson = JSON.stringify(dataJson);

    Repo.postContribuenti(apiToken, dataJson)
      .then((response) => {
        setErrorMessage(false);
        data["dataCreazione"] = moment().format();

        localStorage.setItem("contribuente", JSON.stringify(data));
        window.location.href = props.subfolder + "/";
      })
      .catch((error) => {
        console.log(error);

        setErrorMessage(true);
      });
  };

  return (
    <div className="col-12 ">
      <br></br>
      {errorMessage !== undefined && (
        <AlertMessage error={errorMessage}></AlertMessage>
      )}

      <form onSubmit={handleSubmit} className="">
        <div className="card card-body">
          <div className="card-wrapper card-space">
            <div className="card card-bg">
              <div className="card-body">
                <h4 className="card-title">Contribuente Selezionato:</h4>

                <div className="row form-group">
                  <div className="col-xs-6 col-md-3">
                    <Input
                      id="nome"
                      required
                      label="Nome"
                      type="text"
                      value={data.nome}
                      name="nome"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-xs-6 col-md-4">
                    <Input
                      id="cognome"
                      required
                      label="Cognome"
                      type="text"
                      value={data.cognome}
                      name="cognome"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-xs-6 col-md-5">
                    <Input
                      id="codicefiscale"
                      required
                      label="Codice Fiscale"
                      type="text"
                      value={data.cfPiva}
                      name="cfPiva"
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="row form-group">
                  <div className="col-xs-6 col-md-3">
                    <Input
                      id="dataNascita"
                      label="Data Di Nascita"
                      required
                      type="date"
                      name="dataDiNascita"
                      placeholder="12/02/2021"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-xs-6 col-md-5">
                    <Input
                      id="luogoDiNascita"
                      required
                      label="Luogo di Nascita"
                      type="text"
                      value={data.luogoDiNascita}
                      name="luogoDiNascita"
                      onChange={handleChange}
                    />
                  </div>

                  <div className="col-xs-6 col-md-4">
                    <Input
                      id="professione"
                      required
                      label="Professione"
                      type="text"
                      value={data.professione}
                      name="professione"
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="row form-group">
                  <div className="col-md-3">
                    <Input
                      id="cittàDiResidenza"
                      required
                      label="Città di Residenza"
                      type="text"
                      name="luogoDiResidenza"
                      value={data.luogoDiResidenza}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-5">
                    <Input
                      id="indirizzoDiResidenza"
                      required
                      label="Via di Residenza"
                      type="text"
                      name="indirizzoResidenza"
                      value={data.indirizzoResidenza}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-4">
                    {" "}
                    <Input
                      id="statoCivile"
                      required
                      label="Stato Civile"
                      type="text"
                      name="statoCivile"
                      value={data.statoCivile}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="row form-group">
                  <div className="col-md-3">
                    <Input
                      id="email"
                      required
                      label="Email"
                      type="email"
                      name="email"
                      value={data.email}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-5">
                    <Input
                      id="pec"
                      label="Pec"
                      type="email"
                      name="pec"
                      value={data.pec}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-4">
                    {" "}
                    <Input
                      required
                      label="Cellulare"
                      type="number"
                      name="cellulare"
                      id="cellulare"
                      value={data.cellulare}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="row form-group">
                  <div className="col-md-3">
                    <Input
                      label="ragioneSociale"
                      type="text"
                      name="ragioneSociale"
                      id="ragioneSociale"
                      value={data.ragioneSociale}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-5">
                    <Input
                      onChange={handleChange}
                      label="Sesso"
                      type="text"
                      name="sesso"
                      id="sesso"
                      value={data.sesso}
                    />
                  </div>
                  <div className="col-md-4">
                    {" "}
                    <Input
                      label="Data Di Morte"
                      type="date"
                      id="dataDiDecesso"
                      name="dataDiDecesso"
                      placeholder="12/02/2021"
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <a className="read-more align-right col-12">
                  <button className="btn btn-primary">Invia</button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default NuovoContribuente;

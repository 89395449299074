import axios from "axios";
import configuration from "./config.json";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const hub_url = window.__RUNTIME_CONFIG__.REACT_APP_HUB_URL;

const auth_url = window.__RUNTIME_CONFIG__.REACT_APP_AUTH_URL;

var entityId = cookies.get("ente") ? cookies.get("ente").idEnte : "";

var operator =
  cookies.get("user_profile") && cookies.get("user_profile").WorkingRole;

var clientId = "bari_test";
// var entityId = "8fefaafb-5a9f-44a6-b499-e4b56998eaf4";

const Repo = {
  getContribuenti: (token, obb) => {
    var config = {
      method: "post",
      url: hub_url + configuration.HUB_PATH_GET_CONTRIBUENTI,
      headers: {
        entityId: entityId,
        clientId: clientId,
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/pdf",
        role: "OPERATOR",
      },
      data: obb,
    };
    return axios(config);
  },

  postContribuenti: (token, obb) => {
    var config = {
      method: "post",
      url: hub_url + configuration.HUB_PATH_POST_CONTRIBUENTE,
      headers: {
        entityId: entityId,
        clientId: clientId,
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/pdf",
        role: "OPERATOR",
      },
      data: obb,
    };
    return axios(config);
  },

  putContribuenti: (token, obb) => {
    var config = {
      method: "Put",
      url: hub_url + configuration.HUB_PATH_PUT_CONTRIBUENTE,
      headers: {
        entityId: entityId,
        clientId: clientId,
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/pdf",
        role: "OPERATOR",
      },
      data: obb,
    };
    return axios(config);
  },

  getFascicolo: (token, obb) => {
    var header = {
      entityId: entityId,
      clientId: clientId,
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
      Accept: "application/pdf",
    };

    if (operator) {
      header["role"] = operator;
    }

    var config = {
      method: "post",
      url: hub_url + configuration.HUB_PATH_GET_FASCICOLO,
      headers: header,
      data: obb,
    };
    return axios(config);
  },

  getPagamentiAttesiCartelle: (token, obb) => {
    var header = {
      entityId: entityId,
      clientId: clientId,
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
      Accept: "application/pdf",
    };

    if (operator) {
      header["role"] = operator;
    }

    var config = {
      method: "post",
      url: hub_url + configuration.HUB_PATH_GET_PAGAMENTI_DOVUTI_CARTELLE,
      headers: header,
      data: obb,
    };
    return axios(config);
  },

  getPagamentiAttesiContribuente: (token, obb) => {
    var header = {
      entityId: entityId,
      clientId: clientId,
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
      Accept: "application/pdf",
    };

    if (operator) {
      header["role"] = operator;
    }

    var config = {
      method: "post",
      url: hub_url + configuration.HUB_PATH_GET_PAGAMENTI_DOVUTI_CONTRIBUENTE,
      headers: header,
      data: obb,
    };
    return axios(config);
  },

  getPagamentiEffettuatiCartella: (token, obb) => {
    var header = {
      entityId: entityId,
      clientId: clientId,
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
      Accept: "application/pdf",
    };

    if (operator) {
      header["role"] = operator;
    }

    var config = {
      method: "post",
      url: hub_url + configuration.HUB_PATH_GET_PAGAMENTI_EFFETTUATI_CARTELLE,
      headers: header,
      data: obb,
    };
    return axios(config);
  },

  getPagamentiEffettuatiContribuente: (token, obb) => {
    var header = {
      entityId: entityId,
      clientId: clientId,
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
      Accept: "application/pdf",
    };

    if (operator) {
      header["role"] = operator;
    }

    var config = {
      method: "post",
      url:
        hub_url + configuration.HUB_PATH_GET_PAGAMENTI_EFFETTUATI_CONTRIBUENTE,
      headers: header,
      data: obb,
    };
    return axios(config);
  },

  getCartelleCorrelate: (token, obb) => {
    var header = {
      entityId: entityId,
      clientId: clientId,
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
      Accept: "application/pdf",
    };

    if (operator) {
      header["role"] = operator;
    }

    var config = {
      method: "post",
      url: hub_url + configuration.HUB_PATH_GET_CARTELLE_CORRELATE,
      headers: header,
      data: obb,
    };
    return axios(config);
  },

  getCartellaAllegati: (token, obb) => {
    var header = {
      entityId: entityId,
      clientId: clientId,
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
      Accept: "application/pdf",
    };

    if (operator) {
      header["role"] = operator;
    }

    var config = {
      method: "post",
      url: hub_url + configuration.HUB_PATH_GET_ALLEGATI,
      headers: header,
      data: obb,
    };
    return axios(config);
  },

  getUserProfile: (token) =>
    axios({
      method: "GET",
      url:
        auth_url +
        configuration.AUTH_PATH_GET_PROFILE +
        "?clientId=" +
        clientId +
        "&entityId=" +
        entityId,
      headers: {
        "content-type": "application/octet-stream",
        "x-rapidapi-host": "example.com",
        Authorization: "Bearer " + token,
      },
      params: {},
    }),

  getEntities: () => {
    var config = {
      method: "get",
      url:
        auth_url +
        configuration.AUTH_PATH_GET_ENTITIES +
        "?clientId=" +
        clientId,
      headers: {},
    };

    return axios(config);
  },

  getCartellaDownload: (tokenD, idCartella, idTipoPratica) =>
    axios({
      method: "get",
      url:
        hub_url +
        configuration.HUB_PATH_GET_DOWNLOADCARTELLA +
        "/" +
        idCartella +
        "?t=" +
        tokenD +
        "&tc=" +
        idTipoPratica +
        "&eid=" +
        entityId,
      headers: {},
    }),

  getAllegatoDownload: (tokenD, idAllegato, idTipoPratica) =>
    axios({
      url:
        hub_url +
        configuration.HUB_PATH_GET_DOWNLOAD_ALLEGATO +
        "/" +
        idAllegato +
        "?t=" +
        tokenD +
        "&tc=" +
        idTipoPratica +
        "&eid=" +
        entityId,
      method: "get",
      responseType: "blob", // important
    }),

  getFile64: (token, obb) => {
    var config = {
      method: "post",
      url: hub_url + configuration.HUB_PATH_GET_DOWNLOAD,
      //responseType: 'arraybuffer',
      headers: {
        Role: "OPERATOR",
        entityId: entityId,
        clientId: clientId,
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        //'Accept': 'application/zip',
      },
      data: obb,
    };

    return axios(config);
  },

  postUrlPagoPa: (token, obb) => {
    var header = {
      entityId: entityId,
      clientId: clientId,
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    };

    if (operator) {
      header["role"] = operator;
    }

    var config = {
      method: "post",
      url: hub_url + configuration.HUB_PATH_POST_URL_PAGOPA,
      headers: header,
      data: obb,
    };
    return axios(config);
  },

  getModello3: (token, obb) => {
    var header = {
      entityId: entityId,
      clientId: clientId,
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
      Accept: "application/pdf",
    };

    if (operator) {
      header["role"] = operator;
    }

    var config = {
      method: "post",
      url: hub_url + configuration.HUB_PATH_GET_DOWNLOAD_MODELLOTRE,
      headers: header,
      data: obb,
    };

    return axios(config);
  },

  getPosizioniDebitorie: (token, obb) => {
    var header = {
      EntityId: entityId,
      ClientId: clientId,
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
      Accept: "application/pdf",
    };

    if (operator) {
      header["role"] = operator;
    }

    var config = {
      method: "post",
      url: hub_url + configuration.HUB_PATH_GET_POSIZIONI,
      headers: header,
      data: obb,
    };
    return axios(config);
  },

  deletePosizioneDebitoria: (token, id) => {
    var header = {
      EntityId: entityId,
      ClientId: clientId,
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
      Accept: "application/pdf",
    };

    if (operator) {
      header["role"] = operator;
    }

    var config = {
      method: "delete",
      url: hub_url + configuration.HUB_PATH_DELETE_POSIZIONE + "/" + id,
      headers: header,
    };
    return axios(config);
  },

  postPosizioniDebitorie: (token, obb) => {
    var header = {
      EntityId: entityId,
      ClientId: clientId,
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
      Accept: "application/pdf",
    };

    if (operator) {
      header["role"] = operator;
    }

    var config = {
      method: "post",
      url: hub_url + configuration.HUB_PATH_POST_POSIZIONI,
      headers: header,
      data: obb,
    };
    return axios(config);
  },

  putPosizioniDebitorie: (token, obb) => {
    var header = {
      EntityId: entityId,
      ClientId: clientId,
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
      Accept: "application/pdf",
    };

    if (operator) {
      header["role"] = operator;
    }

    var config = {
      method: "put",
      url: hub_url + configuration.HUB_PATH_PUT_POSIZIONI,
      headers: header,
      data: obb,
    };
    return axios(config);
  },

  getTipiEntrata: (token) => {
    var header = {
      EntityId: entityId,
      ClientId: clientId,
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
      Accept: "application/pdf",
    };

    if (operator) {
      header["role"] = operator;
    }

    var config = {
      method: "post",
      url: hub_url + configuration.HUB_PATH_GET_ENTRATE,
      headers: header
    };
    return axios(config);
  }
};

export default Repo;

import { useEffect, useState } from "react";
import { Input } from "design-react-kit";
import moment from "moment";

function Anagrafica(props) {
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(props.contribuente);
  }, [props.contribuente]);

  const handleChange = (event) => {
    let value = event.target.value;
    let name = event.target.name;

    switch (name) {
      case "nome":
        setData((prevState) => ({
          ...prevState,
          nome: value,
        }));

        break;

      case "cognome":
        setData((prevState) => ({
          ...prevState,
          cognome: value,
        }));

        break;

      case "cfPiva":
        setData((prevState) => ({
          ...prevState,
          cfPiva: value,
        }));

        break;

      case "luogoDiNascita":
        setData((prevState) => ({
          ...prevState,
          luogoDiNascita: value,
        }));

        break;

      case "professione":
        setData((prevState) => ({
          ...prevState,
          professione: value,
        }));
        break;

      case "luogoDiResidenza":
        setData((prevState) => ({
          ...prevState,
          luogoDiResidenza: value,
        }));
        break;

      case "indirizzoResidenza":
        setData((prevState) => ({
          ...prevState,
          indirizzoResidenza: value,
        }));
        break;

      case "statoCivile":
        setData((prevState) => ({
          ...prevState,
          statoCivile: value,
        }));
        break;

      case "email":
        setData((prevState) => ({
          ...prevState,
          email: value,
        }));
        break;

      case "pec":
        setData((prevState) => ({
          ...prevState,
          pec: value,
        }));
        break;

      case "pec":
        setData((prevState) => ({
          ...prevState,
          pec: value,
        }));
        break;

      case "cellulare":
        setData((prevState) => ({
          ...prevState,
          cellulare: value,
        }));
        break;

      case "ragioneSociale":
        setData((prevState) => ({
          ...prevState,
          ragioneSociale: value,
        }));
        break;

      case "sesso":
        setData((prevState) => ({
          ...prevState,
          sesso: value,
        }));
        break;

      case "dataDiDecesso":
        setData((prevState) => ({
          ...prevState,
          dataDiDecesso: moment(value).format("YYYY-MM-DDTHH:mm:ss"),
        }));

      case "dataDiNascita":
        setData((prevState) => ({
          ...prevState,
          dataDiNascita: moment(value).format("YYYY-MM-DDTHH:mm:ss"),
        }));

        break;

      default:
        break;
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    props.eventSave(data);
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="row form-group mt-4 group-padding">
          <div className="col-xs-6 col-md-3 input-padding">
            {props.editMode ? (
              <Input
                id="nome"
                required
                label="Nome"
                type="text"
                value={data.nome ? data.nome : ""}
                name="nome"
                onChange={handleChange}
              />
            ) : (
              <Input
                disabled
                label="Nome"
                type="text"
                value={
                  props.contribuente.nome
                    ? props.contribuente.nome
                    : "Non Presente"
                }
              />
            )}
          </div>
          <div className="col-xs-6 col-md-4 input-padding">
            {props.editMode ? (
              <Input
                id="cognome"
                required
                label="Cognome"
                type="text"
                value={data.cognome ? data.cognome : ""}
                name="cognome"
                onChange={handleChange}
              />
            ) : (
              <Input
                disabled
                label="Cognome"
                type="text"
                value={
                  props.contribuente.cognome
                    ? props.contribuente.cognome
                    : "Non Presente"
                }
              />
            )}
          </div>
          <div className="col-xs-6 col-md-5 mt-1 input-padding">
            {" "}
            {props.editMode ? (
              <Input
                id="codicefiscale"
                required
                label="Codice Fiscale"
                type="text"
                value={data.cfPiva ? data.cfPiva : ""}
                name="cfPiva"
                onChange={handleChange}
              />
            ) : (
              <Input
                disabled
                label="Codice Fiscale"
                type="text"
                value={
                  props.contribuente.cfPiva
                    ? props.contribuente.cfPiva
                    : "Non Presente"
                }
              />
            )}
          </div>
        </div>

        <div className="row form-group group-padding">
          <div className="col-xs-6 col-md-3 input-padding">
            {props.editMode ? (
              <Input
                id="dataNascita"
                label="Data Di Nascita"
                required
                type="date"
                name="dataDiNascita"
                onChange={handleChange}
                value={moment(data.dataDiNascita).format("YYYY-MM-DD")}
              />
            ) : (
              <Input
                disabled
                label="Data di Nascita"
                type="text"
                value={
                  props.contribuente.dataDiNascita
                    ? moment(props.contribuente.dataDiNascita).format(
                        "DD-MM-YYYY"
                      )
                    : "/"
                }
              />
            )}
          </div>
          <div className="col-xs-6 col-md-5 input-padding">
            {props.editMode ? (
              <Input
                id="luogoDiNascita"
                required
                label="Luogo di Nascita"
                type="text"
                value={data.luogoDiNascita ? data.luogoDiNascita : ""}
                name="luogoDiNascita"
                onChange={handleChange}
              />
            ) : (
              <Input
                disabled
                label="Luogo di Nascita"
                type="text"
                value={
                  props.contribuente.luogoDiNascita
                    ? props.contribuente.luogoDiNascita
                    : "Non Presente"
                }
              />
            )}
          </div>

          <div className="col-xs-6 col-md-4 input-padding">
            {props.editMode ? (
              <Input
                id="professione"
                required
                label="Professione"
                type="text"
                value={data.professione ? data.professione : ""}
                name="professione"
                onChange={handleChange}
              />
            ) : (
              <Input
                disabled
                label="Professione"
                type="text"
                value={
                  props.contribuente.professione
                    ? props.contribuente.professione
                    : " "
                }
              />
            )}
          </div>
        </div>

        <div className="row form-group group-padding">
          <div className="col-md-3 input-padding">
            {props.editMode ? (
              <Input
                id="cittàDiResidenza"
                required
                label="Città di Residenza"
                type="text"
                name="luogoDiResidenza"
                value={data.luogoDiResidenza ? data.luogoDiResidenza : ""}
                onChange={handleChange}
              />
            ) : (
              <Input
                disabled
                label="Città di Residenza"
                type="text"
                value={
                  props.contribuente.luogoDiResidenza
                    ? props.contribuente.luogoDiResidenza
                    : "Non Presente"
                }
              />
            )}
          </div>
          <div className="col-md-5 input-padding">
            {props.editMode ? (
              <Input
                id="indirizzoDiResidenza"
                required
                label="Via di Residenza"
                type="text"
                name="indirizzoResidenza"
                value={data.indirizzoResidenza ? data.indirizzoResidenza : ""}
                onChange={handleChange}
              />
            ) : (
              <Input
                disabled
                label="Via di Residenza"
                type="text"
                value={
                  props.contribuente.indirizzoResidenza
                    ? props.contribuente.indirizzoResidenza
                    : "Non Presente"
                }
              />
            )}
          </div>
          <div className="col-md-4 input-padding">
            {props.editMode ? (
              <Input
                id="statoCivile"
                required
                label="Stato Civile"
                type="text"
                name="statoCivile"
                value={data.statoCivile ? data.statoCivile : ""}
                onChange={handleChange}
              />
            ) : (
              <Input
                disabled
                label="Stato Civile"
                type="text"
                value={
                  props.contribuente.statoCivile
                    ? props.contribuente.statoCivile
                    : "Non Presente"
                }
              />
            )}
          </div>
        </div>

        <div className="row form-group group-padding">
          <div className="col-md-3 input-padding">
            {props.editMode ? (
              <Input
                id="email"
                required
                label="Email"
                type="email"
                name="email"
                value={data.email ? data.email : ""}
                onChange={handleChange}
              />
            ) : (
              <Input
                disabled
                label="Email"
                type="text"
                value={
                  props.contribuente.email
                    ? props.contribuente.email
                    : "Non Presente"
                }
              />
            )}
          </div>
          <div className="col-md-5 input-padding">
            {props.editMode ? (
              <Input
                id="pec"
                label="Pec"
                type="email"
                name="pec"
                value={data.pec ? data.pec : ""}
                onChange={handleChange}
              />
            ) : (
              <Input
                disabled
                label="Pec"
                type="text"
                value={
                  props.contribuente.pec
                    ? props.contribuente.pec
                    : "Non Presente"
                }
              />
            )}
          </div>
          <div className="col-md-4 input-padding">
            {props.editMode ? (
              <Input
                required
                label="Cellulare"
                type="number"
                name="cellulare"
                id="cellulare"
                value={data.cellulare ? data.cellulare : ""}
                onChange={handleChange}
              />
            ) : (
              <Input
                disabled
                label="Cellulare"
                type="text"
                value={
                  props.contribuente.cellulare
                    ? props.contribuente.cellulare
                    : "Non Presente"
                }
              />
            )}
          </div>
        </div>

        {props.editMode && (
          <div className="col-12 text-center">
            <button
              className="btn btn-primary"
              data-toggle="collapse1a"
              href="#collapseExample"
              role="button"
              aria-expanded="false"
              aria-controls="collapseExample"
              type="submite"
            >
              Salva Modifiche
            </button>
          </div>
        )}
      </form>
    </>
  );
}

export default Anagrafica;

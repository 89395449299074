import { Link } from "react-router-dom";

function Rettifica(props) {
  return (
    <>
      {!props.isOperator && (
        <>
          <div className="px-lg-5 px-2">
            <div className="row pt-md-4">
              <div className="col-lg-10  col-12">
                <h2>Rettifica dati</h2>
                <p>
                  In questa sezione è possibile procedere alla registrazione di
                  una richiesta di rettifica dei dati anagrafici oppure
                  modificare o consultare le richieste precedentemente inserite.
                </p>

                <div className="col-lg-12">
                  <div className="card-wrapper card-space">
                    <div className="card card-bg">
                      <div className="card-body">
                        <h5 className="card-title">Nuova richiesta</h5>
                        <p className="card-text">
                          Per inserire una nuova richiesta di rettifica puoi
                          consultare la{" "}
                          <Link to="https://sc.anpr.interno.it/serviziCittadino/dist/docs/guida_compilazione_rettifica.pdf">
                            Guida alla compilazione.
                          </Link>
                        </p>
                        <Link
                          to="/richiesta_rettifica"
                          className="btn btn-primary"
                        >
                          Inserisci una richiesta
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Rettifica;

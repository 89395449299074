import React, { useState, useEffect } from "react";

export const DovutiApiContext = React.createContext();

const DovutiContext = props => {
  const [responseDovuti, setResponse] = useState();
  

  return (
    <DovutiApiContext.Provider value={{responseDovuti, setResponse}}>{props.children}</DovutiApiContext.Provider>
  );
};

export default DovutiContext;
import React, { useState, useEffect } from "react";

export const ApiContext = React.createContext();

const Context = props => {
  const [responseFascicolo, setResponseFascicolo] = useState([]);
  

  return (
    <ApiContext.Provider value={{responseFascicolo, setResponseFascicolo}}>{props.children}</ApiContext.Provider>
  );
};

export default Context;
import {
  Card,
  CardBody,
  CardTitle,
  CardText,
  Accordion,
  AccordionHeader,
  AccordionBody,
  Button,
  Collapse,
  Input,
} from "design-react-kit";
import { useEffect, useState, useContext } from "react";

import { useCookies } from "react-cookie";
import Repo from "../Utility/Repo";
import moment from "moment";
import { Link } from "react-router-dom";
import Anagrafica from "./Anagrafica";
import AlertMessage from "../Objects/AlertMessage.js";
import Notification from "./Notification";
import { ApiContext } from "../Contexts/ContribuentiContext";
import Pagination from "../Objects/Pagination";
import iconSprite from "bootstrap-italia/dist/svg/sprite.svg";

import ReactPaginate from "react-paginate";

function PanelloOperatore(props) {
  const [listaContribuente, setListaContribuente] = useState();
  const [contribuenteSelezionato, setContribuenteSelezionato] = useState([]);
  const [cookies] = useCookies();
  const [apiToken] = useState(cookies.api_token);
  const [loading, setLoading] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const [resetPag, setResetPag] = useState(true);

  let limit = 10;

  const [pageCount, setPageCount] = useState(0);
  const [queryCount, setQueryCount] = useState(0);
  let pageIndex = 0;
  const [pindex, setPIndex] = useState(0);

  const [dataRequest, setDataRequest] = useState({
    cfPiva: "",
    cognome: "",
    nome: "",
    pageIndex: 0,
    pageSize: 15,
    flagRefresh: 1,
    orderDescending: false,
    orderField: "Cognome",
  });

  const [valdie, setValid] = useState(true);

  const handleChange = (event) => {
    let value = event.target.value;

    switch (event.target.name) {
      case "nome":
        setDataRequest((prevState) => ({
          ...prevState,
          nome: value,
        }));
        break;

      case "cognome":
        setDataRequest((prevState) => ({
          ...prevState,
          cognome: value,
        }));
        break;

      case "cfiva":
        setDataRequest((prevState) => ({
          ...prevState,
          cfpIva: value,
        }));
        break;

      case "dataDiNascita":
        setDataRequest((prevState) => ({
          ...prevState,
          dataDiNascita: moment(value).format("YYYY-MM-DDTHH:mm:ss+01:00"),
        }));
        break;

      default:
        break;
    }
  };

  // const ChangePage = (numberPage) => {
  //   var page = numberPage - 1;
  //   var dataTemp = data;
  //   dataTemp.pageIndex = page;
  //   setData(dataTemp);
  //   pageIndex = numberPage - 1;
  //   setPIndex(pageIndex);

  //   reload();
  // };

  const handlePageClick = (pageSelect) => {
    console.clear();
    window.scroll(0, 0);
    let currentPage = pageSelect.selected + 1;

    var page = currentPage - 1;
    var dataTemp = dataRequest;
    dataTemp.pageIndex = page;
    setDataRequest(dataTemp);
    pageIndex = currentPage - 1;
    setPIndex(pageIndex);

    reload();
  };

  const reload = () => {
    var dataJson = dataRequest;
    dataJson = JSON.stringify(dataJson);
    setLoading(false);
    Repo.getContribuenti(apiToken, dataJson)
      .then((response) => {
        setListaContribuente(response.data.items ? response.data.items : []);
        setLoading(true);
        setPageCount(response.data.pageCount);
        setQueryCount(response.data.queryCount);
        //   pageIndex = response.data.pageIndex;
        //   setPIndex(pageIndex);
      })
      .catch((error) => {
        console.log(error);
        setLoading(true);
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    setResetPag(false);
    var page = 0;
    var dataTemp = dataRequest;
    dataTemp.pageIndex = 0;
    setDataRequest(dataTemp);
    pageIndex = 0;
    setPIndex(pageIndex);
    setPageCount(0);
    if (apiToken) {
      reload();
      setTimeout(function () {
        setResetPag(true);
      }, 5);
    }
  };

  const clearContribuente = (event) => {
    localStorage.removeItem("contribuente");
    window.location.href = props.subfolder + "/ricerca";
  };

  useEffect(() => {
    var obbjContribuente = JSON.parse(localStorage.getItem("contribuente"));

    if (obbjContribuente) {
      setContribuenteSelezionato(obbjContribuente);
    }
  }, []);

  useEffect(() => {}, [handlePageClick]);

  const selezionaUtente = (obbj) => {
    obbj["dataCreazione"] = moment().format();
    localStorage.setItem("contribuente", JSON.stringify(obbj));
    window.location.href = props.subfolder + "/";
  };

  const saveEdit = (obbj) => {
    setLoading(false);

    var dataJson = obbj;
    dataJson = JSON.stringify(dataJson);
    Repo.putContribuenti(apiToken, dataJson)
      .then((response) => {
        setTimeout(() => {
          setErrorMessage();
        }, 5000);

        obbj["dataCreazione"] = moment().format();
        localStorage.setItem("contribuente", JSON.stringify(obbj));
        setLoading(true);
        setEditMode(false);
        setErrorMessage(false);

        setContribuenteSelezionato(obbj);

        setTimeout(() => {
          setErrorMessage();
        }, 5000);
      })
      .catch((error) => {
        console.log(error);
        setErrorMessage(true);
      });
  };

  return (
    <>
      {errorMessage !== undefined && (
        <Notification
          fix="top-fix"
          header="Salvataggio avvenuto con successo"
          state="success"
          withIcon
          dismissable="true"
        />
      )}

      {contribuenteSelezionato.length != 0 ? (
        <>
          <div className="row ">
            <div className="col-10 mr-0 text-center">
              <div className="collapse-header" id="heading1a">
                <button
                  className="collapseCustom px-2"
                  data-toggle="collapse"
                  data-target="#collapse1a"
                  aria-expanded="false"
                  aria-controls="collapse1a"
                >
                  Scheda di:{" "}
                  {contribuenteSelezionato.nome && contribuenteSelezionato.cognome ? (
                    contribuenteSelezionato.nome +
                    " " +
                    contribuenteSelezionato.cognome +
                    " - " +
                    contribuenteSelezionato.cfPiva)
                  : (contribuenteSelezionato.ragioneSociale)}
                </button>
              </div>
            </div>
            <div className="col-1 pt-2 mt-1 text-center" style={{}}>
              <svg
                className="icon icon-primary"
                // style={{ paddingRight: "0px" }}
                onClick={() => clearContribuente()}
                style={{ cursor: "pointer" }}
              >
                <use xlinkHref={`${iconSprite}#it-close-circle`}></use>
              </svg>
            </div>

            <div className="col-12 ">
              <br></br>
              <div
                id="collapse1a"
                className="collapse "
                role="tabpanel"
                aria-labelledby="heading1a"
              >
                <div className="card card-body">
                  <div className="card-wrapper card-space">
                    <div className="card card-bg">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-8">
                            <h5 className="card-title">
                              Contribuente Selezionato:
                            </h5>
                          </div>
                          <div className="col-4 text-right">
                            <button
                              {...(!editMode
                                ? { className: "btn btn-warning mr-1 btn-sm" }
                                : { className: "btn-sm btn btn-danger mr-1" })}
                              onClick={() => setEditMode(!editMode)}
                            >
                              {!editMode ? "Modifica Dati" : "Annulla"}
                            </button>
                          </div>
                        </div>

                        <Anagrafica
                          contribuente={contribuenteSelezionato}
                          editMode={editMode}
                          eventSave={saveEdit}
                        ></Anagrafica>
                        <div className="col-12 text-center">
                          {!editMode && (
                            <button
                              className="btn btn-secondary"
                              data-toggle="collapse1a"
                              href="#collapseExample"
                              role="button"
                              aria-expanded="false"
                              aria-controls="collapseExample"
                              onClick={() => clearContribuente()}
                            >
                              Ricerca Nuovo Contribuente
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="row">
          <div className="col-12 ">
            <div className="card-wrapper card-space">
              <div className="card card-bg">
                <div className="card-body">
                  <div className="row">
                    <div className="col-8">
                      <h5 className="card-title">Cerca Contribuente</h5>
                    </div>
                    <div className="col-4 text-right">
                      <Link
                        to={{
                          pathname: "/contribuente/nuovo",
                        }}
                      >
                        <button className="btn btn-primary mr-1 ">Nuovo</button>
                      </Link>
                    </div>
                  </div>

                  <form onSubmit={handleSubmit}>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <Input
                          id="exampleInputText1"
                          label="Nome"
                          value={dataRequest.nome}
                          onChange={handleChange}
                          type="text"
                          name="nome"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <Input
                          id="exampleInputText2"
                          label="Cognome"
                          value={dataRequest.cognome}
                          onChange={handleChange}
                          type="text"
                          name="cognome"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <Input
                          id="exampleInputText3"
                          label="Codice Fiscale/Partita Iva"
                          value={dataRequest.cfpIva}
                          onChange={handleChange}
                          type="text"
                          name="cfiva"
                          maxLength="16"
                          minLength="11"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <Input
                          id="exampleInputText4"
                          label="Data Di Nascita"
                          onChange={handleChange}
                          type="date"
                          name="dataDiNascita"
                          placeholder="12/02/2021"
                        />
                      </div>
                      <div className="form-group col-md-12 text-center">
                        <button className="btn btn-primary mr-1" type="submit">
                          Ricerca
                        </button>
                      </div>
                    </div>
                  </form>
                  {listaContribuente && (
                    <div className="row">
                      <div className="col-12">
                        {listaContribuente && listaContribuente.length > 0 ? (
                          listaContribuente.map((x, index) => (
                            <div key={index}>
                              <div className="card card-teaser rounded shadow mb-2  col-12">
                                <div className="card-body">
                                  <div className="row">
                                    <div className="col-sm-6 col-lg-4 d-flex align-items-center">
                                      <h5 className="card-title">
                                        {x.cognome || x.nome
                                          ? x.cognome + " " + x.nome
                                          : x.ragioneSociale ? x.ragioneSociale : "Non Presente"}
                                      </h5>
                                    </div>
                                    <div className="col-sm-6 col-lg-4 d-flex align-items-center">
                                      {x.cfPiva && (
                                        <>
                                          Codice Fiscale: <b className="ml-1">{x.cfPiva}</b>
                                        </>
                                      )}
                                      {/* {x.ragioneSociale && (
                                        <>
                                          <br />
                                          Ragione Sociale:{" "}
                                          <b>{x.ragioneSociale}</b>
                                        </>
                                      )} */}
                                      {x.email && (
                                        <>
                                          <br />
                                          Email: <b>{x.email}</b>
                                        </>
                                      )}
                                    </div>

                                    <div className="col-sm-12 col-lg-4 text-right">
                                      <button
                                        onClick={() => selezionaUtente(x)}
                                        className="btn btn-primary"
                                        style={{ minWidth: "164px" }}
                                      >
                                        Seleziona Utente
                                      </button>
                                    </div>
                                  </div>

                                  <div className="row"></div>
                                </div>
                              </div>

                              {/* <th style={{ width: "60px" }}>
                              {" "}
                              {pindex * 15 + index + 1}
                            </th>
                            <td style={{ minWidth: "160px" }}>

                            </td>
                            <td>{x.ragioneSociale}</td>
                            <td>{x.cfPiva}</td>
                            <td>{x.email ? x.email : "Non Presente"}</td>
                            <td>
                              <button
                                onClick={() => selezionaUtente(x)}
                                className="btn btn-primary"
                                style={{ minWidth: "164px" }}
                              >
                                Seleziona Utente
                              </button>
                            </td> */}
                            </div>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="5" className="text-center">
                              <b>Nessun Utente</b>
                            </td>
                          </tr>
                        )}

                        <div className="text-right">
                          <b>Totale: </b>
                          {queryCount}
                        </div>
                        <br />

                        <div className="row col-12">
                          {resetPag && (
                            <ReactPaginate
                              previousLabel={
                                <svg className="icon icon-primary">
                                  <use
                                    xlinkHref={`${iconSprite}#it-chevron-left`}
                                  ></use>
                                </svg>
                              }
                              nextLabel={
                                <svg className="icon icon-primary">
                                  <use
                                    xlinkHref={`${iconSprite}#it-chevron-right`}
                                  ></use>
                                </svg>
                              }
                              breakLabel={"..."}
                              pageCount={pageCount}
                              marginPagesDisplayed={2}
                              pageRangeDisplayed={4}
                              onPageChange={handlePageClick}
                              containerClassName={
                                "pagination justify-content-center col-12"
                              }
                              pageClassName={"page-item"}
                              pageLinkClassName={"page-link"}
                              previousClassName={"page-item"}
                              previousLinkClassName={"page-link"}
                              nextClassName={"page-item"}
                              nextLinkClassName={"page-link"}
                              breakClassName={"page-item"}
                              breakLinkClassName={"page-link"}
                              activeClassName={"active"}
                              initialPage={0}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {!loading && (
        <div className="col-12">
          <div className="trasparente">
            <div className="progress-spinner progress-spinner-active spinner">
              <span className="sr-only">Caricamento...</span>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PanelloOperatore;

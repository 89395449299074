import React, { useState } from "react";
import { useCookies } from "react-cookie";
import moment from "moment";
import Repo from "../Utility/Repo";
import { Input } from "design-react-kit";
import iconSprite from "bootstrap-italia/dist/svg/sprite.svg";

function Allegati(props) {
  const [cookies] = useCookies();
  const [apiToken] = useState(cookies.api_token);

  const [localEnte] = useState(cookies.ente);
  const [defaultEntity, setDefaultEntity] = useState(
    cookies.user_profile ? cookies.user_profile.AreaName : ""
  );

  const getPdf = async (obbj) => {
    Repo.getAllegatoDownload(
      obbj.tokenDownload,
      obbj.idAllegato,
      props.idTipoEntrata
    )
      .then(function (response) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", obbj.nomeFileAllegato); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <>
      {props.children.length !== 0 ? (
        <>
          <div className="row mt-2 mr-2">
            {Array.isArray(props.children) &&
              props.children.map((obbj, index) => (
                <div className="col-12 col-lg-4" key={index}>
                  <div className="card-wrapper card-space">
                    <div className="card card-bg">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-7">
                            <h6 className="card-title">
                              {obbj.nomeFileAllegato}
                            </h6>
                          </div>
                          <div className="col-5 text-right">
                            <button
                              className="btn btn-primary mr-1 "
                              onClick={() => getPdf(obbj)}
                            >
                              <svg className="icon icon-sm icon-white">
                                <use href={`${iconSprite}#it-download`} />
                              </svg>{" "}
                              <span>Scarica</span>
                            </button>
                          </div>
                        </div>

                        <div className="">
                          <hr></hr>
                          <div className="row mt-4">
                            <div className="col-xs-6 col-sm-6 col-lg-6">
                              {" "}
                              <Input
                                disabled
                                readOnly
                                label="Data Emissione"
                                type="text"
                                value={
                                  obbj.dataEmissioneAllegato
                                    ? moment(
                                      new Date(obbj.dataEmissioneAllegato)
                                    ).format("DD-MM-YYYY")
                                    : "/"
                                }
                              />
                            </div>
                            <div className="col-xs-6 col-sm-6 col-lg-6">
                              {" "}
                              <Input
                                disabled
                                readOnly
                                label="Data Notifica"
                                type="text"
                                value={
                                  obbj.dataNotificaAllegato
                                    ? moment(
                                      new Date(obbj.dataNotificaAllegato)
                                    ).format("DD-MM-YYYY")
                                    : "/"
                                }
                              />
                            </div>
                          </div>
                          <div className="row  mt-3">
                            <div className="col-xs-12  col-sm-12 col-lg-12">
                              <Input
                                disabled
                                readOnly
                                label="Modalità Notifica"
                                type="text"
                                value={
                                  obbj.dataNotificaAllegato
                                    ? obbj.dataNotificaAllegato
                                    : "/"
                                }
                              />
                            </div>
                          </div>
                          <div className="row  mt-3">
                            <div className="col-xs-6 col-sm-6 col-lg-6">
                              <Input
                                disabled
                                readOnly
                                label="Data Annullam."
                                type="text"
                                value={
                                  obbj.dataAnnullamentoAllegato
                                    ? moment(
                                      new Date(obbj.dataAnnullamentoAllegato)
                                    ).format("DD-MM-YYYY")
                                    : "/"
                                }
                              />
                            </div>
                            <div className="col-xs-6 col-sm-6 col-lg-6">
                              <Input
                                disabled
                                readOnly
                                label="Descr. Tipo"
                                type="text"
                                value={
                                  obbj.descrizioneTipoAllegato
                                    ? obbj.descrizioneTipoAllegato
                                    : "/"
                                }
                              />
                            </div>
                          </div>
                          <div className="row  mt-3">
                            <div className="col-xs-6 col-sm-6 col-lg-6">
                              <Input
                                disabled
                                readOnly
                                label="Posiz. Scatola"
                                type="text"
                                value={
                                  obbj.posizioneScatolaInArchivio
                                    ? obbj.posizioneScatolaInArchivio
                                    : "/"
                                }
                              />
                            </div>
                            <div className="col-xs-6 col-sm-6 col-lg-6">
                              <Input
                                disabled
                                readOnly
                                label="Motivo Manc.N."
                                type="text"
                                value={
                                  obbj.motivoMancataNotifica
                                    ? obbj.motivoMancataNotifica
                                    : "/"
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </>
      ) : (
        <b>Nessun Risultato da Mostrare</b>
      )}
    </>
  );
}

export default Allegati;

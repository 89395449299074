import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import AlertMessage from "../Objects/AlertMessage.js";
import Repo from "../Utility/Repo";
import ListaPagamentiEffettuati from "../Objects/ListaPagamentiEffettuati";
import moment from "moment";
import Pagination from "../Objects/Pagination.js";
import Select from "../Objects/Select.js";
import { Link } from "react-router-dom";

function PagamentiEffettuati(props) {
  const [cookies] = useCookies();
  const [apiToken] = useState(cookies.api_token);
  const [loading, setLoading] = useState();
  const [pagamentiEffettuati, setPagamentiEffettuati] = useState([]);
  const [errorMessage, setErrorMessage] = useState(false);

  const [pageNumber, setPageNumber] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [queryCount, setQueryCount] = useState(0);

  const [data, setData] = useState({
    cfpIva: "",
    tipoCartella: "string",
    idCartella: "string",
    nomeUnivocoCartella: "string",
    dal: "2021-05-20T11:50:04.262Z",
    al: "2021-05-20T11:50:04.262Z",
    pageIndex: 0,
    pageSize: 10,
    orderDescending: true,
    orderField: "CFPIva",
  });

  useEffect(() => {
    if (props.codiceFiscale) {
      var dataObb = data;
      dataObb.cfpIva = props.codiceFiscale;
      var dataJson = JSON.stringify(dataObb);
      setData(dataObb);

      if (localStorage.getItem("dataForm")) {
        setData(JSON.parse(localStorage.getItem("dataForm")));
        dataJson = localStorage.getItem("dataForm");
      } else {
      }

      reload(dataJson);
    }
  }, [props.codiceFiscale]);

  const handleChangeSelect = (event, nameOfComponent) => {
    let name = nameOfComponent.name;
    let value = event.value;

    switch (name) {
      case "valueData":
        var startdate = moment();

        switch (value) {
          case "1m":
            startdate = moment()
              .subtract(1, "months")
              .format("YYYY-MM-DDTHH:mm:ss");
            break;

          case "3m":
            startdate = moment()
              .subtract(3, "months")
              .format("YYYY-MM-DDTHH:mm:ss");
            break;

          case "1y":
            startdate = moment()
              .subtract(1, "years")
              .format("YYYY-MM-DDTHH:mm:ss");
            break;

          default:
            startdate = null;
            break;
        }

        setData((prevState) => ({
          ...prevState,
          dal: startdate,
        }));

        break;

      default:
        break;
    }
  };

  const reload = (dataJson) => {
    setLoading(false);
    localStorage.setItem("dataForm", dataJson);
    Repo.getPagamentiEffettuatiContribuente(apiToken, dataJson)
      .then((response) => {
        setPagamentiEffettuati(response.data.items ? response.data.items : []);
        setLoading(true);
        setPageCount(response.data.pageCount);
        setQueryCount(response.data.queryCount);
        setLoading(true);
      })
      .catch((error) => {
        console.log(error);
        setLoading(true);
        setErrorMessage(true);
      });
  };

  const ChangePage = (numberPage) => {
    var page = numberPage - 1;
    var dataTemp = data;
    dataTemp.pageIndex = page;
    setData(dataTemp);
    setPageNumber(page);

    reload(JSON.stringify(data));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (apiToken) {
      reload(JSON.stringify(data));
    }
  };

  return (
    <>
      <div className="clearfix">
        <div className="float-left">
          <nav className="breadcrumb-container pl-2" aria-label="breadcrumb">
            <ol className="breadcrumb">
              <Link
                style={{ color: "inherit", textDecoration: "inherit" }}
                to={"/"}
              >
                Home
              </Link>
              &nbsp; <b>/</b>
              <a className={"breadcrumb-item active"}>
                &nbsp; <b>Pagamenti Effettuati</b> &nbsp;
              </a>{" "}
            </ol>
          </nav>
        </div>
      </div>

      {errorMessage && <AlertMessage error={true}></AlertMessage>}

      <div className="col-12">
        <div className="card card-teaser rounded shadow mb-2">
          <div className="card-body">
            <h3>Dettaglio Pagamenti Effettuati</h3>
            <br />

            <form onSubmit={handleSubmit}>
              <div>
                <div className="form-row">
                  <div className="form-group col-6">
                    <div className="bootstrap-select-wrapper">
                      <label>Periodo</label>

                      <Select
                        aria-label="Scegli una opzione"
                        name="valueData"
                        defaultInputValue=""
                        defaultMenuIsOpen={false}
                        defaultValue={null}
                        icon={false}
                        name="valueData"
                        onChange={handleChangeSelect}
                        options={[
                          {
                            value: "1m",
                            label: "Ultimo mese",
                          },
                          {
                            value: "3m",
                            label: "Ultimi tre mesi",
                          },
                          {
                            value: "1y",
                            label: "Ultimo anno",
                          },
                        ]}
                        placeholder="Scegli una opzione"
                      />
                    </div>
                  </div>
                  <div className="form-group col text-center">
                    <button type="submit" className="btn btn-primary">
                      Cerca{" "}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div className="col-12 mt-5">
          {loading ? (
            <>
              {pagamentiEffettuati.length > 0 ? (
                <>
                  {" "}
                  <ListaPagamentiEffettuati>
                    {pagamentiEffettuati}
                  </ListaPagamentiEffettuati>
                </>
              ) : (
                <b>Nessun Risultato!</b>
              )}
            </>
          ) : (
            <div className="col-12">
              <div className="trasparente">
                <div className="progress-spinner progress-spinner-active spinner">
                  <span className="sr-only">Caricamento...</span>
                </div>
              </div>
            </div>
          )}

          {pagamentiEffettuati.length !== 0 && (
            <Pagination
              methodChange={ChangePage}
              PageIndex={pageNumber + 1}
              PageCount={pageCount}
              TotalCount={queryCount}
            ></Pagination>
          )}
        </div>
      </div>
    </>
  );
}

export default PagamentiEffettuati;

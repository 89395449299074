import React from "react";

import mainLogo from "../../Img/mainlogo.png";

import logo from "../../Img/logo.png";

import GooglePlayGet from "../../Img/GooglePlayGet.png";
import AppStoreDownload from "../../Img/AppStoreDownload.png";
import { Link } from "react-router-dom";

function Footer(props) {
  return (
    <footer className="it-footer">
      <div className="it-footer-main footerCustom">
        <div className="container footerText">
          <section>
            <div className="row ">
              <div className="col-sm-12">
                <div className="it-brand-wrapper">
                  <a href="#" className="">
                    <img alt="dd" height="58" src={logo} />
                    <div className="it-brand-text">
                      <h2 className="no_toc">
                        {" "}
                        <img
                          className="img-fluid"
                          src={mainLogo}
                          style={{ marginLeft: "5px", maxHeight: "40px" }}
                        />
                      </h2>

                      <h3 className="no_toc d-none d-lg-block ml-3">
                        Servizi per il cittadino
                      </h3>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </section>
          <section></section>
          <section className="py-4">
            <div className="row clearfix">
              <div className="col-lg-3 col-md-3 pb-2">
                <h6>SEGNALAZIONI</h6>
                <p>
                  <a className="text-white" href="mailto:segnalazioni@smartcitypa.it">
                    segnalazioni@smartcitypa.it
                  </a>
                </p>
              </div>
              <div className="col-lg-3 col-md-3 pb-2">
                <h6>INFORMAZIONI</h6>
                <p>
                  <strong>Smart City PA</strong>
                  <br />
                  <a
                    href="http://www.smartcitypa.it"
                    className="small-prints text-white"
                    target="_blank"
                    title="www.smartcitypa.it"
                    rel="noopener noreferrer"
                  >
                    www.smartcitypa.it
                  </a>{" "}
                  <br />
                  info@smartcitypa.it
                </p>
                <p></p>
                <p></p>
              </div>

              <div className="col-lg-3 col-md-3 pb-2">
                <h6>CONTATTI</h6>
                <p>
                  <strong>Servizi Locali SPA</strong> <br />
                  Via P. Mascagni, 22 - 20122 Milano (MI) <br />
                  C.F./P.IVA: 03170580751 <br />
                  <a
                    href="http://www.servizilocalispa.it"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="small-prints text-white"
                    title="www.servizilocalispa.it"
                  >
                    www.servizilocalispa.it
                  </a>
                </p>
              </div>

              <div className="col-lg-3 col-md-3 pb-2">
                <h6>APPS</h6>
                <p>
                  <img src={GooglePlayGet} className="storeApp" alt="" />
                </p>
                <p>
                  <img src={AppStoreDownload} className="storeApp" alt="" />
                </p>
              </div>
            </div>
          </section>

          <section className="py-4 border-white border-top">
            <div className="container  ">
              <h3 className="sr-only">Sezione Link Utili</h3>
              <ul className="it-footer-small-prints-list list-inline mb-0 d-flex flex-column flex-md-row">
                <li className="list-inline-item">
                  <Link
                    to="/privacy_policy"
                    className="small-prints text-white"
                    title="Privacy-Cookies"
                  >
                    Privacy policy
                  </Link>
                </li>
              </ul>
            </div>
          </section>
          <div className="cookiebar">
            <p>
              Questo sito utilizza cookie tecnici, analytics e di terze parti.{" "}
              <br />
              Proseguendo nella navigazione accetti l’utilizzo dei cookie.
            </p>
            <div className="cookiebar-buttons">
              <a href="/privacy_policy" className="cookiebar-btn">
                Privacy policy<span className="sr-only">cookies</span>
              </a>
              <button
                data-accept="cookiebar"
                className="cookiebar-btn cookiebar-confirm"
              >
                Accetto<span className="sr-only"> i cookies</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
export const MemoizedFooter = React.memo(Footer);

import { Input } from "design-react-kit";
import { useState, useEffect } from "react";
import moment from "moment";
import { useCookies } from "react-cookie";
import Repo from "../Utility/Repo";
import AlertMessage from "../Objects/AlertMessage.js";
import Select from "../Objects/Select.js";

function NuovaPosizione(props) {
  const [errorMessage, setErrorMessage] = useState();
  const [personType, setPersonType] = useState();
  const [cookies] = useCookies();

  const [apiToken] = useState(cookies.api_token);

  const [backgroundColorF, setBackgroundColorF] = useState("transparent");
  const [textColorF, setTextColorF] = useState("#435a70");
  const [backgroundColorG, setBackgroundColorG] = useState("transparent");
  const [textColorG, setTextColorG] = useState("#435a70");

  const [errorCfPiva, setErrorCfPiva] = useState(false);
  const [entrateSelect, setEntrateSelect] = useState({});

  const [items, setItems] = useState([
    {
      value: 1,
      label: "Scegli un'opzione...",
    },
  ]);

  const [data, setData] = useState({
    nomePagatore: "",
    cognomePagatore: "",
    cfPiva: "",
    indirizzoPagatoreVia: "",
    indirizzoPagatoreCitta: "",
    rata: "",
    dataScadenza: "",
    importoVersamento: null,
    causaleVersamento: "",
    idTipoEntrata: 0,
  });

  const handleChange = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    switch (name) {
      case "nome":
        setData((prevState) => ({
          ...prevState,
          nomePagatore: value,
        }));

        break;

      case "cognome":
        setData((prevState) => ({
          ...prevState,
          cognomePagatore: value,
        }));

        break;

      case "codiceFiscale":
        setData((prevState) => ({
          ...prevState,
          cfPiva: value,
        }));

        break;

      case "partitaIva":
        setData((prevState) => ({
          ...prevState,
          cfPiva: value,
        }));

        break;

      case "ragioneSociale":
        setData((prevState) => ({
          ...prevState,
          ragioneSociale: value,
        }));

        break;

      // case "entrata":
      //   setData((prevState) => ({
      //     ...prevState,
      //     entrata: value,
      //   }));
      //   break;

      case "dataScadenza":
        setData((prevState) => ({
          ...prevState,
          dataScadenza: moment(value).format("YYYY-MM-DDTHH:mm:ss"),
        }));
        break;

      case "rata":
        setData((prevState) => ({
          ...prevState,
          rata: value,
        }));
        break;

      case "importo":
        setData((prevState) => ({
          ...prevState,
          importoVersamento: parseInt(value),
        }));
        break;

      case "indirizzoPagatoreVia":
        setData((prevState) => ({
          ...prevState,
          indirizzoPagatoreVia: value,
        }));
        break;

      case "indirizzoPagatoreCitta":
        setData((prevState) => ({
          ...prevState,
          indirizzoPagatoreCitta: value,
        }));
        break;

      case "idTipoEntrata":
        setData((prevState) => ({
          ...prevState,
          idTipoEntrata: value,
        }));
        break;

      case "causaleVersamento":
        setData((prevState) => ({
          ...prevState,
          causaleVersamento: value,
        }));
        break;

      default:
        break;
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const regexCF =
      /^[A-Za-z]{6}[0-9LMNPQRSTUV]{2}[A-Za-z]{1}[0-9LMNPQRSTUV]{2}[A-Za-z]{1}[0-9LMNPQRSTUV]{3}[A-Za-z]{1}$/gm;

    const regexPV = /^[0-9]{11}$/gm;

    let passedValidation = true;

    if (personType) {
      if (!data.cfPiva.match(regexCF)) {
        setErrorCfPiva(true);
        passedValidation = false;
      } else {
        setErrorCfPiva(false);
      }
    } else {
      if (!data.cfPiva.match(regexPV)) {
        passedValidation = false;
        setErrorCfPiva(true);
      } else {
        setErrorCfPiva(false);
      }
    }

    if (passedValidation) {
      var entity = data;
      var dataJson = {
        entity,
      };
      dataJson = JSON.stringify(dataJson);

      Repo.postPosizioniDebitorie(apiToken, dataJson)
        .then((response) => {
          localStorage.clear();
          //window.location.href = window.location.href.split("/nuovo")[0];
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    Repo.getTipiEntrata(apiToken)
      .then((response) => {
        setEntrateSelect(response.data.data);

        setItems(response.data.data);
        response.data.data.map((obj, index) => {
          const newList = items.concat({
            value: obj.idTipoEntrata,
            label: obj.descrizione,
          });
          setItems(newList);
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (personType) {
      setBackgroundColorF("#2e58a6");
      setTextColorF("white");
      setBackgroundColorG("transparent");
      setTextColorG("#435a70");
    } else {
      setBackgroundColorF("transparent");
      setTextColorF("#435a70");
      setBackgroundColorG("#2e58a6");
      setTextColorG("white");
    }

    setData({
      nomePagatore: "",
      cognomePagatore: "",
      cfPiva: "",
      indirizzoPagatoreVia: "",
      indirizzoPagatoreCitta: "",
      rata: "",
      dataScadenza: "",
      importoVersamento: 0,
      causaleVersamento: "",
      idTipoEntrata: 0,
    });
  }, [personType]);

  const changeEntrata = (event) => {
    var entrataValue = event.value;
    setData((prevState) => ({
      ...prevState,
      idTipoEntrata: parseInt(entrataValue),
    }));
  };

  return (
    <>
      <div className="col-12 ">
        <br></br>
        {errorMessage !== undefined && (
          <AlertMessage error={errorMessage}></AlertMessage>
        )}

        <form onSubmit={handleSubmit} className="">
          <div className="card card-body">
            <div className="card-wrapper card-space pb-5">
              <div className="card card-bg">
                <div className="card-body">
                  <h4 className="card-title">Posizione Debitoria:</h4>

                  <div
                    className="row col-lg-4 border border-primary rounded mb-4 mt-5 text-center ml-1 p-0 align-items-center"
                    style={{ height: "40px" }}
                  >
                    <div
                      className="col-6 p-0 border-right border-primary rounded pt-1"
                      style={{
                        backgroundColor: backgroundColorF,
                        color: textColorF,
                        cursor: "pointer",
                        height: "40px",
                      }}
                      onClick={() => setPersonType(true)}
                    >
                      <a>
                        <b>Persona Fisica</b>
                      </a>
                    </div>
                    <div
                      className="col-6 p-0 rounded pt-1"
                      style={{
                        backgroundColor: backgroundColorG,
                        color: textColorG,
                        cursor: "pointer",
                        height: "40px",
                      }}
                      onClick={() => setPersonType(false)}
                    >
                      <a>
                        <b>Persona Giuridica</b>
                      </a>
                    </div>
                  </div>
                  {personType && (
                    <>
                      <div className="row form-group">
                        <div className="col-xs-6 col-md-3">
                          <Input
                            id="nome"
                            required
                            label="Nome"
                            type="text"
                            value={data.nomePagatore}
                            name="nome"
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-xs-6 col-md-4">
                          <Input
                            id="cognome"
                            required
                            label="Cognome"
                            type="text"
                            value={data.cognomePagatore}
                            name="cognome"
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-xs-6 col-md-5">
                          <Input
                            id="codicefiscale"
                            required
                            label="Codice Fiscale"
                            type="text"
                            value={data.cfPiva}
                            name="codiceFiscale"
                            onChange={handleChange}
                            {...(errorCfPiva && {
                              infoText: "Il campo presenta errori",
                            })}
                            {...(errorCfPiva && { className: "text-danger" })}
                          />
                        </div>
                      </div>

                      <div className="row form-group">
                        <div className="col-xs-12 col-md-6">
                          <Input
                            id="indirizzoPagatoreVia"
                            required
                            label="Indirizzo Pagamento"
                            type="text"
                            value={data.indirizzoPagatoreVia}
                            name="indirizzoPagatoreVia"
                            onChange={handleChange}
                          />
                        </div>

                        <div className="col-xs-12 col-md-6">
                          <Input
                            id="indirizzoPagatoreCitta"
                            required
                            label="Città"
                            type="text"
                            value={data.indirizzoPagatoreCitta}
                            name="indirizzoPagatoreCitta"
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                      <div className="row form-group">
                        <div className="bootstrap-select-wrapper col-5">
                          <Select
                            aria-label="Tipo Entrata"
                            clearText="Annulla"
                            value={items.label}
                            defaultValue={items.label}
                            defaultMenuIsOpen={false}
                            icon={false}
                            id="idTipoEntrata"
                            onChange={changeEntrata}
                            options={items}
                            isSearchable
                            placeholder="Entrata"
                          />
                        </div>
                        <div className="col-xs-6 col-md-4">
                          <Input
                            id="dataScadenza"
                            required
                            label="Data di Scadenza"
                            type="date"
                            value={moment(data.dataScadenza).format(
                              "YYYY-MM-DD"
                            )}
                            name="dataScadenza"
                            placeholder="12/02/2021"
                            onChange={handleChange}
                          />
                        </div>

                        <div className="col-xs-6 col-md-3">
                          <Input
                            id="rata"
                            required
                            type="number"
                            min="0"
                            label="Rata"
                            value={data.rata ? data.rata : ""}
                            name="rata"
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                      <div className="row form-group">
                        <div className="col-md-3">
                          <Input
                            id="importo"
                            required
                            label="Importo"
                            type="number"
                            min="0"
                            name="importo"
                            value={data.importoVersamento}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-md-5">
                          <Input
                            id="causaleVersamento"
                            required
                            label="Causale Versamento"
                            type="text"
                            name="causaleVersamento"
                            value={data.causaleVersamento}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  {!personType && (
                    <>
                      <div className="row form-group">
                        <div className="col-xs-6 col-md-6">
                          <Input
                            id="ragioneSociale"
                            required
                            label="Ragione Sociale"
                            type="text"
                            value={
                              data.ragioneSociale ? data.ragioneSociale : ""
                            }
                            name="ragioneSociale"
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-xs-6 col-md-6">
                          <Input
                            id="PartitaIva"
                            required
                            label="Partita Iva"
                            type="text"
                            value={data.cfPiva}
                            name="codiceFiscale"
                            onChange={handleChange}
                            {...(errorCfPiva && {
                              infoText: "Il campo presenta errori",
                            })}
                            {...(errorCfPiva && { className: "text-danger" })}
                          />
                        </div>
                      </div>

                      <div className="row form-group">
                        <div className="col-xs-12 col-md-6">
                          <Input
                            id="indirizzoPagatoreVia"
                            required
                            label="Indirizzo Pagamento"
                            type="text"
                            value={data.indirizzoPagatoreVia}
                            name="indirizzoPagatoreVia"
                            onChange={handleChange}
                          />
                        </div>

                        <div className="col-xs-12 col-md-6">
                          <Input
                            id="indirizzoPagatoreCitta"
                            required
                            label="Città"
                            type="text"
                            value={data.indirizzoPagatoreCitta}
                            name="indirizzoPagatoreCitta"
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                      <div className="row form-group">
                        <div className="bootstrap-select-wrapper col-5">
                          <Select
                            aria-label="Entrata"
                            clearText="Annulla"
                            defaultValue={items}
                            defaultMenuIsOpen={false}
                            icon={false}
                            id="selectExampleClassic"
                            onChange={changeEntrata}
                            options={items}
                            isSearchable
                            placeholder="Entrata"
                          />
                        </div>

                        <div className="col-xs-6 col-md-4">
                          <Input
                            id="dataScadenza"
                            required
                            label="Data di Scadenza"
                            type="date"
                            value={moment(data.dataScadenza).format(
                              "YYYY-MM-DD"
                            )}
                            name="dataScadenza"
                            placeholder="12/02/2021"
                            onChange={handleChange}
                          />
                        </div>

                        <div className="col-xs-6 col-md-3">
                          <Input
                            id="rata"
                            required
                            type="number"
                            min="0"
                            label="Rata"
                            value={data.rata}
                            name="rata"
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                      <div className="row form-group">
                        <div className="col-md-3">
                          <Input
                            id="importo"
                            required
                            label="Importo"
                            type="number"
                            min="0"
                            name="importo"
                            value={data.importo}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-md-5">
                          <Input
                            id="causaleVersamento"
                            required
                            label="Causale Versamento"
                            type="text"
                            name="causaleVersamento"
                            value={data.causaleVersamento}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  <a className="read-more text-right col-12">
                    <button className="btn btn-primary">Salva</button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default NuovaPosizione;

import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { useLocation } from "react-router";
import moment from "moment";
import ListaRate from "../Objects/ListaRate";
import { Redirect } from "react-router-dom";
import Repo from "../Utility/Repo";
import logopagopa from "../../Img/pagopa-logo.png";
import PagoPAButton from "../Objects/PagoPAButton";
import iconSprite from "bootstrap-italia/dist/svg/sprite.svg";

function DettaglioPagamentiAttesi(props) {
  const [cookies] = useCookies();
  const [apiToken] = useState(cookies.api_token);
  const [pagamento, setPagamento] = useState(
    props.children ? props.children : []
  );
  const baseUrl = location.protocol + "//" + location.host;
  useEffect(() => {
    setPagamento(props.children ? props.children : []);
  }, [props.children]);

  var dataDownload = {
    cfpIva: cookies.user_profile.FiscalCode,
    nomeCognomePagatore: props.user.FirstName + " " + props.user.LastName,
    dataScadenza: pagamento.dataScadenza,
    identificativoPosizioneDebitoria: pagamento.quartoCampo,
    idTipoEntrata: pagamento.idTipoEntrata,
    idTipoEntrataVerticale: pagamento.idTipoEntrataVerticale,
    causale: pagamento.causale,
    importo: pagamento.importoDovuto,
  };

  const downloadFile = () => {
    if (pagamento.idRata) {
      dataDownload["numeroRata"] = pagamento.numeroRata;
    }

    var dataJsonDownload = JSON.stringify(dataDownload);

    // dataJson = JSON.stringify(dataJson);
    Repo.getModello3(apiToken, dataJsonDownload)
      .then((response) => {
        var file = response.data.data.bytes;
        var a = window.document.createElement("a");
        a.href = "data:application/octet-stream;base64," + file;
        a.download = response.data.data.nomeFile + ".pdf";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  var data = JSON.stringify({
    cfpIva: cookies.user_profile.FiscalCode,
    anagraficaPagatore: props.user.FirstName + " " + props.user.LastName,
    importoTotale: pagamento.importoDovuto,
    ReturnUrl: baseUrl + "/payment_check",
    idTipoEntrata: pagamento.idTipoEntrata,
    idTipoEntrataVerticale: pagamento.idTipoEntrataVerticale,
    pagamenti: [
      {
        importo: pagamento.importoDovuto,
        causale: pagamento.causale,
        quartoCampo: pagamento.quartoCampo,
        idTipoEntrata: pagamento.idTipoEntrata,
        idTipoEntrataVerticale: pagamento.idTipoEntrataVerticale,
      },
    ],
  });

  const handleSubmit = () => {
    var dataJson = data;

    // dataJson = JSON.stringify(dataJson);
    Repo.postUrlPagoPa(apiToken, dataJson)
      .then((response) => {
        window.location.href = response.data.data.url;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <div className="px-lg-5 px-2">
        <div className="row">
          <div className="col-12 col-lg-12">
            <div className="card-wrapper card-space">
              <div className="card card-bg">
                <div className="card-body">
                  <div className="row">
                    <div className="col-8">
                      {" "}
                      <h5 className="card-title">
                        {" "}
                        {pagamento.idPagamento
                          ? pagamento.idPagamento
                          : pagamento.idRata}
                      </h5>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12">
                      <hr />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-6 col-md-4">
                      <p>
                        <b>Importo Dovuto: </b> {pagamento.importoDovuto} €
                      </p>
                    </div>
                    <div className="col-xs-6 col-md-4">
                      <p>
                        <b>Importo Residuo: </b> 0 €
                      </p>
                    </div>
                    <div className="col-xs-6 col-md-4">
                      <p>
                        <b>Importo Versato: </b> 0 €
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    {pagamento.idRata && (
                      <div className="col-xs-6 col-md-4">
                        <p>
                          <b>Importo Interessi: </b>{" "}
                          {pagamento.importoInteressi} €
                        </p>
                      </div>
                    )}
                    <div className="col-xs-6 col-md-4">
                      <p>
                        <b>Causale: </b> {pagamento.causale}
                      </p>
                    </div>
                    <div className="col-xs-6 col-md-4">
                      <p>
                        <b>Scadenza</b>{" "}
                        {pagamento.dataScadenza
                          ? moment(new Date(pagamento.dataScadenza)).format(
                            "DD-MM-YYYY"
                          )
                          : "/"}
                      </p>
                    </div>
                  </div>

                  <hr></hr>
                  <div className="row">
                    {(!props.dettaglioCartella &&
                      pagamento.rate &&
                      pagamento.rate.length) > 0 ? (
                      <>
                        <ListaRate setRataDettaglio={props.setRataDettaglio}>
                          {pagamento.rate}
                        </ListaRate>
                      </>
                    ) : (
                      <>
                        <div className="col-md-6 text-center mt-2">
                          <button
                            style={{
                              width: "240px",
                              height: "48px",
                            }}
                            type="button"
                            className="btn btn-primary"
                            onClick={downloadFile}
                          >
                            <svg className="icon icon-sm icon-white">
                              <use href={`${iconSprite}#it-download`} />
                            </svg>{" "}
                            <span>Scarica avviso cartaceo</span>
                          </button>
                        </div>
                        <div className="col-md-6 text-center mt-2 ">
                          <PagoPAButton
                            handleSubmit={handleSubmit}
                          ></PagoPAButton>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DettaglioPagamentiAttesi;
